import React from 'react';
import { Button, Card } from 'antd';

interface Props {
  message: string;
  buttonAction: () => void;
  buttonText: string;
  customImage?: string;
  hideButton?: boolean;
  buttonLoading?: boolean;
}

function EmptyList(props: Props) {
  const {
    message,
    buttonAction,
    buttonText,
    customImage,
    hideButton,
    buttonLoading = false,
  } = props;
  return (
    <>
      <Card bordered={false} className="empty-list">
        <img
          src={customImage ? customImage : '/assets/images/empty-list.svg'}
          alt="empty list"
        />
        <span className="empty-list-message">{message}</span>
        {!hideButton && (
          <Button loading={buttonLoading} type="primary" onClick={buttonAction}>
            {buttonText}
          </Button>
        )}
      </Card>
    </>
  );
}

export default EmptyList;
