import React, { useState } from 'react';
import { Button, Card, Form, Input, notification } from 'antd';
import { Link } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import RegisterPackages from './components/RegisterPackages';
import { history } from '../../index';
import { AxiosError } from 'axios';
import AWSRegionSelectDropdown from 'src/features/account/components/AWSRegionSelectDropdown';

function RegisterCompany() {
  const [step, setStep] = useState('form');
  const { subscriptionsStore } = useStore();

  const {
    submitting,

    create,
  } = subscriptionsStore;
  const [form] = Form.useForm();

  async function getValues(values: any) {
    try {
      await create({
        companyName: values.company_name,
        name: values.name,
        email: values.email,
        phone: values.phone,
        password: values.password,
        storageRegion: values.storageRegion,
      });
      setStep('packages');
    } catch (error) {
      const ex = error as AxiosError;
      if (ex.response) {
        const errorString = ex.response.data;
        const split = errorString.split(';');

        split.forEach((e: string) => {
          if (e.includes('already exists')) {
            if (e.toLowerCase().includes('email'))
              form.setFields([
                {
                  name: 'email',
                  errors: ['Email already exists!'],
                },
              ]);
            if (e.toLowerCase().includes('company'))
              form.setFields([
                {
                  name: 'company_name',
                  errors: ['Company name already exists!'],
                },
              ]);
          }
        });
      }
    }
  }

  function next() {
    if (step === 'form') {
      form.submit();
    } else {
      notification.success({
        duration: 45,
        className: 'submit-notification-success',
        message: 'Account registered',
        description:
          'You have successfully registered your account. Please verify your email before logging in.',
      });
      history.push('/login');
    }
  }

  return (
    <>
      <div style={{ height: '100vh', display: 'flex' }}>
        <Card className="registration" title="Registration">
          {step === 'form' ? (
            <Form
              form={form}
              onFinish={getValues}
              layout="vertical"
              name="register_company"
              className="registration-form">
              <Form.Item
                name="company_name"
                label={'Company Name'}
                rules={[{ required: true, message: 'Enter company name.' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label={'Where should we store your media:'}
                name="storageRegion"
                rules={[{ required: true, message: 'This field is required' }]}>
                <AWSRegionSelectDropdown />
              </Form.Item>
              <Form.Item
                name="name"
                label={'Full name:'}
                rules={[{ required: true, message: 'Enter a name.' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="phone"
                label={'Tel. Number'}
                rules={[
                  { required: true, message: 'Enter telephone number.' },
                  {
                    pattern: new RegExp(
                      '^[+]?[(]?[+]?[0-9]{2,3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'
                    ),
                    message: 'Invalid phone number.',
                  },
                ]}>
                <Input placeholder="+27827000000" />
              </Form.Item>

              <Form.Item
                name="email"
                label={'Email'}
                rules={[
                  { required: true, message: 'Enter email address.' },
                  { type: 'email', message: 'Please enter a valid email.' },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label={'Password'}
                extra={
                  'Password requires: At least 6 letters, 1 Number and 1 Capital Letter'
                }
                rules={[
                  { required: true, message: 'Enter password.' },
                  { min: 6, message: 'Password must be 6 characters long.' },
                  {
                    pattern: new RegExp('^(?=.*[A-Z])'),
                    message: 'Password must contain 1 capital letter',
                  },
                  {
                    pattern: new RegExp('^(?=.*?[0-9])'),
                    message: 'Password must contain 1 number',
                  },
                ]}>
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="retype_password"
                label={'Retype Password'}
                rules={[
                  { required: true, message: 'Retype password.' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      );
                    },
                  }),
                ]}>
                <Input.Password />
              </Form.Item>

              <div className="registration-warning">
                <strong>Please note:</strong> By creating an account, you will
                be registering a profile for a new company. If your company
                already has a profile on Happy Snappy Lite, please contact your
                Account Admin or Account Manager - they can then add you as a
                team member and send you your login details.
              </div>
            </Form>
          ) : (
            <RegisterPackages />
          )}

          <div className="registration-footer">
            <div className="registration-footer-login">
              <p>Already have an account?</p>
              <Link to={'/login'}>Login</Link>
            </div>

            <Button
              type="primary"
              htmlType="submit"
              onClick={next}
              loading={submitting}>
              {step === 'form' ? 'Create an account' : 'Confirm'}
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}

export default observer(RegisterCompany);
