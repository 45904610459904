import React, { useState } from 'react';
import { Layout, Radio, RadioChangeEvent, Tooltip } from 'antd';
import WebsiteView from './WebsiteView';

function WebsitePreview() {
  const [previewSize, setPreviewSize] = useState('386 x 675');
  const [previewMode, setPreviewMode] = useState('mobile');
  function setPreview(e: RadioChangeEvent) {
    setPreviewMode(e.target.value);

    switch (e.target.value) {
      default:
      case 'mobile':
        setPreviewSize('386 x 675');
        break;

      case 'tablet':
        setPreviewSize('1010 x 1376');
        break;

      case 'desktop':
        setPreviewSize('1920 x 1080');
        break;
    }
  }

  const { Header, Content } = Layout;
  return (
    <>
      <Layout className="website-preview">
        <Header>
          <Radio.Group
            onChange={(e: RadioChangeEvent) => setPreview(e)}
            defaultValue={'mobile'}
            buttonStyle="solid"
            className="website-preview-device">
            <Radio.Button value="mobile">Mobile</Radio.Button>
            <Radio.Button value="tablet">Tablet</Radio.Button>
            <Radio.Button value="desktop">Desktop</Radio.Button>
          </Radio.Group>
        </Header>

        <Content>
          <WebsiteView view={previewMode} />
        </Content>
      </Layout>
    </>
  );
}

export default WebsitePreview;
