import React from 'react';
import { Button, Card, Form, Input } from 'antd';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

function Login() {
  const { userStore } = useStore();

  const { login, loginLoading } = userStore;

  async function loginUser(values: any) {
    const result = await login({
      Email: values.email,
      Password: values.password,
      Extra: 'Web',
    });

    if (result) {
      toast.error(result);
    }
  }

  return (
    <>
      <div style={{ height: '100vh', display: 'flex' }}>
        <Card className="login-card" title={'Happy Snappy Lite Login'}>
          <Form
            name="basic"
            layout="vertical"
            onFinish={loginUser}
            autoComplete="off">
            <Form.Item
              label={'Email'}
              name={'email'}
              rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input />
            </Form.Item>

            <Form.Item
              label={'Password'}
              name={'password'}
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}>
              <Input.Password />
            </Form.Item>

            <Form.Item className="login-card-buttons">
              <Button loading={loginLoading} type="primary" htmlType="submit">
                Submit
              </Button>

              <Button
                type={'text'}
                href="/forgot-password"
                style={{ marginLeft: 'auto' }}>
                Forgot Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default observer(Login);
