import React, { useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import { useStore } from '../../app/stores/store';
import SalesAddPayment from './components/SalesAddPayment';
import { observer } from 'mobx-react-lite';
import SalesGatewayList from './components/SalesGatewayList';
import SalesEditGateway from './components/SalesEditGateway';
import selectPaymentGateway from '../qrcodes/components/SelectPaymentGateway';

const allGateways = ['paygate', 'paypal', 'payu'];
enum PAGE_MODES {
  EDIT,
  LIST,
}
const BUTTON_TEXT = {
  0: 'Save Payment Gateway',
  1: 'Add Payment Gateway',
};

function SalesPaymentGateway() {
  const { modalStore, salesStore, userStore } = useStore();
  const { openModal } = modalStore;
  const {
    submittingGateway,
    currentGateways,
    paymentGateways,
    selectedGateway,
    editGateway,
  } = salesStore;
  const { user } = userStore;

  const [mode, setMode] = useState<PAGE_MODES>(PAGE_MODES.LIST);
  const [button, setButton] = useState<string>(BUTTON_TEXT[1]);
  useEffect(() => {
    setButton(BUTTON_TEXT[mode]);
  }, [mode]);

  function openAddPayment() {
    if (!user) return;
    if (!user.isAdministrator) return;
    openModal(true, <SalesAddPayment />, 'Add Payment Gateway', true);
  }

  async function buttonClick() {
    switch (mode) {
      case PAGE_MODES.LIST:
        openAddPayment();
        break;

      case PAGE_MODES.EDIT:
        if (selectedGateway) await editGateway(selectedGateway);

        setMode(PAGE_MODES.LIST);
        break;
    }
  }

  return (
    <Layout className="sales-add-payment">
      <div style={{ display: 'flex' }}>
        <h3 className="tab-header">Payment Gateways</h3>
        {mode === PAGE_MODES.EDIT && (
          <Button
            onClick={() => setMode(PAGE_MODES.LIST)}
            className="yellow-ant-btn">
            Cancel
          </Button>
        )}
        {user!.isAdministrator &&
          (paymentGateways.length < 3 || mode === PAGE_MODES.EDIT) && (
            <Button
              type={'primary'}
              className="sales-add-payment-button"
              onClick={buttonClick}
              loading={submittingGateway}
              disabled={
                mode === PAGE_MODES.LIST &&
                allGateways.every((i) => currentGateways.includes(i))
              }>
              {button}
            </Button>
          )}
      </div>

      {mode === PAGE_MODES.LIST ? (
        <SalesGatewayList changeMode={() => setMode(PAGE_MODES.EDIT)} />
      ) : (
        <SalesEditGateway changeMode={() => setMode(PAGE_MODES.LIST)} />
      )}
    </Layout>
  );
}

export default observer(SalesPaymentGateway);
