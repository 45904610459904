import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import { useStore } from '../stores/store';
import { useParams } from 'react-router-dom';

// @ts-ignore
interface Props extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

export default function SysAdminRoute({
  component: Component,
  ...rest
}: Props) {
  const { userStore } = useStore();
  const { isLoggedIn, user } = userStore;

  return (
    <Route
      {...rest}
      //   todo: instead of redirecting non-supers to login, redirect to a page that says "you are not authorized to view this page"
      render={(props) =>
        !isLoggedIn ? (
          <Redirect to="/login" />
        ) : user && user.isSysAdmin === false ? (
          <Redirect to="/" />
        ) : (
          // @ts-ignore
          <Component {...props} />
        )
      }
    />
  );
}
