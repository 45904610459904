import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { useStore } from '../../../../app/stores/store';
import { observer } from 'mobx-react-lite';

interface Props {
  view: string;
}

function WebsiteView(props: Props) {
  const [showHeader, setShowHeader] = useState<boolean>(false);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const [headerDisplay, setHeaderDisplay] = useState<string>('none');
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const { view } = props;
  const { templatesStore } = useStore();
  const { templateWebsite, selectedTemplate } = templatesStore;

  useEffect(() => {
    if (!templateWebsite || !templateWebsite.imageBlocks) return;

    if (
      templateWebsite?.imageBlocks?.find((ib) => ib.placement === 0)
        ?.imageUrl === ''
    ) {
      setShowHeader(false);
    }

    if (
      templateWebsite?.imageBlocks?.find((ib) => ib.placement === 1)
        ?.imageUrl === ''
    ) {
      setShowFooter(false);
    }

    setPreviewUrl(
      selectedTemplate?.sampleImageUrl?.replace('http://', 'https://') ??
        '/assets/default/landscape-photo.jpg'
    );
  }, [templateWebsite]);

  return (
    <>
      <div
        className={`website-wrapper website-wrapper-${view}`}
        style={{
          backgroundImage: `url("/assets/images/${view}-outline.png")`,
        }}>
        <div
          style={{ backgroundColor: templateWebsite?.backgroundColor }}
          className={`website-view website-view-${view}`}>
          <div
            style={{ maxWidth: '1080px', margin: '0 auto' }}
            className="website-view-inner">
            <div className="website-view-header">
              <img
                src={
                  templateWebsite?.imageBlocks?.find((ib) => ib.placement === 0)
                    ?.imageUrl
                }
                alt={'banner'}
                onLoad={() => setShowHeader(true)}
                style={showHeader ? {} : { display: 'none' }}
              />
            </div>

            <div className="website-view-photo-block">
              <div
                style={{ backgroundColor: templateWebsite?.photoFrameColor }}
                className="website-view-photo-frame">
                <img
                  src={previewUrl}
                  alt={'template preview'}
                  className={`wp-thumbnail-${view}`}
                />
                {/* <img src={'/assets/default/landscape-photo.jpg'} alt={'template preview'} />*/}
                <Space className="website-view-photo-socials">
                  <img
                    className="website-view-photo-socials-icon"
                    src={`/assets/images/social-media/download-${templateWebsite?.socialIconsSet}.png`}
                    alt="download"
                  />
                  <img
                    className="website-view-photo-socials-icon"
                    src={`/assets/images/social-media/facebook-${templateWebsite?.socialIconsSet}.png`}
                    alt="facebook"
                  />
                  <img
                    className="website-view-photo-socials-icon"
                    src={`/assets/images/social-media/twitter-${templateWebsite?.socialIconsSet}.png`}
                    alt="twitter"
                  />
                  <img
                    className="website-view-photo-socials-icon"
                    src={`/assets/images/social-media/whatsapp-${templateWebsite?.socialIconsSet}.png`}
                    alt="whatsapp"
                  />
                </Space>
              </div>
            </div>

            <div className="website-view-footer">
              <img
                onLoad={() => setShowFooter(true)}
                src={
                  templateWebsite?.imageBlocks?.find((ib) => ib.placement === 1)
                    ?.imageUrl
                }
                alt={'footer'}
                style={showFooter ? {} : { display: 'none' }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(WebsiteView);
