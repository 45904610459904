import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { Card, Dropdown, Menu, Spin, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import EmptyList from '../../../app/layout/EmptyList';
import { PaymentGateway } from '../../../app/models/paymentGateway';
import { useStore } from '../../../app/stores/store';
import SalesAddPayment from './SalesAddPayment';
import DeleteProjectModal from '../../projects/components/DeleteProjectModal';
import DeleteConfirmationModal from '../../../app/common/modals/DeleteConfirmationModal';
import { history } from '../../../index';

interface Props {
  changeMode: () => void;
}

function SalesGatewaylist(props: Props) {
  const { changeMode } = props;
  const { modalStore, salesStore, userStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const {
    paymentGateways,
    submittingGateway,
    deleteGateway,
    setSelectedGateway,
  } = salesStore;
  const { user } = userStore;

  function openAddPayment() {
    openModal(true, <SalesAddPayment />, 'Add Payment Gateway', true);
  }

  function confirmDelete(id: number) {
    openModal(
      true,
      <DeleteConfirmationModal
        cancel={closeModal}
        confirm={() => deleteG(id)}
        message={'Are you sure you want to delete this gateway?'}
      />,
      'Confirm Gateway Delete',
      true,
      'delete-confirmation'
    );
  }

  async function deleteG(id: number) {
    setDeletingId(id);

    closeModal();
    const result = await deleteGateway(id);
    if (!result.isSuccess) {
      const modalMessage = (
        <>
          <p className="delete-gateway-p">
            Unable to delete payment gateway, it is linked to the following
            templates:
          </p>
          <div className="delete-gateway-item-container">
            {(result ?? { value: [] }).value.map((v: any) => (
              <div key={v.id} className="delete-gateway-item">
                <div className="delete-gateway-item-x">x</div>
                <div className="delete-gateway-item-name">{v.name}</div>
              </div>
            ))}
          </div>
        </>
      );

      openModal(
        true,
        <DeleteConfirmationModal
          singleButton={true}
          cancel={closeModal}
          confirm={closeModal}
          message={modalMessage}
        />,
        'Deleting Gateway',
        true,
        'delete-confirmation'
      );
    }

    setDeletingId(-1);
  }

  function editGateway(e: PaymentGateway) {
    if (!user) return;
    if (!user.isAdministrator) {
      return;
    }

    setSelectedGateway(e);
    changeMode();
  }

  function isAdmin() {
    if (!user) return false;

    return user.isAdministrator;
  }

  const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
  const [deletingId, setDeletingId] = useState(-1);

  const columns = [
    {
      dataIndex: 'gatewayName',
      key: 'gatewayName',
      render: (gatewayName: string) => (
        <img
          src={`/assets/images/${gatewayName}.png`}
          alt="gateway logo"
          style={{ height: '40px' }}
        />
      ),
    },
    {
      title: 'Username',
      dataIndex: 'userName',
      key: 'userName',
      render: (userName: string) => (
        <div style={{ fontWeight: 'bold', color: '#1890ff' }}>{userName}</div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    isAdmin()
      ? {
          title: '',
          dataIndex: 'id',
          width: '50px',
          render: (id: number, record: PaymentGateway) => (
            <>
              {submittingGateway && deletingId === id ? (
                <Spin indicator={loadingIcon} />
              ) : (
                <div onClick={(e) => e.stopPropagation()}>
                  <Dropdown
                    disabled={submittingGateway}
                    overlay={
                      <Menu>
                        <Menu.Item key="0" onClick={() => confirmDelete(id)}>
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                    placement="bottomRight"
                    overlayStyle={{ width: '150px' }}>
                    <MoreOutlined
                      style={{
                        fontSize: '20px',
                        cursor: 'pointer',
                        opacity: `${submittingGateway ? '40%' : '100%'}`,
                      }}
                    />
                  </Dropdown>
                </div>
              )}
            </>
          ),
        }
      : {
          width: 0,
        },
  ];
  return (
    <>
      {paymentGateways.length < 1 ? (
        <EmptyList
          message={`You currently have no payment gateways setup. ${
            (user ?? { isManager: false }).isManager
              ? 'You are unable to add a payment gateway, please ask an admin to add one.'
              : 'Click the button below to create one.'
          }`}
          buttonText="Add Payment Gateway"
          hideButton={(user ?? { isManager: false }).isManager}
          buttonAction={openAddPayment}
          customImage="/assets/images/no-data-collection.svg"
        />
      ) : (
        <Card>
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  editGateway(record);
                },
              };
            }}
            columns={columns}
            dataSource={paymentGateways}
            rowKey="id"
          />
        </Card>
      )}
    </>
  );
}

export default observer(SalesGatewaylist);
