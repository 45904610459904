import { Avatar, Button, Card, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import EmptyList from '../../../app/layout/EmptyList';
import { useStore } from '../../../app/stores/store';
import { EntityStatus } from '../../../app/common/options/enums';
import ProjectAddMembers from './ProjectAddMembers';
import ProjectMembers from './ProjectMembers';
import { observer } from 'mobx-react-lite';
import AddTemplateForm from '../../templates/components/AddTemplateForm';
import { default as CustomAvatar } from '../../dashboard/components/Avatar';

interface Props {
  changeTab: (e: string) => void;
}

function ProjectSummary(props: Props) {
  const { changeTab } = props;
  const { projectStore, templatesStore, userStore, modalStore } = useStore();

  const { openModal } = modalStore;

  const { user } = userStore;

  const {
    selectedProject,
    projectUsers,
    totalPhotoCount,

    setAddingTemplate,
  } = projectStore;

  const { getTemplatesBySubscription, getTemplates, loading } = templatesStore;

  const [addingMembers, setAddingMembers] = useState(false);
  function openAddMember() {
    setAddingMembers(true);
  }

  async function checkList() {
    if (!user) return;

    const result = await getTemplatesBySubscription(
      user!.subscriptionId.toString(),
      { filter: 'active', mode: 'full' }
    );
    if (getTemplates.length > 0 || (result ?? 0) > 0) {
      setAddingTemplate(true);
      changeTab('2');
    } else {
      openModal(
        true,
        <AddTemplateForm
          from="projects"
          project_id={selectedProject!.id}
          type="all"
        />,
        'Create New Template',
        true
      );
    }
  }

  return (
    <>
      <h3 className={'tab-header'}>Project Summary</h3>
      <Row wrap className="view-project-row">
        <Card title="Information" className="info-card">
          <div className="project-info-item">
            <div className="project-info-item-title">Project Name:</div>
            <div className="project-info-item-info">
              {(selectedProject ?? { name: '' }).name}
            </div>
          </div>

          <div className="project-info-item">
            <div className="project-info-item-title">Description:</div>
            <div className="project-info-item-info">
              {(selectedProject ?? { description: '' }).description}
            </div>
          </div>

          <div className="project-info-item">
            <div className="project-info-item-title">Project Status:</div>
            <div style={{ display: 'flex' }} className="project-info-item-info">
              <div
                className={`little-circle ${EntityStatus[
                  selectedProject?.status ?? 1
                ].toLowerCase()}`}
              />
              {EntityStatus[selectedProject?.status ?? 1].toUpperCase()}
            </div>
          </div>

          <div style={{ marginTop: '15px' }}>
            <div className="project-info-item-title">Photographers:</div>

            {selectedProject!.templateGroupAppUsers.filter(
              (tg) => (tg.appUser ?? { isPhotographer: false }).isPhotographer
            ).length > 0 ? (
              <Avatar.Group maxCount={8} size={'large'}>
                {selectedProject!.templateGroupAppUsers
                  .filter(
                    (tg) =>
                      (tg.appUser ?? { isPhotographer: false }).isPhotographer
                  )
                  .map((a, index: number) => {
                    return (
                      <CustomAvatar
                        index={index}
                        username={a.appUser.displayName}
                        key={index}
                      />
                    );
                  })}
              </Avatar.Group>
            ) : (
              <div>No photographers have been added to the project.</div>
            )}
          </div>
        </Card>

        <Card title="Summary" className="info-card middle-card">
          <div className="photos-taken-card">
            <p className="photos-taken-card-gray">Photos Taken</p>
            <p className="photos-taken-card-amount">{totalPhotoCount}</p>
          </div>
        </Card>

        <Card title="Templates" className="info-card">
          {selectedProject!.templateGroupTemplates.length > 0 ? (
            <div className="photos-taken-card">
              <p className="photos-taken-card-gray">Number of templates: </p>
              <p className="photos-taken-card-amount">
                {
                  selectedProject!.templateGroupTemplates.filter(
                    (tg) => tg.template.status !== EntityStatus.Deleted
                  ).length
                }
              </p>
            </div>
          ) : (
            <p
              className="photos-taken-card-gray"
              style={{ marginBottom: '60px' }}>
              You currently have no templates allocated to this project. Click
              the button below to create a new template and add it to this
              project.
            </p>
          )}

          <Button
            className="yellow-ant-btn"
            loading={loading}
            onClick={checkList}>
            Add New Template
          </Button>
        </Card>
      </Row>

      {addingMembers ? (
        <ProjectAddMembers
          addMembers={(e: boolean) => setAddingMembers(e)}
          cancel={() => setAddingMembers(false)}
        />
      ) : (
        <>
          {projectUsers.length > 0 ? (
            <ProjectMembers addMembers={(e: boolean) => setAddingMembers(e)} />
          ) : (
            <EmptyList
              message="You currently have no team members allocated to this project. Click the button below to add members."
              buttonText="Add members"
              buttonAction={openAddMember}
              customImage="/assets/images/people-list.svg"
            />
          )}
        </>
      )}
    </>
  );
}

export default observer(ProjectSummary);
