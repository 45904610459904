import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Card, Dropdown, Menu, Spin, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { AppUser } from '../../../app/models/appUser';
import { useStore } from '../../../app/stores/store';

interface Props {
  addMembers: (e: boolean) => void;
}

function ProjectMembers(props: Props) {
  const { addMembers } = props;

  const { projectStore } = useStore();
  const {
    projectUsers,
    selectedProject,
    deletingUsers,
    loadingUsers,

    removeUsers,
  } = projectStore;

  const [selected, setSelected] = useState<number[]>([]);
  const [showRemove, setShowRemove] = useState(false);
  const [removingId, setRemovingId] = useState(-1);

  async function deleteUsers(type: string, user?: AppUser) {
    if (!selectedProject) return;

    let selectedUsers = selected;

    if (type === 'individual' && user) {
      selectedUsers = typeof user.id === 'number' ? [user.id] : [user.userId];
      setRemovingId(selectedUsers[0]);
    }

    await removeUsers(selectedProject.id, selectedUsers);
    setSelected([]);
    setShowRemove(false);
  }

  const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'fullName',
      render: (name: string, record: AppUser) => (
        <div className="project-member-name">{name}</div>
      ),
    },
    {
      title: 'Roles',
      dataIndex: 'isAdministrator',
      key: 'userRoles',
      render: (isAdministrator: boolean, record: AppUser) => (
        <div>
          {isAdministrator ? 'Admin, ' : ''}{' '}
          {record.isManager ? 'Manager, ' : ''}{' '}
          {record.isPhotographer ? 'Photographer, ' : ''}
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tel Number',
      dataIndex: 'phoneNumber',
      key: 'phone',
    },
    {
      title: '',
      dataIndex: 'id',
      width: '50px',
      render: (id: number, record: AppUser) => (
        <>
          {deletingUsers && removingId === record.userId ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <Dropdown
              disabled={deletingUsers}
              overlay={
                <Menu>
                  <Menu.Item
                    key="0"
                    onClick={() => {
                      deleteUsers('individual', record);
                    }}>
                    Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              placement="bottomRight"
              overlayStyle={{ width: '150px' }}>
              <MoreOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
            </Dropdown>
          )}
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      if (selectedRowKeys.length > 0) setShowRemove(true);
      else setShowRemove(false);

      setSelected(selectedRowKeys as number[]);
    },
  };

  if (loadingUsers)
    return (
      <>
        <Card>
          <LoadingComponent content="Loading project members..." />
        </Card>
      </>
    );

  return (
    <>
      <Card className="project-members" bordered={false}>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div className="project-members-title">Team Members</div>

          <Button
            loading={deletingUsers}
            type="primary"
            danger
            style={{
              marginRight: '20px',
              display: `${showRemove ? 'block' : 'none'}`,
            }}
            onClick={() => deleteUsers('selected')}>
            Remove team members
          </Button>

          <Button type="primary" onClick={() => addMembers(true)}>
            Add team members
          </Button>
        </div>

        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={projectUsers}
          rowKey={'userId'}
        />
      </Card>
    </>
  );
}

export default observer(ProjectMembers);
