import React from 'react';
import { useStore } from '../../../../app/stores/store';
import { Input } from 'antd';
import ColourBanner from '../../../../app/common/components/ColourBanner';
import { FIELDS } from '../../../../app/common/options/enums';
import { FIELD_NAMES } from '../../../../app/common/options/selectOptions';

function PopupStyle() {
  const { templatesStore, projectStore } = useStore();
  const {
    selectedDataCollection,

    setDataCollection,
    setDisablePopupSave,
  } = templatesStore;

  const { readonlySettings } = projectStore;

  function updateColours(color: string, field: string) {
    setDataCollection({
      ...selectedDataCollection,
      [field]: color,
    });
  }

  function changeText(e: any, field: string) {
    switch (field) {
      case 'submit':
        setDataCollection({
          ...selectedDataCollection,
          submitButtonText: e.target.value ?? '',
        });
        break;

      case 'decline':
        setDataCollection({
          ...selectedDataCollection,
          declineButtonText: e.target.value ?? '',
        });
        break;
    }
    setDisablePopupSave();
  }

  return (
    <>
      <div className="website-header-subheading">Header and Footer</div>

      <div className="website-theme-selected">
        <p>Background Colour: </p>
        <ColourBanner
          readonly={readonlySettings}
          field={FIELD_NAMES.HEADER_FOOTER_BG}
          callBack={updateColours}
          color={
            selectedDataCollection?.headerFooterBackgroundColor ?? '#D2D2D2'
          }
        />
      </div>

      <div className="website-theme-selected">
        <p>Text Color: </p>
        <ColourBanner
          readonly={readonlySettings}
          field={FIELD_NAMES.HEADER_FOOTER_TEXT}
          callBack={updateColours}
          color={selectedDataCollection?.headerFooterTextColor ?? '#fff'}
        />
      </div>

      <div className="website-header-subheading" style={{ marginTop: '20px' }}>
        Message body
      </div>

      <div className="website-theme-selected">
        <p>Background Colour: </p>
        <ColourBanner
          readonly={readonlySettings}
          field={FIELD_NAMES.MESSAGE_BODY_BG}
          callBack={updateColours}
          color={selectedDataCollection?.bodyBackgroundColor ?? '#000'}
        />
      </div>

      <div className="website-theme-selected">
        <p>Text Color: </p>
        <ColourBanner
          readonly={readonlySettings}
          field={FIELD_NAMES.MESSAGE_BODY_TEXT}
          callBack={updateColours}
          color={selectedDataCollection?.bodyTextColor ?? '#fff'}
        />
      </div>

      <div className="website-header-subheading" style={{ marginTop: '20px' }}>
        Submit Button
      </div>

      <div className="website-theme-selected">
        <p>Background Colour: </p>
        <ColourBanner
          readonly={readonlySettings}
          field={FIELD_NAMES.SUBMIT_BUTTON_BG}
          callBack={updateColours}
          color={selectedDataCollection?.submitButtonColor ?? '#D2D2D2'}
        />
      </div>

      <div className="website-theme-selected">
        <p>Text Color: </p>
        <ColourBanner
          readonly={readonlySettings}
          field={FIELD_NAMES.SUBMIT_BUTTON_TEXT}
          callBack={updateColours}
          color={selectedDataCollection?.submitButtonTextColor ?? '#fff'}
        />
      </div>

      <div className="website-theme-selected">
        <p>Button Text:</p>
        <Input
          disabled={readonlySettings}
          onChange={(e) => changeText(e, 'submit')}
          style={{ width: '200px', marginLeft: 'auto' }}
          value={selectedDataCollection?.submitButtonText ?? ''}
        />
      </div>

      <div className="website-header-subheading" style={{ marginTop: '20px' }}>
        Decline Button
      </div>

      <div className="website-theme-selected">
        <p>Background Colour: </p>
        <ColourBanner
          readonly={readonlySettings}
          field={FIELD_NAMES.DECLINE_BUTTON_BG}
          callBack={updateColours}
          color={selectedDataCollection?.declineButtonColor ?? '#C0C0C0'}
        />
      </div>

      <div className="website-theme-selected">
        <p>Text Color: </p>
        <ColourBanner
          readonly={readonlySettings}
          field={FIELD_NAMES.DECLINE_BUTTON_TEXT}
          callBack={updateColours}
          color={selectedDataCollection?.declineButtonTextColor ?? '#808080'}
        />
      </div>

      <div className="website-theme-selected">
        <p>Button Text:</p>
        <Input
          disabled={readonlySettings}
          onChange={(e) => changeText(e, 'decline')}
          style={{ width: '200px', marginLeft: 'auto' }}
          value={selectedDataCollection?.declineButtonText ?? ''}
        />
      </div>
    </>
  );
}

export default PopupStyle;
