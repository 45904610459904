import React, { ReactNode, useState } from 'react';
import { Button, Form, Input, Radio, RadioChangeEvent } from 'antd';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import DES from 'crypto-js/tripledes';
import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';
import { getActiveSubscriptionId } from '../../../app/common/util/common';
import Stripe from 'stripe';
interface IFormDetails {
  name: string;
  surname: string;
  email: string;
  client_id: string;
  client_secret: string;
}

const PaymentGateways = [
  {
    image: '/assets/images/paypal.png',
    name: 'paypal',
    idMessage: (
      <>
        <div>
          Please navigate to{' '}
          <a
            href="https://developer.paypal.com/developer/applications"
            target="_blank"
            rel="noreferrer">
            PayPal Dashboard
          </a>{' '}
          and select an app or create a new app to view the Client ID and Client
          Secret
        </div>
      </>
    ),
    secretMessage: <></>,
  },
  {
    image: '/assets/images/paygate.png',
    name: 'paygate',
    idMessage: (
      <>
        <div>
          Please enter your PayGate ID. This can be viewed on your PayGate
          dashboard.
        </div>
      </>
    ),
    secretMessage: (
      <>
        <div>
          Please navigate to this{' '}
          <a
            href="https://map.paygate.co.za/admin/config_payweb3.php"
            target="_blank"
            rel="noreferrer">
            PayWeb 3 Dashboard
          </a>{' '}
          to get the secret key.
        </div>
      </>
    ),
  },
  {
    image: '/assets/images/stripe.png',
    name: 'stripe',
    idMessage: (
      <>
        <div>No client ID required for Stripe</div>
      </>
    ),
    secretMessage: (
      <>
        <div>
          Please navigate to this{' '}
          <a
            href="https://dashboard.stripe.com/apikeys"
            target="_blank"
            rel="noreferrer">
            Stripe Dashboard
          </a>{' '}
          to get the secret key. PLEASE SAVE THIS AS YOU CAN ONLY VIEW IT ONCE!
        </div>
      </>
    ),
  },
];

function SalesAddPayment() {
  const { modalStore, salesStore, userStore } = useStore();
  const {
    submittingGateway,
    currentGateways,

    createPaymentGateway,
  } = salesStore;
  const { closeModal } = modalStore;
  const { user } = userStore;
  const activeSubscriptionId = getActiveSubscriptionId();
  const [enteringDetails, setEnteringDetails] = useState(false);
  const [paymentGateway, setPaymentGateway] = useState<string>('');
  const [form] = Form.useForm();
  const [gatewayInfo, setGatewayInfo] = useState<{
    idMessage: ReactNode;
    secretMessage: ReactNode;
  }>({
    idMessage: <></>,
    secretMessage: <></>,
  });

  function optionSelected(e: RadioChangeEvent) {
    setPaymentGateway(e.target.value ?? '');
    const gateway = PaymentGateways.find(
      (s) => s.name === (e.target.value ?? '')
    );
    setGatewayInfo(
      gateway
        ? {
            idMessage: gateway.idMessage,
            secretMessage: gateway.secretMessage,
          }
        : {
            idMessage: <></>,
            secretMessage: <></>,
          }
    );
  }

  async function addGateway(e: IFormDetails) {
    if (!user || !activeSubscriptionId) return;

    const cryptkey = Utf8.parse(process.env.REACT_APP_SECRET_KEY ?? '');
    const iv = Hex.parse('00000000000000000000000000000000');
    const enc = DES.encrypt(e.client_secret, cryptkey, {
      iv,
    });
    let webhookSecret = null;

    switch (paymentGateway) {
      case 'stripe':
        const stripe = new Stripe(e.client_secret, {
          // @ts-expect-error: api version the .net backend uses is incompatible with the node library, see https://stripe.com/docs/api/versioning
          apiVersion: '2020-08-27',
        });
        // todo: add logging if the env is not defined
        if (!process.env.REACT_APP_CUSTOMER_WEBHOOK_URL) return;
        const webhookEndpoint = await stripe.webhookEndpoints.create({
          url: `${process.env.REACT_APP_CUSTOMER_WEBHOOK_URL}?subscriptionId=${activeSubscriptionId}`,
          enabled_events: ['checkout.session.completed'],
          api_version: '2020-08-27',
          description:
            'This webhook was automatically configured with your Happy Snappy account to ensure accurate reporting of customer payments. Please do not remove it while using this Stripe account with your Happy Snappy account.',
        });
        webhookSecret = webhookEndpoint.secret;
        break;
      default:
        break;
    }

    await createPaymentGateway({
      id: 0,
      gatewayName: paymentGateway,
      userName: `${e.name} ${e.surname}`,
      type: paymentGateway,
      status: 'Active',
      gatewayId: '',
      password: '',
      firtsName: e.name,
      lastName: e.surname,
      email: e.email,
      clientId: e.client_id,
      clientKey: enc.toString(),
      subscriptionId: activeSubscriptionId,
      webhookKey: webhookSecret || null,
    });

    closeModal();
  }

  if (!enteringDetails)
    return (
      <>
        <Radio.Group className="add-payment-method" onChange={optionSelected}>
          {PaymentGateways.filter((gateway) => {
            return !currentGateways.includes(gateway.name);
          }).map((gateway) => (
            <Radio value={gateway.name} key={gateway.name}>
              <img src={gateway.image} alt="gateway logo" />
            </Radio>
          ))}
        </Radio.Group>

        <div className="add-payment-method-buttons">
          <Button className="yellow-ant-btn" onClick={modalStore.closeModal}>
            Cancel
          </Button>

          <Button
            disabled={paymentGateway.trim() === ''}
            type="primary"
            onClick={() => setEnteringDetails(true)}>
            Next
          </Button>
        </div>
      </>
    );

  return (
    <>
      <Form
        className="add-payment-details-form"
        name="paymentGatewayDetails"
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={addGateway}>
        <Form.Item
          name="name"
          label="First Name:"
          rules={[{ required: true, message: 'This field is required' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="surname"
          label="Last Name:"
          rules={[{ required: true, message: 'This field is required' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name={'email'}
          label="Email:"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'This is not a valid email',
            },
          ]}>
          <Input autoComplete="new-email" />
        </Form.Item>
        <Form.Item
          name="client_id"
          label="Gateway ID:"
          extra={gatewayInfo.idMessage}
          rules={
            paymentGateway !== 'stripe'
              ? [{ required: true, message: 'This field is required' }]
              : []
          }>
          <Input disabled={paymentGateway === 'stripe'} />
        </Form.Item>
        <Form.Item
          label={'Secret Key:'}
          extra={gatewayInfo.secretMessage}
          name={'client_secret'}
          rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input autoComplete={'client-secret'} />
        </Form.Item>
        <Form.Item className="add-payment-details-form-buttons">
          <Button
            loading={submittingGateway}
            type="text"
            onClick={() => {
              setPaymentGateway('');
              setEnteringDetails(false);
            }}>
            Back
          </Button>

          <Button
            loading={submittingGateway}
            className="yellow-ant-btn cancel-button"
            onClick={modalStore.closeModal}>
            Cancel
          </Button>

          <Button loading={submittingGateway} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default observer(SalesAddPayment);
