import React, { useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useStore } from '../../../app/stores/store';
import { RoleOptions } from '../../../app/common/options/selectOptions';
import { AddMemberDto } from '../../../app/models/team/AddMemberDto';
import { observer } from 'mobx-react-lite';
import { EntityStatus } from 'src/app/common/options/enums';
import { getActiveSubscriptionId } from 'src/app/common/util/common';

function AddTeamMember() {
  const { modalStore, userStore, teamStore, subscriptionsStore } = useStore();
  const { user } = userStore;
  const {
    submitting,

    addMember,
  } = teamStore;
  const { listSubscriptions, pushUser, subscriptions } = subscriptionsStore;

  const activeSubscriptionId = getActiveSubscriptionId();

  useEffect(() => {
    if (!user || !user.isSysAdmin) return;

    listSubscriptions();
  }, [listSubscriptions]);

  async function onFinish(e: any) {
    if (!user) return;

    const member: AddMemberDto = {
      displayName: e.name,
      username: e.email,
      name: e.name,
      email: e.email,
      subscriptionId: user.isSysAdmin ? e.subscription : user.subscriptionId,
      password: e.password,
      isSysAdmin: false,
      isSysManager: false,
      isAdministrator: e.role.includes('admin'),
      isManager: e.role.includes('manager'),
      isPhotographer: e.role.includes('photographer'),
      phone: e.telNumber,
    };

    const result = await addMember(member);
    if (result) {
      const split = result.split('-');
      if (split.length === 3) {
        const dto = {
          id: +split[2],
          name: member.name,
          email: member.email,
          phone: member.phone,
          isAdministrator: member.isAdministrator,
          isManager: member.isManager,
          isPhotographer: member.isPhotographer,
          templateGroup: 0,
          subscriptionId: member.subscriptionId,
          userId: +split[2],
          displayName: member.name,
        };
        pushUser(dto);
      }
    }
    modalStore.closeModal();
  }

  const { Option } = Select;
  return (
    <Form
      className="add-team-member-form"
      name="addTeamMember"
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        subscription: activeSubscriptionId,
      }}
      autoComplete="off">
      <Form.Item
        name="name"
        label="Full name:"
        rules={[{ required: true, message: 'This field is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="role"
        label="Role:"
        rules={[{ required: true, message: 'This field is required' }]}>
        <Select mode="multiple">
          {RoleOptions.map((role) => {
            if (
              !(user ?? { isSysAdmin: false }).isSysAdmin &&
              !(user ?? { isAdministrator: false }).isAdministrator &&
              (role.value === 'admin' || role.value === 'manager')
            ) {
              return;
            }
            return (
              <Option value={role.value} key={`${role.key}${role.value}`}>
                {role.display}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={'email'}
        label="Email:"
        rules={[
          {
            type: 'email',
            message: 'This is not a valid email',
          },
          {
            required: true,
            message: 'This field is required',
          },
        ]}>
        <Input autoComplete="new-email" />
      </Form.Item>
      <Form.Item
        name="telNumber"
        label="Tel. Number:"
        rules={[
          { required: true, message: 'This field is required' },
          {
            pattern: new RegExp(
              '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$'
            ),
            message: 'Invalid phone number.',
          },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Enter password.' },
          { min: 6, message: 'Password must be 6 characters long.' },
          {
            pattern: new RegExp('^(?=.*[A-Z])'),
            message: 'Password must contain 1 capital letter',
          },
          {
            pattern: new RegExp('^(?=.*?[0-9])'),
            message: 'Password must contain 1 number',
          },
        ]}>
        <Input.Password autoComplete="new-password" />
      </Form.Item>
      {user?.isSysAdmin && (
        <Form.Item
          name="subscription"
          label="Account:"
          rules={[
            { required: true, message: 'Selecting an account is required.' },
          ]}>
          <Select
            loading={subscriptionsStore.loadingSubscriptions}
            placeholder="Select an account"
            showSearch
            options={subscriptions
              .filter((s) => s.status === EntityStatus.Active)
              .map((s) => ({ value: s.id, label: s.companyName }))
              .sort((sa, sb) => (sa.label > sb.label ? 1 : -1))}
            optionFilterProp="children"
            filterOption={(
              inputValue,
              option = {
                label: '',
                value: 0,
              }
            ) =>
              option?.label?.toUpperCase().includes(inputValue.toUpperCase())
            }
            onSearch={(value) => {}}
          />
        </Form.Item>
      )}
      <Form.Item className="add-team-member-form-buttons">
        <Button
          className="yellow-ant-btn"
          onClick={modalStore.closeModal}
          loading={submitting}>
          Cancel
        </Button>

        <Button type="primary" htmlType="submit" loading={submitting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default observer(AddTeamMember);
