import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  currency: boolean;
  gateway: boolean;
}

function NoCommerce(props: Props) {
  const { currency, gateway } = props;

  return (
    <>
      <div className={'no-commerce'}>
        <h4>You are unable to sell images because:</h4>

        {currency && (
          <p>
            There are no currencies allowed. <br />{' '}
            <Link to={'/sales?tab=3'}>
              Go to currencies. (Unsaved changes will be lost)
            </Link>
          </p>
        )}

        {gateway && (
          <p>
            No payment gateways have been added. <br />{' '}
            <Link to={'/sales?tab=2'}>
              Go to payment gateways. (Unsaved changes will be lost)
            </Link>
          </p>
        )}
      </div>
    </>
  );
}

export default NoCommerce;
