import { Button, Input, Space, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import EmptyList from '../../../app/layout/EmptyList';
import { useStore } from '../../../app/stores/store';
import { history } from '../../../index';
import { AllSubscriptionItemDto } from '../../../app/models/subscriptions/allSubscriptionItem.dto';
import { EntityStatus } from '../../../app/common/options/enums';
import { ColumnsType } from 'antd/es/table/interface';

function SubscriptionsList() {
  const { subscriptionsStore } = useStore();

  const { allSubscriptions } = subscriptionsStore;

  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const columns: ColumnsType<any> = [
    {
      dataIndex: 'accountName',
      key: 'accountName',
      title: 'Account Name',
      sorter: (
        { accountName: a }: AllSubscriptionItemDto,
        { accountName: b }: AllSubscriptionItemDto
      ) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1),
      defaultSortOrder: 'ascend',
      render: (accountName: string) => (
        <div className="project-member-name">{accountName}</div>
      ),
    },
    {
      dataIndex: 'createdBy',
      key: 'createdBy',
      title: 'Created By',
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      width: '120px',
      render: (status: EntityStatus) => <div>{EntityStatus[status]}</div>,
      sorter: (a: { status: EntityStatus }, b: { status: EntityStatus }) =>
        a.status - b.status,
    },
    {
      dataIndex: 'subscriptionPackage',
      key: 'subscriptionPackage',
      title: 'Subscription Package',
    },
    {
      dataIndex: 'projects',
      key: 'projects',
      title: 'Projects',
    },
    {
      dataIndex: 'createdDate',
      key: 'createdIndex',
      title: 'Created Date',
      render: (createdDate: string) => (
        <div>
          {new Date(createdDate).toLocaleDateString('en-ZA', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </div>
      ),
    },
  ];

  const onRow = (record: AllSubscriptionItemDto) => {
    return {
      onClick: (e: any) => {
        history.push(`/subscription/${record.id}`);
      },
    };
  };

  function enterPress(e: any) {
    setSearchValue(e.target.value);
  }

  function clear(e: any) {
    if (e.target.value.trim() === '') setSearchValue('');
  }

  function searchList() {
    setSearchValue(inputValue);
  }

  return (
    <>
      <div className="all-subscriptions">
        <h3 className="tab-header">All Subscriptions</h3>

        <Space size={10} style={{ marginRight: '30px' }}>
          <Input
            onPressEnter={(e) => enterPress(e)}
            onBlur={(e) => setInputValue(e.target.value)}
            allowClear
            onChange={clear}
          />
          <Button type="primary" onClick={searchList}>
            Search
          </Button>
        </Space>
      </div>

      {allSubscriptions.length < 1 ? (
        <EmptyList
          message="There are no accounts"
          hideButton={true}
          buttonAction={() => {}}
          buttonText=""
        />
      ) : (
        <Table
          className="all-subscriptions-table"
          onRow={onRow}
          columns={columns}
          dataSource={allSubscriptions.filter((s) =>
            s.accountName.toLowerCase().includes(searchValue.toLowerCase())
          )}
          rowKey="id"
        />
      )}
    </>
  );
}

export default observer(SubscriptionsList);
