import React from 'react';
import { Form, Input, Select } from 'antd';
import { ShareThemes } from '../../../../app/common/options/selectOptions';
import { useStore } from '../../../../app/stores/store';

function WebsiteSharing() {
  const { templatesStore, projectStore } = useStore();
  const {
    templateWebsite,

    setTemplateWebsite,
  } = templatesStore;
  const { readonlySettings } = projectStore;

  const [form] = Form.useForm();
  function setDetails(e: any) {
    setTemplateWebsite({
      ...templateWebsite,
      socialIconsSet: form.getFieldValue('icons'),
      socialShareTitle: form.getFieldValue('title'),
      socialShareMessage: form.getFieldValue('message'),
      socialShareHashtags: form.getFieldValue('hashtag'),
    });
  }

  function iconsChanged(e: string) {
    setTemplateWebsite({
      ...templateWebsite,
      socialIconsSet: form.getFieldValue('icons'),
    });
  }

  const { Option } = Select;
  const { TextArea } = Input;
  return (
    <>
      <Form
        name="website-sharing"
        className="website-sharing-form"
        labelCol={{ span: 8 }}
        labelAlign="left"
        form={form}
        initialValues={{
          icons: (templateWebsite ?? { socialIconsSet: 'light' })
            .socialIconsSet,
          title: (templateWebsite ?? { socialShareTitle: '' }).socialShareTitle,
          message: (templateWebsite ?? { socialIconsSet: '' })
            .socialShareMessage,
          hashtag: (templateWebsite ?? { socialShareHashtags: '' })
            .socialShareHashtags,
        }}
        onBlur={(e) => setDetails(e)}>
        <Form.Item name="icons" label="Social Icons">
          <Select onChange={iconsChanged} disabled={readonlySettings}>
            {ShareThemes.map((t) => (
              <Option key={t.value} value={t.value}>
                {t.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="website-header-subheading">Share Details</div>

        <Form.Item
          label="Title:"
          name={'title'}
          style={{ marginTop: '20px' }}
          rules={[{ required: true, message: 'Please enter sharing title.' }]}>
          <Input disabled={readonlySettings} placeholder={'Enter title'} />
        </Form.Item>

        <Form.Item
          label="Message:"
          name={'message'}
          rules={[
            { required: true, message: 'Please enter sharing message.' },
          ]}>
          <TextArea disabled={readonlySettings} placeholder={'Enter message'} />
        </Form.Item>

        <Form.Item
          label="Hashtag:"
          name={'hashtag'}
          help="Example: tag,tag2,tag3"
          rules={[
            { required: true, message: 'Please enter sharing hashtag.' },
          ]}>
          <Input disabled={readonlySettings} placeholder={'Enter hashtag'} />
        </Form.Item>
      </Form>
    </>
  );
}

export default WebsiteSharing;
