import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { Switch } from 'react-router';
import SideBar from './SideBar';
import NavBar from './NavBar';
import Dashboard from '../../../features/dashboard/Dashboard';
import Projects from '../../../features/projects/Projects';
import Templates from '../../../features/templates/Templates';
import QrCodes from '../../../features/qrcodes/QrCodes';
import Team from '../../../features/team/Team';
import Account from '../../../features/account/Account';
import NotFound from '../../../features/errors/NotFound';
import ServerError from '../../../features/errors/ServerError';
import PrivateRoute from '../PrivateRoute';
import LoadingComponent from '../LoadingComponent';
import ViewProject from '../../../features/projects/ViewProject';
import Sales from '../../../features/sales/Sales';
import ActiveTeamMembers from '../../../features/team/ActiveTeamMembers';
import TeamMemberDetails from '../../../features/team/TeamMemberDetails';
import ViewTemplate from '../../../features/templates/ViewTemplate';
import { User } from '../../models/user';
import { history } from '../../../index';
import PaymentSuccess from '../../../features/payments/PaymentSuccess';
import PaymentFailed from '../../../features/payments/PaymentFailed';
import StripeRedirect from '../../../features/account/components/subscription/StripeRedirect';
import ViewSubscription from '../../../features/account/components/ViewSubscription';
import TutorialWalkthrough from '../../common/tutorialWalkthough/TutorialWalkthrough';
import SysAdminRoute from '../SystemAdminRoute';
import { useParams, useLocation } from 'react-router-dom';
import Logger from 'src/app/common/logger/Logger';

function DashboardRoutes() {
  const { Header, Sider, Content } = Layout;

  const {
    commonStore,
    userStore,
    dashboardStore,
    accountStore,
    subscriptionsStore,
    navigationStore,
  } = useStore();
  const { setBaseUrl } = navigationStore;
  const { getOverall } = dashboardStore;

  const { getUser } = userStore;
  const { fetch: fetchSubscriptionDetails } = subscriptionsStore;
  const { fetchDetails } = accountStore;
  const { subId: subscriptionId } = useParams<{ subId: string }>();

  const { token, appLoaded, setAppLoaded } = commonStore;
  const location = useLocation();

  useEffect(() => {
    if (!token || !userStore.user) {
      history.push('/login');
      setAppLoaded();
    }

    if (!subscriptionId || !Number(subscriptionId)) {
      history.push('/not-found');
      return;
    }
    // if (Number(subscriptionId) === selectedSubscription?.id) return;
    commonStore.appLoaded = false;
    setBaseUrl(`/subscription/${subscriptionId}`);

    // todo: handle error if any of these fail

    Promise.all([
      getUser().then((user?: User) => {
        if (!!user && !user.isSysAdmin) history.push('/not-found');
      }),
      fetchSubscriptionDetails(Number(subscriptionId)),
      fetchDetails(),
    ]).finally(() => setAppLoaded());

    // on cleanup remove the selected subscription and temporarily set appLoaded to false
    return () => {
      commonStore.appLoaded = false;
      setBaseUrl('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getUser,
    getOverall,
    fetchSubscriptionDetails,
    subscriptionId,
    location.pathname,
  ]);

  if (!appLoaded)
    return (
      <LoadingComponent content={'Loading Happy Snappy Lite Dashboard...'} />
    );

  return (
    <>
      <Layout className="app-layout">
        <Sider style={{ width: '230px', maxWidth: 0 }}>
          <SideBar />
        </Sider>

        <Layout>
          <Header style={{ zIndex: 1 }}>
            <NavBar />
          </Header>
          <TutorialWalkthrough />
          <Logger />
          <Content style={{ overflowY: 'auto' }}>
            <Switch>
              <SysAdminRoute
                exact
                path="/subscription/:subId/dashboard"
                component={Dashboard}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/projects"
                component={Projects}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/projects/:id"
                component={ViewProject}
              />
              <SysAdminRoute
                exact
                path={[
                  '/subscription/:subId/templates',
                  '/subscription/:subId/projects/:id/templates',
                ]}
                component={Templates}
              />
              <SysAdminRoute
                exact
                path={'/subscription/:subId/templates/:id'}
                component={ViewTemplate}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/codes"
                component={QrCodes}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/payment/success"
                component={PaymentSuccess}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/payment/fail"
                component={PaymentFailed}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/stripe/redirect"
                component={StripeRedirect}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/sales"
                component={Sales}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/team"
                component={Team}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/team/active"
                component={ActiveTeamMembers}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/team/:id"
                component={TeamMemberDetails}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/account"
                component={Account}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/not-found"
                component={NotFound}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/server-error"
                component={ServerError}
              />
              <SysAdminRoute
                exact
                path="/subscription/:subId/"
                component={ViewSubscription}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default observer(DashboardRoutes);
