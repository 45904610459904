import { makeAutoObservable, runInAction } from 'mobx';
import { Template } from '../models/templates/template';
import agent from '../api/Agent';
import { TemplateCreateDTO } from '../models/templates/templateCreateDTO';
import { TemplateCurrencyDTO } from '../models/templates/templateCurrencyDTO';
import { TemplateImageSetFull } from '../models/templates/templateImageSetFull';
import { TemplateImageFull } from '../models/templates/templateImageFull';
import { TemplateImageLayerFull } from '../models/templates/templateImageLayerFull';
import { TemplateInfoDTO } from '../models/templates/templateInfoDTO';
import { TemplateWebsite } from '../models/templates/templateWebsite';
import { TemplateDataCollection } from '../models/templates/templateDataCollection';
import { TemplateImageSetPutDTO } from '../models/templates/templateImageSetPut.dto';
import { DataCollectionDto } from '../models/templates/dataCollection.dto';
import { toast } from 'react-toastify';
import { check } from '../api/listErrors';
import { AxiosError } from 'axios';

export class TemplatesStore {
  loading: boolean = false;
  loadingImageSets: boolean = false;
  loadingDataCollection: boolean = false;
  submitting: boolean = false;
  submittingImageSet: boolean = false;
  submittingWebsite: boolean = false;
  submittingDataCollection: boolean = false;

  // Base template vars
  templateRegistry = new Map<number, Template>();
  archivedTemplateRegistry = new Map<number, Template>();
  selectedTemplate: Template | null = null;
  defaultTemplateCurrency: TemplateCurrencyDTO | null = null;
  triggerSave: boolean = false;
  disableSubmitSettings: boolean = false;
  disableSubmitCommerce: boolean = false;
  disableSubmitLayers: boolean = false;
  selectedDataCollection: TemplateDataCollection | null = null;

  // Image set vars
  imageSetRegistry = new Map<number, TemplateImageSetFull>();
  selectedImageSet: TemplateImageSetFull | null = null;
  imageSetOrientation: string = 'landscape';

  templateImages: TemplateImageFull | null = null;
  selectedImage: TemplateImageLayerFull | null = null;

  // Website and data collection
  templateWebsite: TemplateWebsite | null = null;
  disablePopupSave: boolean = true;
  popupResultRegistry = new Map<number, DataCollectionDto>();

  // Template preview
  showingBackground: boolean = false;

  updatingTemplate: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  setSubmitting = (loading: boolean) => {
    this.submitting = loading;
  };

  setSubmittingImageSet = (value: boolean) => {
    this.submittingImageSet = value;
  };

  setSubmittingWebsite = (value: boolean) => {
    this.submittingWebsite = value;
  };

  setSubmittingDataCollection = (value: boolean) => {
    this.submittingDataCollection = value;
  };

  setLoadingDataCollection = (value: boolean) => {
    this.loadingDataCollection = value;
  };

  setShowingBackground = (value: boolean) => {
    this.showingBackground = value;
  };

  setUpdatingTemplate = (value: boolean) => {
    this.updatingTemplate = value;
  };

  /**
   * Template object methods
   */

  setSelectedTemplate = (template: Template) => {
    this.selectedTemplate = template;
  };

  setTrigger = () => {
    this.triggerSave = !this.triggerSave;
  };

  getTemplatesByProject = async () => {
    try {
      this.setLoading(true);
      const templates = await agent.Templates.listByProject('1');
      runInAction(() => {
        templates.forEach((t: Template) => {
          this.templateRegistry.set(t.id, t);
        });
      });
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  getTemplatesBySubscription = async (id: string, queryParams?: any) => {
    try {
      this.setLoading(true);

      this.templateRegistry.clear();
      const templates = await agent.Templates.listBySubscription(
        id,
        queryParams
      );
      templates.forEach((t: Template) => {
        this.setTemplate(t);
      });
      return templates.length;
    } catch (error) {
      if (typeof error !== 'string') {
        throw error;
      }

      return 0;
    } finally {
      this.setLoading(false);
    }
  };

  clearSelectedLayer = () => {
    this.selectedImage = null;
  };

  fetchArchivedTemplates = async (id: string) => {
    try {
      this.setLoading(true);

      const result = await agent.Templates.listBySubscription(id, {
        filter: 'archived',
      });
      result.forEach((t: Template) => {
        this.setArchivedTemplate(t);
      });
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  private setArchivedTemplate = (template: Template) => {
    this.archivedTemplateRegistry.set(template.id, template);
  };

  get getArchivedTemplates() {
    return Array.from(this.archivedTemplateRegistry.values());
  }

  get getTemplates() {
    return Array.from(this.templateRegistry.values());
  }

  filterTemplates = (type: 'all' | 'green' | 'standard') => {
    switch (type) {
      case 'green':
        return this.greenScreenTemplates;

      case 'standard':
        return this.standardTemplates;

      case 'all':
      default:
        return this.getTemplates;
    }
  };

  get standardTemplates() {
    return Array.from(this.templateRegistry.values()).filter(
      (value) => !value.isGreenScreen
    );
  }

  get greenScreenTemplates() {
    return Array.from(this.templateRegistry.values()).filter(
      (value) => value.isGreenScreen
    );
  }

  createTemplate = async (template: TemplateCreateDTO) => {
    try {
      this.setSubmitting(true);
      const response = await agent.Templates.createTemplate(template);
      this.setTemplate(response);
      return response.id;
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  setDisableSubmitSettings = (value: boolean) => {
    this.disableSubmitSettings = value;
  };

  setDisableSubmitCommerce = (value: boolean) => {
    this.disableSubmitCommerce = value;
  };

  setDisableSubmitLayers = (value: boolean) => {
    this.disableSubmitLayers = value;
  };

  private setTemplate = (template: Template) => {
    this.templateRegistry.set(template.id, template);
  };

  private getTemplate = (id: number) => {
    return this.templateRegistry.get(id);
  };

  private removeTemplate = (id: number) => {
    this.templateRegistry.delete(id);
  };

  fetchTemplate = async (id: number) => {
    try {
      this.setLoading(true);
      this.setLoadingImageSets(true);
      this.imageSetRegistry.clear();
      const response = await agent.Templates.getById(id, { mode: 'full' });
      runInAction(() => {
        this.selectedTemplate = response;
        this.templateWebsite = response.templateWebsite ?? null;
        this.selectedImage = null;
      });

      await this.fetchImageSets(response.id);
      await this.fetchDataCollection(response.id);
    } catch (error) {
      console.error(error);
    } finally {
      this.setLoading(false);
    }
  };

  fetchDataCollection = async (id: number) => {
    try {
      this.setLoadingDataCollection(true);

      const result = await agent.Templates.getDataCollection(id);
      this.setDataCollection(result);
      this.setDisablePopupSave();
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingDataCollection(false);
    }
  };

  fetchCollectionResults = async (
    id: number,
    startDate: string,
    endDate: string
  ) => {
    try {
      this.setLoadingDataCollection(true);
      this.popupResultRegistry.clear();

      const result = await agent.Templates.popupResults(id, startDate, endDate);
      result.forEach((dt) => {
        this.setCollectionResult(dt);
      });
    } catch (error) {
      this.popupResultRegistry.clear();
      throw error;
    } finally {
      this.setLoadingDataCollection(false);
    }
  };

  exportingData: boolean = false;
  setExportingData = (value: boolean) => {
    this.exportingData = value;
  };

  exportDataCollection = async (
    id: number,
    startDate: string,
    endDate: string
  ) => {
    try {
      this.setExportingData(true);

      return await agent.Templates.exportDataCollection(id, startDate, endDate);
    } catch (error) {
      throw error;
    } finally {
      this.setExportingData(false);
    }
  };

  private setCollectionResult = (dto: DataCollectionDto) => {
    this.popupResultRegistry.set(dto.id, dto);
  };

  get dataCollectionResults() {
    return Array.from(this.popupResultRegistry.values());
  }

  setDataCollection = (value: TemplateDataCollection | null) => {
    this.selectedDataCollection = value;
  };

  updateTemplate = async (subscriptionId: number) => {
    try {
      if (!this.selectedTemplate) return;
      const promises = [];
      this.setUpdatingTemplate(true);

      this.setSubmitting(true);
      const dto: TemplateInfoDTO = {
        id: this.selectedTemplate.id,
        subscriptionId,
        name: this.selectedTemplate.name ?? '',
        description: this.selectedTemplate.description ?? '',
        isGreenScreen: this.selectedTemplate.isGreenScreen ?? false,
        sellImages: this.selectedTemplate.sellImages,
        subscriptionCurrencyId: this.selectedTemplate.subscriptionCurrencyId,
        singleImagePrice: this.selectedTemplate.singleImagePrice,
        imageSetPrice: this.selectedTemplate.imageSetPrice,
        paymentGatewayId: this.selectedTemplate.paymentGatewayId,
      };

      for (const imageSet of this.imageSets) {
        if (imageSet.updated) {
          promises.push(
            agent.Templates.updateImageSet(imageSet.id, {
              id: imageSet.id,
              name: imageSet.name,
            })
          );
        }

        for (const image of imageSet.templateImages) {
          promises.push(agent.Templates.updateImages(image));
        }
      }
      promises.push(agent.Templates.updateTemplateInfo(dto));
      const results = await Promise.all(promises);

      results.forEach((r: boolean | TemplateImageFull) => {
        if (typeof r === 'boolean') return;

        this.setUpdateImageLayer(r);
      });

      this.imageSetRegistry.clear();
      (this.selectedTemplate.templateImageSets ?? []).forEach((im) => {
        this.setImageSet(im);
      });

      toast.success('Template settings and layers saved!');
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
      this.setUpdatingTemplate(false);
    }
  };

  private setImageSet = (im: TemplateImageSetFull) => {
    this.imageSetRegistry.set(im.id, im);

    if ((this.selectedImageSet ?? { id: -1 }).id === im.id) {
      this.setSelectedImageSet(im);
    }
  };

  private setUpdateImageLayer = (r: TemplateImageFull) => {
    if (!this.selectedTemplate) return;

    const imageSet = (this.selectedTemplate.templateImageSets ?? []).find(
      (im) => im.id === r.templateImageSetId
    );
    if (imageSet) {
      const index = imageSet.templateImages.findIndex(
        (templateImage: TemplateImageFull) => templateImage.id === r.id
      );

      if (index === -1) return;

      imageSet.templateImages[index] = r;
      if (this.templateImages!.id === r.id) this.templateImages = r;

      if (imageSet.orderIndex === 0 && !r.isPortrait) {
        this.selectedTemplate.sampleImageUrl = r.thumbUrl;
      }
    }
  };

  private setThumbnailUrl = (url: string) => {
    if (!this.selectedTemplate) return;

    this.selectedTemplate.sampleImageUrl = url;
  };

  deleteTemplate = async (id: number) => {
    try {
      const template = this.getArchivedTemplate(id);
      this.removeArchivedTemplate(id);
      await agent.Templates.delete(template!.code);

      runInAction(() => {
        if (this.selectedTemplate && this.selectedTemplate.id === id) {
          this.selectedTemplate = null;
        }
      });
    } catch (error) {
      throw error;
    }
  };

  restoring: boolean = false;
  setRestoring = (value: boolean) => {
    this.restoring = value;
  };

  restoreTemplate = async (id: number) => {
    try {
      this.setRestoring(true);
      const template = this.getArchivedTemplate(id);

      if (!template) return;

      await agent.Templates.updateStatus(id, 'active');

      runInAction(() => {
        this.removeArchivedTemplate(id);
        this.templateRegistry.set(id, template);
      });
    } catch (error) {
      const e = error as AxiosError;
      if (e.response !== undefined) {
        toast.error(
          (e.response.data ?? { error: 'Error restoring template.' }).error
        );
        return;
      }

      throw error;
    } finally {
      this.setRestoring(false);
    }
  };

  archiving: boolean = false;
  setArchiving = (value: boolean) => {
    this.archiving = value;
  };

  archiveTemplate = async (id: number) => {
    try {
      this.setArchiving(true);
      const template = this.getTemplate(id);

      if (!template) return;

      await agent.Templates.updateStatus(id, 'archived');

      runInAction(() => {
        this.templateRegistry.delete(id);
        this.archivedTemplateRegistry.set(id, template);

        if (this.selectedTemplate && this.selectedTemplate.id === id) {
          this.selectedTemplate = null;
        }
      });
    } catch (error) {
      throw error;
    } finally {
      this.setArchiving(false);
    }
  };

  private getArchivedTemplate = (id: number) => {
    return this.archivedTemplateRegistry.get(id);
  };

  private removeArchivedTemplate = (id: number) => {
    this.archivedTemplateRegistry.delete(id);
  };

  clearTemplate = () => {
    this.selectedImage = null;
    this.selectedImageSet = null;
    this.selectedTemplate = null;
    this.templateImages = null;
    this.imageSetOrientation = 'landscape';
    this.imageSetRegistry.clear();
  };

  searchTemplates = (value: string) => {
    // this.templateRegistry.
  };

  /**
   * Template image methods.
   */

  uploadTemplateFile = async (file: any) => {
    if (!this.selectedTemplate) return '';

    if (!file) return '';

    const formData = new FormData();
    formData.append('fileData', file as File);
    formData.append('uploadDescription', 'Image Layer for image with Id 14');
    formData.append('templateId', '14');

    const response = await agent.Templates.uploadFile(formData);
    return response.data.fileUrl;
  };

  updateILayer = async (layer: TemplateImageFull) => {
    await agent.Templates.updateImages(layer);
  };

  addImageLayer = (layer: TemplateImageLayerFull) => {
    if (!this.templateImages) return;

    this.templateImages.templateImageLayers.push(layer);
  };

  get templateSetImages() {
    if (!this.templateImages) return [];
    return this.templateImages.templateImageLayers;
  }

  setTemplateSetImages = (arr: TemplateImageLayerFull[]) => {
    if (!this.templateImages) return [];
    arr.forEach((image: TemplateImageLayerFull, index: number) => {
      image.orderIndex = index;
    });
    this.templateImages = {
      ...this.templateImages,
      templateImageLayers: arr,
    };
  };

  updateImageOrder = () => {
    this.templateSetImages.forEach((i) => {});
  };

  setSelectedImage = (layer: TemplateImageLayerFull | null) => {
    this.selectedImage = layer;
  };

  removeLayer = (id: any) => {
    if (!this.templateImages) return;

    const index = this.templateImages.templateImageLayers.findIndex((image) =>
      image.newId ? image.newId === id : image.id === +id
    );
    this.templateImages.templateImageLayers.splice(index, 1);
  };

  updateLayerPosition = (top: number, left: number) => {
    if (!this.selectedImage) return;

    switch (this.imageSetOrientation) {
      default:
      case 'landscape':
        this.selectedImage.top = top / 1748;
        this.selectedImage.left = left / 2480;
        break;

      case 'portrait':
        this.selectedImage.top = top / 2480;
        this.selectedImage.left = left / 1748;
        break;
    }
  };

  updateLayerSize = (width: number, height: number) => {
    if (!this.selectedImage) return;

    this.selectedImage.width = Math.round(width);
    this.selectedImage.height = Math.round(height);
  };

  /**
   * Store methods for handling image sets/template variations.
   * This should cover:
   *  get image sets
   *  delete image set
   *  create image set
   *  update image set
   */

  fetchImageSets = async (id: number) => {
    try {
      this.setLoadingImageSets(true);

      let response = [];
      if (!this.selectedTemplate?.templateImageSets) {
        response = await agent.Templates.getImageSets(id);
      } else {
        response = this.selectedTemplate!.templateImageSets;
      }

      response.forEach((imageSet: TemplateImageSetFull) => {
        this.imageSetRegistry.set(imageSet.id, imageSet);
      });
      this.setSelectedImageSet(response[0]);
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingImageSets(false);
    }
  };

  setLoadingImageSets = (value: boolean) => {
    this.loadingImageSets = value;
  };

  get imageSets() {
    return Array.from(this.imageSetRegistry.values());
  }

  createImageSet = async (id: number) => {
    try {
      this.setSubmittingImageSet(true);

      const result = await agent.Templates.createImageSet(id);
      this.setSelectedImageSet(result, true);
      this.imageSetRegistry.set(result.id, result);
      if (!this.selectedTemplate) return;

      this.selectedTemplate.templateImageSets?.push(result);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmittingImageSet(false);
    }
  };

  setSelectedImageSet = (
    value: TemplateImageSetFull | null,
    created: boolean = false
  ) => {
    this.selectedImageSet = value;

    if (value) {
      const images = value.templateImages.find(
        (ti) => ti.isPortrait === (this.imageSetOrientation === 'portrait')
      );
      if (images) {
        images.templateImageLayers.sort(
          (l1, l2) => l1.orderIndex - l2.orderIndex
        );
        this.templateImages = images;
      }
    }
  };

  deleteImageSet = async (id: number) => {
    try {
      this.imageSetRegistry.delete(id);
      this.setSelectedImageSet(this.imageSets[0]);
      await agent.Templates.deleteImageSet(id);

      if (!this.selectedTemplate) return;

      const index = (this.selectedTemplate.templateImageSets ?? []).findIndex(
        (t: TemplateImageSetFull) => t.id === id
      );
      if (index === -1) return;

      this.selectedTemplate.templateImageSets?.splice(index, 1);
    } catch (error) {
      console.error(error);
    }
  };

  setImageSetOrientation = (value: string) => {
    if (!this.selectedImageSet) return;
    this.imageSetOrientation = value;

    const images = this.selectedImageSet.templateImages.find(
      (ti) => ti.isPortrait === (value === 'portrait')
    );
    if (images) this.templateImages = images;
  };

  updateTemplateImages = (value: TemplateImageFull) => {
    if (!this.selectedImageSet || !this.templateImages || !this.imageSets)
      return;
    let index = this.selectedImageSet.templateImages.findIndex(
      (images) => images.id === this.templateImages!.id
    );
    this.selectedImageSet.templateImages[index] = value;
    this.templateImages = value;

    runInAction(() => {
      index = this.imageSets.findIndex(
        (im) => im.id === value.templateImageSetId
      );
      const imagesIndex = this.imageSets[index].templateImages.findIndex(
        (ti) => ti.id === value.id
      );
      this.imageSets[index].templateImages[imagesIndex] = value;
    });
  };

  updateTemplateImageSet = async (body: TemplateImageSetPutDTO) => {
    try {
      if (!this.selectedImageSet || !this.selectedTemplate) return;

      const result: TemplateImageSetFull = await agent.Templates.updateImageSet(
        body.id,
        body
      );
      this.setSelectedImageSet(result);
    } catch (error) {}
  };

  updateImageSetName = (id: number, name: string) => {
    const imageSet = this.imageSetRegistry.get(id);

    if (!imageSet) return;

    imageSet.name = name;
    imageSet.updated = true;
  };

  setDisablePopupSave = () => {
    if (!this.selectedDataCollection) {
      this.disablePopupSave = true;
      return;
    }

    this.disablePopupSave =
      (!this.selectedDataCollection.collectEmail &&
        !this.selectedDataCollection.collectPhone &&
        !this.selectedDataCollection.collectName) ||
      this.selectedDataCollection.bodyText?.trim() === '' ||
      this.selectedDataCollection.headerText?.trim() === '' ||
      this.selectedDataCollection.termsText?.trim() === '' ||
      this.selectedDataCollection.submitButtonText?.trim() === '' ||
      this.selectedDataCollection.declineButtonText?.trim() === '';
  };

  setTemplateWebsite = (value: TemplateWebsite | null) => {
    this.templateWebsite = value;
  };

  updateTemplateWebsite = async (body: TemplateWebsite, groupId?: string) => {
    try {
      this.setSubmittingWebsite(true);

      await agent.Templates.updateWebsite(body, {
        templateId: body.templateId!.toString(),
        templateGroupId: groupId,
      });
      toast.success('Template website saved!');
    } catch (error) {
      throw error;
    } finally {
      this.setSubmittingWebsite(false);
    }
  };

  updateDataCollection = async (
    body: TemplateDataCollection,
    groupId?: string
  ) => {
    try {
      this.setSubmittingDataCollection(true);

      await agent.Templates.updateDataCollection(body, {
        templateId: body.templateId!.toString(),
        templateGroupId: groupId,
      });
      toast.success('Template data collection popup saved!');
    } catch (error) {
      throw error;
    } finally {
      this.setSubmittingDataCollection(false);
    }
  };

  startTemplateDataCollection = async (id: number) => {
    try {
      this.setSubmittingDataCollection(true);

      await agent.Templates.startDataCollection(id);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmittingDataCollection(false);
    }
  };

  stopTemplateDataCollection = async (id: number) => {
    try {
      this.setSubmittingDataCollection(true);

      await agent.Templates.stopDataCollection(id);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmittingDataCollection(false);
    }
  };
}
