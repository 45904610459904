import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useQuery from '../../../../app/common/util/hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useStore } from '../../../../app/stores/store';
import { history } from '../../../../index';

function StripeRedirect() {
  const { subscriptionsStore, stripeStore } = useStore();
  const { changeSubscription } = subscriptionsStore;
  const { getIntent } = stripeStore;

  const query = useQuery();
  const [stage, setStage] = useState('loading');
  const [message, setMessage] = useState('');
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  useEffect(() => {
    const id = query.get('payment_intent');
    const subId = query.get('subscription');

    if (!id || !subId) {
      setStage('error');
      setMessage('There was an error getting the ID from the redirect url');
      return;
    }

    getIntent(id).then((response: any) => {
      switch (response.status) {
        case 'succeeded':
          changeSubscription(2, subId).then(() => {
            setStage('success');
            setMessage('Payment was successful, please click to  continue!');
          });
          break;

        case 'processing':
          setStage('info');
          setMessage('Payment is being processed!');
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setStage('error');
          setMessage('Payment failed. Please try another payment method.');
          break;

        default:
          setStage('error');
          setMessage('Please try again.');
          break;
      }
    });
  }, [getIntent]);

  return (
    <>
      <div className="stripe-redirect">
        <h2>
          {stage === 'loading' || stage === 'processing'
            ? 'Verifying Subscription Payment...'
            : stage === 'success'
            ? 'Subscription payment received.'
            : 'Subscription payment failed.'}
        </h2>

        <p>{message}</p>

        {(stage === 'success' || stage === 'error') && (
          <Button type="primary" href={'/account?tab=2'}>
            {stage === 'success' ? 'Continue' : 'Go back'}
          </Button>
        )}

        {stage === 'loading' && <Spin indicator={antIcon} />}
      </div>
    </>
  );
}

export default observer(StripeRedirect);
