import { makeAutoObservable } from 'mobx';

export class AddMemberStore {
  selectedMember: any | null = {
    id: 1,
    name: 'John',
    surname: 'Doe',
    role: 'This is a generic role given.',
    email: 'John@doe.com',
    telNumber: '000 000 0000',
  };

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedMember = (member: any) => {
    this.selectedMember = member;
  };
}
