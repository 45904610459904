import React from 'react';
import useQuery from '../../app/common/util/hooks';
import EnterEmail from './components/EnterEmail';
import EnterPasswords from './components/EnterPasswords';

function ForgotPassword() {
  const query = useQuery();
  const resetKey = query.get('key');

  if (resetKey) return <EnterPasswords resetKey={resetKey} />;

  return (
    <>
      <EnterEmail />
    </>
  );
}

export default ForgotPassword;
