import { makeAutoObservable } from 'mobx';
import { fabric } from 'fabric';
import { IEvent } from 'fabric/fabric-impl';

const Orientation = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
};

const portrait = document.createElement('img');
portrait.src = `/assets/default/portrait-photo-gs.png`;
const landscape = document.createElement('img');
landscape.src = `/assets/default/landscape-photo-gs.png`;

export default class FabricStore {
  canvas: fabric.Canvas | null = null;
  canvasLoaded: boolean = false;
  isDragging: boolean = false;
  mouseDown: boolean = false;
  lastPosX = 0;
  lastPosY = 0;

  constructor() {
    makeAutoObservable(this);
  }

  createCanvas = (
    id: string,
    options: fabric.ICanvasOptions,
    greenScreen: boolean,
    scale: number,
    background?: string
  ) => {
    this.canvas = new fabric.Canvas(id, options);

    if (greenScreen && background) {
      fabric.Image.fromURL(background, (oImg: fabric.Image) =>
        this.addBackground(oImg, 'landscape', true)
      );
    }

    this.canvas.setDimensions(
      {
        height: `${this.canvas.height! * scale}px`,
        width: `${this.canvas.width! * scale}px`,
      },
      { cssOnly: true }
    );
    this.canvas.on('object:moving', function (e) {
      let obj = e.target;
      if (!obj) return;
      obj.setCoords();
      // top-left  corner
      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top!, obj.top! - obj.getBoundingRect().top);
        obj.left = Math.max(obj.left!, obj.left! - obj.getBoundingRect().left);
      }
      // bot-right corner
      if (
        obj.getBoundingRect().top + obj.getBoundingRect().height >
          obj.canvas!.height! ||
        obj.getBoundingRect().left + obj.getBoundingRect().width >
          obj.canvas!.width!
      ) {
        obj.top = Math.min(
          obj.top!,
          obj.canvas!.height! -
            obj.getBoundingRect().height +
            obj.top! -
            obj.getBoundingRect().top
        );
        obj.left = Math.min(
          obj.left!,
          obj.canvas!.width! -
            obj.getBoundingRect().width +
            obj.left! -
            obj.getBoundingRect().left
        );
      }
    });

    fabric.Image.fromURL(
      `/assets/default/landscape-photo${greenScreen ? '-gs.png' : '.jpg'}`,
      (oImg) => {
        this.addShape(oImg);
        oImg.sendToBack();
      },
      {
        top: 0,
        left: 0,
        selectable: false,
        evented: false,
        name: 'people-placeholder',
        scaleX: 2480 / 3126,
        scaleY: 1748 / 2251,
      }
    );

    this.setCanvasLoaded(true);
  };

  addBackground = (
    shape: fabric.Image,
    orientation: string,
    firstLoad?: boolean
  ) => {
    if (!this.canvas) return;

    this.canvas.setBackgroundImage(shape, (e: any) => {
      e.scaleY =
        (orientation === Orientation.LANDSCAPE ? 1748 : 2480) / shape.height!;
      e.scaleX =
        (orientation === Orientation.LANDSCAPE ? 2480 : 1748) / shape.width!;

      if (orientation === Orientation.LANDSCAPE) {
        e.scaleToWidth(2480);
      }
      e.top = this.canvas?.getCenter().top;
      e.originY = 'center';
      this.canvas!.requestRenderAll();
    });

    const image = this.canvas
      .getObjects()
      .find((o) => o.name === 'people-placeholder');
    if (image || firstLoad) return;

    this.addPeoplePlaceholder(orientation);
  };

  updateScale(scale: number, c: fabric.Canvas) {
    c.setDimensions(
      {
        height: `${c.height! * scale}px`,
        width: `${c.width! * scale}px`,
      },
      { cssOnly: true }
    );
  }

  private setMouseDown = (opt: IEvent<MouseEvent>) => {
    const evt = opt.e;
    this.mouseDown = true;
    this.lastPosX = evt.clientX;
    this.lastPosY = evt.clientY;
  };
  private setMouseUp = () => {
    this.mouseDown = false;
  };
  private dragCanvas = (opt: IEvent<MouseEvent>) => {
    if (this.isDragging && this.mouseDown) {
      let e = opt.e;
      const vpt = this.canvas!.viewportTransform;
      if (!vpt) return;
      vpt[4] += e.clientX - this.lastPosX;
      vpt[5] += e.clientY - this.lastPosY;
      this.canvas!.requestRenderAll();
      this.lastPosX = e.clientX;
      this.lastPosY = e.clientY;
    }
  };

  canvasZoomIn = () => {
    if (!this.canvas) return;

    const zoom = this.canvas.getZoom();
    this.canvas.setZoom(zoom + 0.1);
  };

  canvasZoomOut = () => {
    if (!this.canvas) return;

    const zoom = this.canvas.getZoom();
    this.canvas.setZoom(zoom - 0.1);
  };

  get canvasZoom() {
    if (!this.canvas) return 0;
    return this.canvas.getZoom();
  }

  resetZoom = () => {
    if (!this.canvas) return;
    this.canvas.setZoom(1);
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  };

  setDragging = (value: boolean) => {
    if (!this.canvas) return;

    this.isDragging = value;
    if (value) {
      this.canvas.on('mouse:move', (e) => this.dragCanvas(e));
      this.canvas.on('mouse:down', (e) => this.setMouseDown(e));
      this.canvas.on('mouse:up', () => this.setMouseUp());
    } else {
      this.canvas.off('mouse:move');
      this.canvas.off('mouse:down');
      this.canvas.off('mouse:up');
    }

    this.canvas.getObjects().forEach((o: fabric.Object) => {
      o.set('selectable', !value);
    });
    this.canvas.discardActiveObject();
    this.canvas.requestRenderAll.bind(this.canvas);
  };

  setCanvasHandler = (eventName: string, handler: (e: any) => void) => {
    if (this.canvas) {
      this.canvas.on(eventName, (e) => {
        handler(e);
      });
    }
  };

  setObjectHandler = (
    eventName: string,
    objectName: string,
    handler: (e: any) => void
  ) => {
    if (!this.canvas) return;

    const object = this.canvas.getObjects().find((o) => o.name === objectName);

    if (!object) return;
    object.on(eventName, (e) => {
      handler(e);
    });
  };

  setCanvasLoaded = (value: boolean) => {
    this.canvasLoaded = value;
  };

  addShape = (shape: any) => {
    if (this.canvas) {
      this.canvas.add(shape);
    }
  };

  updateObjectDimensions = (name: string, h: number, w: number) => {
    if (!this.canvas) return;

    const object = this.canvas.getObjects().find((o) => o.name === name);
    if (object) {
      object.set('width', w);
      object.set('height', h);
      object.setCoords();
      this.canvas.requestRenderAll();
    }
  };

  getObject = (name: string) => {
    if (!this.canvas) return;

    return this.canvas.getObjects().find((o) => o.name === name);
  };

  updateObjectPosition = (name: string, left: number, top: number) => {
    if (!this.canvas) return;

    const object = this.canvas.getObjects().find((o) => o.name === name);
    if (object) {
      object.set('top', +top);
      object.set('left', +left);
      object.setCoords();
      this.canvas.requestRenderAll();
    }
  };

  removeObject = (name: string) => {
    if (!this.canvas) return;
    const object = this.canvas.getObjects().find((o) => o.name === name);

    if (object) this.canvas.remove(object);
  };

  selectObject = (name: string) => {
    if (!this.canvas) return;
    const object = this.canvas.getObjects().find((o) => o.name === name);

    if (object) {
      this.canvas.setActiveObject(object);
      this.canvas.requestRenderAll();
    }
  };

  addPeoplePlaceholder(orientation: string, gs = true) {
    if (!this.canvas) return;

    const placeholder = new fabric.Image(
      orientation === Orientation.LANDSCAPE ? landscape : portrait
    );
    placeholder.set('top', 0);
    placeholder.set('left', 0);
    placeholder.set('selectable', false);
    placeholder.set('evented', false);
    placeholder.set('name', 'people-placeholder');
    placeholder.set(
      'scaleX',
      (orientation === Orientation.LANDSCAPE ? 2480 : 1748) /
        (orientation === Orientation.LANDSCAPE ? 3126 : 2251)
    );
    placeholder.set(
      'scaleY',
      (orientation === Orientation.LANDSCAPE ? 1748 : 2480) /
        (orientation === Orientation.LANDSCAPE ? 2251 : 3126)
    );
    this.addShape(placeholder);
    placeholder.sendToBack();
  }

  setCanvasOrientation = (
    orientation: string,
    greenScreen: boolean,
    scale: number,
    background?: string
  ) => {
    if (!this.canvas) return;
    const image = this.canvas
      .getObjects()
      .find((o) => o.name === 'people-placeholder');

    if (greenScreen && !background) {
      const temp = new fabric.Image('');
      this.addBackground(temp, orientation);

      this.addPeoplePlaceholder(orientation);
    }

    switch (orientation) {
      default:
      case 'landscape':
        this.canvas.setWidth(2480);
        this.canvas.setHeight(1748);

        if (image && !greenScreen) {
          (image as fabric.Image).setSrc(
            `/assets/default/landscape-photo.jpg`,
            (oImg: fabric.Image) => {
              this.addShape(oImg);
              oImg.sendToBack();
            },
            {
              scaleX: 2480 / 3126,
              scaleY: 1748 / 2251,
            }
          );
        }
        break;

      case 'portrait':
        this.canvas.setHeight(2480);
        this.canvas.setWidth(1748);

        if (image && !greenScreen) {
          (image as fabric.Image).setSrc(
            `/assets/default/portrait-photo.jpg`,
            (oImg: fabric.Image) => {
              this.addShape(oImg);
              oImg.sendToBack();
            },
            {
              scaleX: 1748 / 2251,
              scaleY: 2480 / 3126,
            }
          );
        }
        break;
    }

    this.canvas.setDimensions(
      {
        height: `${this.canvas.height! * scale}px`,
        width: `${this.canvas.width! * scale}px`,
      },
      { cssOnly: true }
    );
  };

  changeLevel = (moveUp: boolean, id: string) => {
    if (!this.canvas) return;
    const object = this.canvas.getObjects().find((o) => o.name === id);
    if (!object) return;

    if (moveUp) {
      this.canvas.sendBackwards(object);
    } else {
      this.canvas.bringForward(object);
    }

    this.canvas.requestRenderAll();
  };

  clearCanvas = () => {
    if (!this.canvas) return;

    this.canvas.remove(...this.canvas.getObjects());
  };

  removeCanvas = () => {
    if (!this.canvas) return;

    this.canvas.clear();
    this.canvas.dispose();
  };

  setCanvasBackground = (color: string) => {
    if (!this.canvas) return;

    this.canvas.setBackgroundColor(color, () => {
      this.canvas?.requestRenderAll();
    });
  };
}
