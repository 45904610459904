import React, { ReactNode, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Checkbox, Form } from 'antd';

interface Props {
  confirm: () => void;
  eulaLink: string;
  termsAndConditionsLink: string;

  confirmText?: string;
}

function TermsAndConditionsConfirmationModal(props: Props) {
  const {
    eulaLink,
    termsAndConditionsLink,
    confirm,
    confirmText = 'Delete',
  } = props;

  const [termsAccepted, setTermsAccepted] = useState(false);

  return (
    <>
      <div>
        <div
          style={{
            marginTop: '15%',
            textAlign: 'start',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 10px',
          }}>
          <div
            style={{
              marginTop: '-15%',
              lineHeight: '2',
            }}>
            <p>
              By signing up, you agree to our{' '}
              <strong>
                <a
                  href={termsAndConditionsLink}
                  target="_blank"
                  rel="noreferrer">
                  Terms and Conditions
                </a>
              </strong>{' '}
              and{' '}
              <strong>
                <a href={eulaLink} target="_blank" rel="noreferrer">
                  End User License Agreement (EULA)
                </a>
              </strong>
              . Please read these agreements carefully before using our app.{' '}
              <strong>
                If you do not agree to these terms, please do not use our app
              </strong>
              . Our Terms and Conditions and EULA cover important topics such as
              user conduct, intellectual property rights, disclaimers of
              warranties, and limitations of liability. <br />
              <br />
              These agreements are designed to protect both you and us, and to
              make sure that we can continue to provide you with the best
              possible service. If you have any questions about our Terms and
              Conditions or EULA, please contact us at{' '}
              <a
                href="mailto:support@happysnappy.com"
                target="_blank"
                rel="noreferrer">
                support@happysnappy.com
              </a>
              .
            </p>
          </div>

          <div
            style={{
              display: 'flex',

              gap: '25px',
              margin: '20px 0 30px',
            }}>
            <label
              style={{
                display: 'flex',

                gap: '10px',
              }}>
              <Checkbox
                checked={termsAccepted}
                onChange={() => {
                  setTermsAccepted((prev) => !prev);
                }}
              />

              <span>
                I have read and agree to the{' '}
                <a
                  href={termsAndConditionsLink}
                  target="_blank"
                  rel="noreferrer">
                  Terms and Conditions
                </a>{' '}
                and{' '}
                <a href={eulaLink} target="_blank" rel="noreferrer">
                  End User License Agreement (EULA).
                </a>
              </span>
            </label>

            <Button
              type="primary"
              htmlType="submit"
              disabled={termsAccepted === false}
              onClick={confirm}>
              {confirmText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(TermsAndConditionsConfirmationModal);
