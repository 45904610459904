import { Button, Layout, Modal, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../app/stores/store';
import ProjectSummary from './components/ProjectSummary';
import ProjectTemplates from './components/ProjectTemplates';
import ProjectSettings from './components/ProjectSettings';
import ProjectPopup from './components/ProjectPopup';
import PhotosTaken from './components/ProjectPhotosTaken';
import NotFound from '../errors/NotFound';
import { useParams } from 'react-router-dom';
import { history } from '../../index';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { EntityStatus } from '../../app/common/options/enums';
import ProjectWebsite from './components/ProjectWebsite';
import ProjectValidationModal, {
  ValidationIssues,
} from './components/ProjectValidationModal';

function ViewProject() {
  const { projectStore, templatesStore, userStore, navigationStore } =
    useStore();

  const { id } = useParams<{ id: string }>();

  const { submittingWebsite, submittingDataCollection } = templatesStore;

  const {
    selectedProject,
    loading,
    submitting,

    fetchProject,
    setSelectedProject,
    updateProject,
    setReadonlySettings,
  } = projectStore;

  const { user } = userStore;

  useEffect(() => {
    if (!id) {
      history.goBack();
      return;
    }

    fetchProject(id);
    setReadonlySettings(true);
  }, [fetchProject, id]);
  const [activeTab, setActiveTab] = useState('1');

  function updateProjectStatus() {
    if (!selectedProject) return;

    if (selectedProject.status === EntityStatus.Active) {
      deactivate();
    } else {
      activate();
    }
  }

  function deactivate() {
    if (!selectedProject) return;

    updateProject(selectedProject.id, {
      name: selectedProject.name,
      description: selectedProject.description,
      projectStatus: EntityStatus.DeActivated,
    });
    setSelectedProject({
      ...selectedProject,
      status: EntityStatus.DeActivated,
    });
  }

  function activate() {
    if (!selectedProject) return;

    const validation: ValidationIssues[] = [];

    const settings: ValidationIssues = {
      title: 'Settings',
      issues: [],
    };
    if (!selectedProject.name || selectedProject.name.trim() === '')
      settings.issues.push('Project name is required');
    if (
      !selectedProject.description ||
      selectedProject.description.trim() === ''
    )
      settings.issues.push('Project description is required');
    if (settings.issues.length > 0) validation.push(settings);

    const templates: ValidationIssues = {
      title: 'Templates',
      issues: [],
    };
    if (selectedProject.templateGroupTemplates.length < 1)
      templates.issues.push('Templates required for project');
    let hasMainWebsite = false;
    let hasMainPopup = false;
    selectedProject.templateGroupTemplates.forEach((t) => {
      if (!hasMainPopup && t.isTemplateGroupMainPopUpId) {
        hasMainPopup = true;
      }
      if (!hasMainWebsite && t.isTemplateGroupMainWebsiteId) {
        hasMainWebsite = true;
      }
    });
    if (!hasMainWebsite)
      templates.issues.push('A main website is required for project');
    if (templates.issues.length > 0) validation.push(templates);

    const members: ValidationIssues = {
      title: 'Members',
      issues: [],
    };
    if (selectedProject.templateGroupAppUsers.length < 1)
      members.issues.push('No members have been added to the project');
    if (members.issues.length > 0) validation.push(members);

    if (validation.length > 0) {
      Modal.info({
        content: (
          <ProjectValidationModal
            issues={validation}
            message="Please ensure that you have setup the below items before activating:"
          />
        ),
        className: 'project-validation-modal',
      });
    } else {
      activateProject();
    }
  }

  async function activateProject() {
    updateProject(selectedProject!.id, {
      name: selectedProject!.name,
      description: selectedProject!.description,
      projectStatus: EntityStatus.Active,
    });
    setSelectedProject({
      ...selectedProject!,
      status: EntityStatus.Active,
    });
  }

  const { Header, Content } = Layout;
  const { TabPane } = Tabs;

  if (loading) return <LoadingComponent content="Loading project..." />;

  if (!selectedProject) return <NotFound />;

  return (
    <>
      <Layout className="view-project">
        <Header className="view-project-header">
          <div>
            <h2>{selectedProject.name}</h2>
          </div>
        </Header>

        <Content>
          <Tabs
            activeKey={activeTab}
            onChange={(e) => setActiveTab(e)}
            defaultActiveKey="1"
            className="view-project-tabs"
            tabBarExtraContent={
              <Button
                loading={
                  submitting || submittingDataCollection || submittingWebsite
                }
                onClick={updateProjectStatus}
                style={{ marginRight: '30px', marginBottom: '20px' }}
                type="primary"
                danger={selectedProject.status === EntityStatus.Active}>
                {selectedProject.status !== EntityStatus.Active
                  ? 'Activate'
                  : 'Deactivate'}{' '}
                Project
              </Button>
            }>
            <TabPane key="1" tab="Summary">
              <ProjectSummary changeTab={(e: string) => setActiveTab(e)} />
            </TabPane>

            <TabPane key="2" tab="Templates">
              <ProjectTemplates />
            </TabPane>

            <TabPane key="3" tab="Website">
              <ProjectWebsite changeTab={(e: string) => setActiveTab(e)} />
            </TabPane>

            {(user ?? { subscriptionPackageId: 1 }).subscriptionPackageId !==
              1 && (
              <>
                <TabPane key="4" tab="Pop-up">
                  <ProjectPopup changeTab={(e: string) => setActiveTab(e)} />
                </TabPane>
              </>
            )}

            <TabPane key="5" tab="Photos">
              <PhotosTaken />
            </TabPane>

            <TabPane key="6" tab="Settings">
              <ProjectSettings />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </>
  );
}

export default observer(ViewProject);
