import React, { useState } from 'react';
import EmptyList from '../../../app/layout/EmptyList';
import { useStore } from '../../../app/stores/store';
import AddProjectForm from './AddProjectForm';
import { Dropdown, Menu, Space, Spin, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { ProjectTemplates } from '../../../app/models/projects/projectTemplates';
import { ProjectDTO } from '../../../app/models/projects/projectDto';
import { EntityStatus } from '../../../app/common/options/enums';
import { toast } from 'react-toastify';
import DeleteConfirmationModal from '../../../app/common/modals/DeleteConfirmationModal';
import { navigate } from '../../../app/common/util/common';

function ProjectsList() {
  const { modalStore, projectStore, userStore } = useStore();

  const { user } = userStore;

  const { projectsArray, deleting, archiveProject, getProject, clearLast } =
    projectStore;

  const { openModal, closeModal } = modalStore;

  const [deletingId, setDeletingId] = useState(-1);

  function addProject() {
    if (!user) return;
    if (user.subscriptionPackageId === 1 && projectsArray.length >= 1) {
      toast.error(
        'You have reached the maximum number of projects for the Starter Package. Upgrade package to be able to create more!'
      );
      return;
    }

    clearLast();
    openModal(true, <AddProjectForm />, 'Create New Project', true);
  }

  function deleteProjectAPI(id: number) {
    archiveProject(id);
    closeModal();
  }

  async function deleteProject(id: number) {
    setDeletingId(id);

    const project = getProject(id);
    let deleteMessage = 'Are you sure you want to delete this project?';

    if (project && project.status === EntityStatus.Active) {
      deleteMessage =
        'This is an active project, are you sure you want to delete it?';
    }

    openModal(
      true,
      <DeleteConfirmationModal
        cancel={closeModal}
        confirm={() => deleteProjectAPI(id)}
        message={<p>{deleteMessage}</p>}
      />,
      'Deleting Project',
      true,
      'delete-confirmation'
    );
  }

  function toProject(id: number) {
    clearLast();
    navigate(`/projects/${id}`);
  }

  const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

  const columns = [
    {
      title: 'Project name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: any) => (
        <div
          className="project-member-name"
          onClick={() => toProject(record.id)}>
          {name}
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'project_description',
      ellipsis: true,
      width: '30vw',
    },
    {
      title: 'Templates',
      dataIndex: 'templateGroupTemplates',
      key: 'templates',
      render: (templateGroupTemplates: ProjectTemplates[]) => (
        <span>{templateGroupTemplates.length}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => (
        <Space>
          <div className={`little-circle active`} />
          {status === EntityStatus.DeActivated
            ? 'Deactivated'
            : EntityStatus[status]}
        </Space>
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      width: '50px',
      render: (id: number, record: ProjectDTO) => (
        <>
          {deleting && deletingId === id ? (
            <Spin indicator={loadingIcon} />
          ) : (
            <div onClick={(e) => e.stopPropagation()}>
              <Dropdown
                disabled={deleting}
                overlay={
                  <Menu>
                    <Menu.Item key="0" onClick={() => deleteProject(id)}>
                      Delete
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                placement="bottomRight"
                overlayStyle={{ width: '150px' }}>
                <MoreOutlined
                  style={{
                    fontSize: '20px',
                    cursor: 'pointer',
                    opacity: `${deleting ? '40%' : '100%'}`,
                  }}
                />
              </Dropdown>
            </div>
          )}
        </>
      ),
    },
  ];

  const onRow = (record: ProjectDTO) => {
    return {
      onClick: (e: any) => {
        toProject(record.id);
      },
    };
  };

  return (
    <>
      {projectsArray && projectsArray.length > 0 ? (
        <Table
          onRow={onRow}
          rowKey={'id'}
          columns={columns}
          dataSource={projectsArray}
        />
      ) : (
        <EmptyList
          message="You currently have no projects. Click the button below to create a new one."
          buttonText="Add Project"
          buttonAction={addProject}
        />
      )}
    </>
  );
}

export default observer(ProjectsList);
