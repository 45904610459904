import { Checkbox, Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../app/stores/store';
import { TemplateCurrencyDTO } from '../../../app/models/templates/templateCurrencyDTO';
import { PaymentGateway } from '../../../app/models/paymentGateway';
import { PaymentProviders } from '../../../app/common/options/selectOptions';
import NoCommerce from './NoCommerce';

const { Option } = Select;

function TemplateCommerce() {
  const { templatesStore, currencyStore, salesStore } = useStore();

  const {
    selectedTemplate,

    setSelectedTemplate,
    setDisableSubmitCommerce,
  } = templatesStore;

  const { getAllowedCurrencies } = currencyStore;

  const { paymentGateways } = salesStore;

  const [defaultCurrency, setDefaultCurrency] = useState<{
    id: number;
    single: number;
    all: number;
  }>({ id: -1, single: 0, all: 0 });
  useEffect(() => {
    getAllowedCurrencies.forEach((c: TemplateCurrencyDTO) => {
      if (c.isDefault) {
        setDefaultCurrency({
          id: c.id,
          single: c.singleImagePrice,
          all: c.imageSetPrice,
        });
      }
    });
  }, [getAllowedCurrencies]);

  const [form] = Form.useForm();
  function setSellImages() {
    const curValue = form.getFieldValue('sellImages');
    if (!curValue) {
      form.setFieldsValue({ sellImages: true });

      if (!selectedTemplate!.subscriptionCurrencyId) {
        form.setFieldsValue({
          all_cost: defaultCurrency.all,
          single_cost: defaultCurrency.single,
        });

        if (defaultCurrency.id > -1) {
          form.setFieldsValue({ currency: defaultCurrency.id });
        }
      }
    } else {
      form.setFieldsValue({ sellImages: false });
    }
    formLeft();
  }

  function formLeft() {
    if (!selectedTemplate) return;
    const paymentGatewayId =
      form.getFieldValue('sellImages') === false
        ? undefined
        : +form.getFieldValue('payment_gateway');
    const subscriptionCurrencyId =
      form.getFieldValue('sellImages') === false
        ? undefined
        : +form.getFieldValue('currency');

    setSelectedTemplate({
      ...selectedTemplate,
      sellImages: form.getFieldValue('sellImages') ?? false,
      singleImagePrice: +form.getFieldValue('single_cost') ?? undefined,
      imageSetPrice: +form.getFieldValue('all_cost') ?? undefined,
      subscriptionCurrencyId,
      paymentGatewayId,
    });

    setDisableSubmitCommerce(
      form.getFieldValue('sellImages') &&
        (!form.getFieldValue('single_cost') ||
          +form.getFieldValue('single_cost') < 1 ||
          !form.getFieldValue('all_cost') ||
          +form.getFieldValue('all_cost') < 1 ||
          !form.getFieldValue('currency') ||
          !form.getFieldValue('payment_gateway'))
    );
  }
  function formChange(e: any) {
    if (
      Object.keys(e).includes('sellImages') &&
      e.sellImages &&
      !selectedTemplate!.subscriptionCurrencyId
    ) {
      form.setFieldsValue({
        all_cost: defaultCurrency.all,
        single_cost: defaultCurrency.single,
      });

      if (defaultCurrency.id > -1) {
        form.setFieldsValue({ currency: defaultCurrency.id });
      }
    }

    if (Object.keys(e).includes('currency')) {
      const currency = getAllowedCurrencies.find((c) => c.id === e.currency);
      if (!currency) {
        return;
      }

      form.setFieldsValue({
        all_cost: currency.imageSetPrice,
        single_cost: currency.singleImagePrice,
      });
    }
    formLeft();
  }

  if (getAllowedCurrencies.length === 0 || paymentGateways.length === 0) {
    return (
      <NoCommerce
        currency={getAllowedCurrencies.length === 0}
        gateway={paymentGateways.length === 0}
      />
    );
  }

  return (
    <>
      <Form
        className="template-commerce"
        name="addTemplate"
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        initialValues={{
          sellImages: selectedTemplate!.sellImages,
          all_cost: (selectedTemplate ?? { imageSetPrice: defaultCurrency.all })
            .imageSetPrice,
          single_cost: (
            selectedTemplate ?? { singleImagePrice: defaultCurrency.single }
          ).singleImagePrice,
          payment_gateway: (selectedTemplate ?? { paymentGatewayId: -1 })
            .paymentGatewayId,
          // currency: (selectedTemplate ?? {subscriptionCurrencyId: -1}).subscriptionCurrencyId,
          currency: selectedTemplate!.subscriptionCurrencyId,
        }}
        form={form}
        onValuesChange={formChange}
        onBlur={formLeft}>
        <Form.Item>
          <Form.Item valuePropName="checked" noStyle name="sellImages">
            <Checkbox />
          </Form.Item>
          <label
            onClick={setSellImages}
            style={{ marginLeft: '10px', cursor: 'pointer' }}>
            Sell images for this template
          </label>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.sellImages !== currentValues.sellImages
          }>
          {({ getFieldValue }) =>
            getFieldValue('sellImages') === true ? (
              <>
                <div className="template-commerce-subheading">
                  Select a payment gateway
                </div>
                <Form.Item name="payment_gateway" rules={[{ required: true }]}>
                  <Select>
                    {paymentGateways.map((c: PaymentGateway) => (
                      <Option value={c.id} key={c.id}>
                        {
                          PaymentProviders.find(
                            (p) => p.value === c.gatewayName
                          )!.display
                        }
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <div className="template-commerce-subheading">
                  Select a currency
                </div>
                <Form.Item name="currency" rules={[{ required: true }]}>
                  <Select>
                    {getAllowedCurrencies.map((c: TemplateCurrencyDTO) => (
                      <Option value={c.id} key={c.id}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item className="cost-field">
                  <label>Single Image Cost: </label>
                  <Form.Item noStyle name="single_cost">
                    <input placeholder="0" style={{ width: '70px' }} />
                  </Form.Item>
                </Form.Item>

                <Form.Item className="cost-field">
                  <label>All Images Cost: </label>
                  <Form.Item noStyle name="all_cost">
                    <input placeholder="0" style={{ width: '70px' }} />
                  </Form.Item>
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>
      </Form>
    </>
  );
}

export default observer(TemplateCommerce);
