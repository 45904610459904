import React, { useState } from 'react';
import { Button, Card, Space } from 'antd';
import { Link } from 'react-router-dom';
import { Template } from '../../../app/models/templates/template';
import { useStore } from '../../../app/stores/store';
import DeleteConfirmationModal from '../../../app/common/modals/DeleteConfirmationModal';
import { observer } from 'mobx-react-lite';
import { navigate } from '../../../app/common/util/common';

interface Props {
  template: Template;
  archived?: boolean;
}

function TemplateCard(props: Props) {
  const { template, archived = false } = props;

  const { templatesStore, modalStore, navigationStore } = useStore();
  const {
    deleteTemplate,
    restoreTemplate,
    archiveTemplate,
    restoring,
    archiving,
  } = templatesStore;
  const { closeModal, openModal } = modalStore;

  const [interactingId, setInteractingId] = useState<number>(-1);
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);

  function goToTemplate() {
    if (archived) {
      restore();
      return;
    }

    navigate(`/templates/${template.id}`);
  }

  function archive() {
    setInteractingId(template.id);
    closeModal();
    archiveTemplate(template.id);
  }

  function confirmDelete() {
    let modalBody = (
      <DeleteConfirmationModal
        confirmText="Archive"
        cancel={closeModal}
        confirm={archive}
        message={<p>Are you sure you want to archive this template?</p>}
      />
    );

    if (archived) {
      modalBody = (
        <DeleteConfirmationModal
          cancel={closeModal}
          confirm={remove}
          message={
            <p>Are you sure you want to permanently delete this template?</p>
          }
        />
      );
    }

    openModal(
      true,
      modalBody,
      'Deleting Template',
      true,
      'delete-confirmation'
    );
  }

  function remove() {
    closeModal();
    deleteTemplate(template.id);
  }

  async function restore() {
    setInteractingId(template.id);
    await restoreTemplate(template.id);

    setInteractingId(-1);
  }

  return (
    <>
      <Card
        onClick={archived ? () => {} : goToTemplate}
        className="templates-list-card">
        <div className="list-card-banner">
          <img
            src={template.sampleImageUrl}
            alt="card-header"
            onLoad={() => setShowPlaceholder(false)}
          />
          <img
            className="template-card-placeholder"
            style={{ display: showPlaceholder ? 'block' : 'none' }}
            src={'/assets/images/landscape-photo.jpg'}
            alt="template card placeholder"
          />
        </div>

        <Space size={0} direction="vertical" className="list-card-space">
          <Link to={`${navigationStore.baseUrl}/templates/${template.id}`}>
            <p>{template.name}</p>
          </Link>
          <p>{template.description}</p>
        </Space>

        <div className="list-card-buttons">
          <Button
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              goToTemplate();
            }}
            loading={interactingId === template.id && (restoring || archiving)}>
            {archived ? 'Restore' : 'Edit'}
          </Button>
          <Button
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              confirmDelete();
            }}
            loading={interactingId === template.id && (restoring || archiving)}>
            {archived ? 'Delete' : 'Archive'}
          </Button>
        </div>
      </Card>
    </>
  );
}

export default observer(TemplateCard);
