import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from '../../../app/stores/store';
import { QRCodesOutputFormat } from '../../../app/common/options/selectOptions';
import { observer } from 'mobx-react-lite';
import NoCodes from './noCodes';
import { MissingCodes } from '../../../app/models/qrcodes/missingCodes';
import { toast } from 'react-toastify';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getActiveSubscriptionId } from '../../../app/common/util/common';
import { formatNumberToBeReadable } from 'src/utils/format';

interface Props {
  changeTab: (e: string) => void;
}

function QrCodesBundles(props: Props) {
  const { qrCodeStore, userStore } = useStore();
  const { user } = userStore;
  const { generatingBundle, qrCodeSummary, requestCodes, fetchQrCodeSummary } =
    qrCodeStore;
  const { changeTab } = props;

  const [purchase, setPurchase] = useState(false);
  const [requiredCodes, setRequiredCodes] = useState({
    value: 0,
    prices: [] as { currency: string; price: number }[],
    request: 0,
  });
  const activeSubscriptionId = getActiveSubscriptionId();

  const onStorageUpdate = (e: any) => {
    const { key, newValue } = e;
    if (key === 'payment_made') {
      switch (newValue) {
        case 'success':
          toast.success(
            'Payment was successful, your QR Codes should now be downloaded.'
          );
          setPurchase(false);
          form.submit();
          break;

        case 'failure':
          toast.error('Payment was a failure, please try again later.');
          setPurchase(false);
          break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);

  const [form] = Form.useForm();
  async function onFinish(e: any) {
    if (!user || !activeSubscriptionId) return;

    const result: MissingCodes = await requestCodes(
      e.total.toString(),
      e.output_type,
      e.description,
      activeSubscriptionId.toString()
    );
    localStorage.removeItem('payment_made');

    if (result) {
      setRequiredCodes({
        value: result.total,
        prices: result.prices,
        request: e.total,
      });
      localStorage.setItem(
        'CODE_REQUEST',
        `total=${e.total};output=${e.output_type};description=${
          e.description
        };subId=${activeSubscriptionId.toString()}`
      );
      setPurchase(true);
    } else {
      fetchQrCodeSummary({
        subscriptionId: activeSubscriptionId.toString(),
      });
      changeTab('1');
      form.resetFields();
    }
  }

  const { Option } = Select;
  return (
    <>
      <div className="qr-bundles-header">
        <h3 className={'tab-header'}>
          QR Code Bundles:{' '}
          {formatNumberToBeReadable(qrCodeSummary?.totalQrToGenerate || 0)}{' '}
          remaining
        </h3>
      </div>
      <div className="qr-bundles">
        <Row wrap={true}>
          <Col span={12}>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              initialValues={{
                remember: true,
                output_type: QRCodesOutputFormat[0].value,
              }}
              onFinish={onFinish}
              autoComplete="off"
              className="generate-codes-form">
              <Form.Item
                rules={[
                  { required: true, message: 'Number of QR Codes required.' },
                  () => ({
                    validator(_, value) {
                      if (value > 0 && value % 1 === 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Please enter a positive, whole number!')
                      );
                    },
                  }),
                ]}
                label="Total:"
                name="total">
                <Input type="number" />
              </Form.Item>

              <Form.Item
                label="Description:"
                name="description"
                rules={[
                  { required: true, message: 'Short description required.' },
                ]}>
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                name="output_type"
                label="Output Type:"
                rules={[
                  {
                    required: true,
                    message: 'Output type of QR Codes required.',
                  },
                ]}>
                <Select allowClear>
                  {QRCodesOutputFormat.map((value) => (
                    <Option value={value.value} key={value.value}>
                      {value.display}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item wrapperCol={{ span: 16 }}>
                <Button
                  loading={generatingBundle}
                  type="primary"
                  htmlType="submit"
                  disabled={purchase}>
                  Create
                </Button>
                {generatingBundle && (
                  <div className="generate-code-message">
                    <InfoCircleOutlined />
                    <p>
                      Generating QR Codes may take some time. Please stay on
                      this page until generation is complete.
                    </p>
                  </div>
                )}
              </Form.Item>
            </Form>
          </Col>

          {purchase && (
            <Col span={12}>
              <NoCodes
                value={requiredCodes}
                cancel={() => setPurchase(false)}
              />
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}

export default observer(QrCodesBundles);
