import axios, { AxiosResponse } from 'axios';
import MD5 from 'crypto-js/md5';

export const PAYGATE_URLS = {
  Initiate: 'https://secure.paygate.co.za/payweb3/initiate.trans',
  Redirect: 'https://secure.paygate.co.za/payweb3/process.trans',
};

const startPayment = (amount: number, quantity: number, email: string) => {
  const dto = new FormData();
  dto.append('PAYGATE_ID', process.env.REACT_APP_PAYGATE_CLIENT_ID ?? '');
  dto.append('REFERENCE', 'payment_test3');
  dto.append('AMOUNT', (amount * 100).toString());
  dto.append('CURRENCY', 'USD');
  dto.append(
    'RETURN_URL',
    `${
      process.env.REACT_APP_PAYMENT_SUCCESS_REDIRECT
    }?gateway=paygate&amount=${amount}&${
      quantity == null ? '' : `count=${quantity}`
    }`
  );
  dto.append('TRANSACTION_DATE', '2018-01-01 12:00:00');
  dto.append('LOCALE', 'en-za');
  dto.append('COUNTRY', 'ZAF');
  dto.append('EMAIL', email);

  const valueString = Array.from(dto.values()).join('');
  const checksum = MD5(
    `${valueString}${process.env.REACT_APP_PAYGATE_CLIENT_SECRET ?? ''}`
  ).toString();

  dto.append('CHECKSUM', checksum);

  return axios
    .post(PAYGATE_URLS.Initiate, dto, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const PaygateService = {
  startPayment,
};

export default PaygateService;
