import React from 'react';
import { Button, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import EmptyList from '../../../app/layout/EmptyList';

interface Props {
  changeTab: (e: string) => void;
}

function QrCodesHistory(props: Props) {
  const { changeTab } = props;

  const { qrCodeStore, userStore } = useStore();
  const { user } = userStore;
  const { qrCodeHistory, loadingHistory } = qrCodeStore;

  const columns = [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'name',
      render: (name: string) => <div className="live-table-name">{name}</div>,
    },
    {
      title: 'Purchaser Name',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Date Purchased',
      dataIndex: 'datePurchased',
      key: 'datePurchased',
      render: (datePurchased: Date) => (
        <div>
          {new Date(datePurchased).toLocaleDateString('en-ZA', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </div>
      ),
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      key: 'totalCost',
    },
    {
      title: 'Price (per QR code)',
      dataIndex: 'price_per_image',
      key: 'price_per_image',
    },
  ];

  if (loadingHistory)
    return <LoadingComponent content="Loading QR Code history..." />;

  return (
    <>
      <div className="purchase-history-header">
        <h3 className={'tab-header'}>Purchase History</h3>

        {user!.subscriptionPackageId !== 1 ?? (
          <Button
            type={'primary'}
            className="qr-codes-button"
            onClick={() => changeTab('2')}>
            Purchase More QR Codes
          </Button>
        )}
      </div>

      {qrCodeHistory.length === 0 ? (
        <EmptyList
          customImage="/assets/images/no-transactions.svg"
          message="There is no bundle history to display"
          hideButton={true}
          buttonAction={() => {}}
          buttonText=""
        />
      ) : (
        <div className="purchase-history">
          <Table
            className="live-name"
            columns={columns}
            dataSource={qrCodeHistory}
            rowKey={'albumCodeRequestId'}
          />
        </div>
      )}
    </>
  );
}

export default observer(QrCodesHistory);
