import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../app/stores/store';
import { TemplateImageSetFull } from '../../../app/models/templates/templateImageSetFull';
import { Button, Tooltip } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';

interface Props {
  variation: TemplateImageSetFull;
}

function TemplateVariationsListItem(props: Props) {
  const { variation } = props;

  const { templatesStore, modalStore } = useStore();
  const {
    selectedImageSet,
    setSelectedImageSet,
    imageSets,
    deleteImageSet,
    updateImageSetName,
  } = templatesStore;

  const { openModal, closeModal } = modalStore;

  function variationSelected() {
    setSelectedImageSet(variation);
  }

  const body = (
    <>
      <div className="variation-confirmation">
        <div className="variation-confirmation-message">
          <h3>Are you sure you want to delete this background?</h3>

          <div className="variation-confirmation-delete">
            <h3>IMPORTANT NOTE</h3>
            <div>
              Photos already rendered against this image set will remain.
            </div>
          </div>
        </div>

        <div className="variation-confirmation-footer">
          <Button className="yellow-ant-btn" onClick={modalStore.closeModal}>
            Cancel
          </Button>

          <Button type="primary" onClick={deleteVariation}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );

  function confirmDelete() {
    openModal(true, body, 'Confirmation Required', true);
  }

  function deleteVariation() {
    deleteImageSet(variation.id);
    closeModal();
  }
  const [name, setName] = useState<string>('');
  const [itemState, setItemState] = useState<'view' | 'edit' | 'save'>('view');
  function setState(type: 'view' | 'edit' | 'save') {
    setItemState(type);

    switch (type) {
      case 'view':
        if (name !== variation.name) {
          updateImageSetName(variation.id, name);
        }
        break;
    }
  }

  useEffect(() => {
    setName(variation.name);
  }, [variation]);

  return (
    <>
      <div
        onClick={variationSelected}
        className={`variation-menu-item ${
          (selectedImageSet ?? { id: -1 }).id === variation.id &&
          'variation-menu-item-active'
        }`}>
        {itemState === 'view' ? (
          <>
            <p>
              {variation.name.trim() !== ''
                ? variation.name
                : `Background ${variation.orderIndex + 1}`}
            </p>
            <Tooltip title="Edit name">
              <EditOutlined
                className="variation-menu-item-edit"
                onClick={() => setState('edit')}
              />
            </Tooltip>
          </>
        ) : (
          <>
            <input
              autoFocus
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <Tooltip title="Confirm">
              <CheckOutlined
                className="variation-menu-item-edit"
                onClick={() => setState('view')}
              />
            </Tooltip>
          </>
        )}
        {imageSets.length > 1 && (
          <Tooltip title="Delete background">
            <img
              onClick={(e) => {
                e.stopPropagation();
                confirmDelete();
              }}
              src="/assets/images/x_red.svg"
              alt="red_x"
            />
          </Tooltip>
        )}
      </div>
    </>
  );
}

export default observer(TemplateVariationsListItem);
