import React, { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import { useStore } from '../../../app/stores/store';
import TemplatePopup from '../../templates/components/TemplatePopup/TemplatePopup';
import { observer } from 'mobx-react-lite';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import EmptyList from '../../../app/layout/EmptyList';
import { ProjectTemplates } from '../../../app/models/projects/projectTemplates';
import templatePopup from '../../templates/components/TemplatePopup/TemplatePopup';

interface Props {
  changeTab: (e: string) => void;
}

function ProjectPopup(props: Props) {
  const { templatesStore, projectStore } = useStore();
  const {
    selectedDataCollection,
    submittingDataCollection,

    updateDataCollection,
    setDataCollection,
    setDisablePopupSave,
  } = templatesStore;
  const { selectedProject, projectTemplates, changeMainValue } = projectStore;

  const [mainCollection, setMainCollection] = useState<number>(-1);

  useEffect(() => {
    if (!selectedProject) return;

    let id = -1;
    selectedProject.templateGroupTemplates.forEach((tg: ProjectTemplates) => {
      if (tg.isTemplateGroupMainPopUpId) id = tg.templateId;
    });

    setMainCollection(id);

    if (id > -1) {
      const popup = projectTemplates.find(
        (t) => t.id === id
      )?.templateDataCollection;
      setDataCollection(popup ?? null);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (templatePopup === null && mainCollection > -1) {
      setMainCollection(-1);
    }
  }, [templatePopup]);

  function setMain(id: number) {
    const template = projectTemplates.find((t) => t.id === id);
    if (!template) return;
    if (!selectedProject) return;
    setMainCollection(id);
    updateDataCollection(
      {
        ...template.templateDataCollection,
        isMain: true,
      },
      selectedProject.id.toString()
    );
    changeMainValue(template.id, 'popup', true);
    setDataCollection({
      ...template.templateDataCollection,
      isMain: true,
    });
    setDisablePopupSave();
  }

  return (
    <>
      {mainCollection === -1 || !selectedDataCollection ? (
        <>
          <h3 className="tab-header">Project Popup</h3>
          {projectTemplates.length > 0 ? (
            <>
              <Card>
                <div className="select-template">
                  <h3>Choose main popup for this project.</h3>

                  <p>
                    Please select from the list below to set the main popup for
                    the project:
                  </p>
                  {submittingDataCollection ? (
                    <>
                      <LoadingComponent />
                    </>
                  ) : (
                    <>
                      <div className="select-template-list">
                        {projectTemplates.map((t) => (
                          <div
                            className="select-template-list-item"
                            key={t.id}
                            onClick={() => setMain(t.id)}>
                            <h3>{t.name}</h3>
                            <div className="subtitle">
                              {t.templateDataCollection?.headerText}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </Card>
            </>
          ) : (
            <>
              <EmptyList
                message="You have no templates added to be able to select a main popup. Please add a template before continuing."
                buttonText="Add template"
                buttonAction={() => props.changeTab('2')}
              />
            </>
          )}
        </>
      ) : (
        <TemplatePopup />
      )}
    </>
  );
}

export default observer(ProjectPopup);
