import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from 'antd';

interface Props {
  message: ReactNode;
  confirm: () => void;
  cancel: () => void;
  singleButton?: boolean;
  confirmText?: string;
  cancelText?: string;
  title?: string;
}

function DeleteConfirmationModal(props: Props) {
  const {
    message,
    cancel,
    confirm,
    singleButton = false,
    confirmText = 'Delete',
    cancelText = 'Cancel',
    title = 'Woah, not so fast!',
  } = props;

  return (
    <>
      <div className="delete-confirmation-content">
        <div className="modal-hand">
          <img src="/assets/images/modal-hand.svg" alt="modal-hand" />
        </div>

        <div
          style={{
            marginTop: '15%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div className="delete-confirmation-content-message">
            <h3>{title}</h3>
            {message}
          </div>

          <div className="delete-confirmation-buttons">
            <Button className="yellow-ant-btn" onClick={cancel}>
              {singleButton ? 'Close' : cancelText}
            </Button>

            {!singleButton && (
              <Button type="primary" htmlType="submit" onClick={confirm}>
                {confirmText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(DeleteConfirmationModal);
