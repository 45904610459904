import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { SaleTransactionsCurrencyTotalDto } from '../../../app/models/sales/saleTransactionsCurrencyTotalDto';
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { formatCurrencyValue } from '../../../utils/format';

function SalesSummary() {
  const { salesStore } = useStore();
  const { transactions, loadingTransactions } = salesStore;

  const [totalCount, setTotalCount] = useState<number>(0);
  const [currencyTotals, setCurrencyTotals] = useState<
    SaleTransactionsCurrencyTotalDto[]
  >([]);
  useEffect(() => {
    if (!transactions) return;

    setCurrencyTotals(transactions.transactionSummaryData.currencyTotals);
    setTotalCount(transactions.transactionSummaryData.currencyTotals.length);
  }, [transactions]);

  if (loadingTransactions)
    return (
      <Card title="Sales Summary" className="sales-summary">
        <LoadingComponent content="Loading sales summary..." />
      </Card>
    );

  return (
    <>
      <Card title="Sales Summary" className="sales-summary">
        {!transactions ? (
          <p>No sales data available</p>
        ) : (
          <>
            <div className="amounts">
              {currencyTotals.map((total: SaleTransactionsCurrencyTotalDto) => (
                <CountUp
                  start={Math.round(total.total / 2)}
                  key={total.currencyCode}
                  useGrouping={true}
                  useEasing={true}
                  end={Math.floor(total.total)}
                  delay={0}
                  duration={5}
                  formattingFn={formatCurrencyValue}>
                  {({ countUpRef }) => (
                    <p
                      className={`amount-zar ${
                        totalCount > 3 ? `amount-zar-${totalCount}` : ''
                      }`}>
                      <span ref={countUpRef} />{' '}
                      <span>({total.currencyCode})</span>
                    </p>
                  )}
                </CountUp>
              ))}
            </div>
            <div className="sales-summary-footer">
              <span>
                <p>
                  Total Photos Taken:{' '}
                  {formatCurrencyValue(
                    transactions.transactionSummaryData.totalPhotoUploads
                  )}
                </p>
                <p>
                  Successful Sales:{' '}
                  {formatCurrencyValue(
                    transactions.transactionSummaryData.successfullSalesCount
                  )}
                </p>
              </span>
              <span>
                <p>
                  Customer Engagement:{' '}
                  {Math.round(
                    transactions.transactionSummaryData.customerEngagement
                  )}
                  %
                </p>
                <p>
                  Total Online Reach:{' '}
                  {transactions.transactionSummaryData.totalOnlineReach ?? 0}
                </p>
              </span>
            </div>
          </>
        )}
      </Card>
    </>
  );
}

export default observer(SalesSummary);
