import React from 'react';
import { Layout } from 'antd';
import SalesTransactions from './components/SalesTransactions';
import ReportFilters from './components/ReportFilters';
import SalesSummary from './components/SalesSummary';

function SalesSummaryReports() {
  return (
    <>
      <Layout className="sales-summary-reports">
        <div className="sales-summary-cards">
          <SalesSummary />
          <ReportFilters />
        </div>
      </Layout>

      <SalesTransactions />
    </>
  );
}

export default SalesSummaryReports;
