import { makeAutoObservable } from 'mobx';
import agent from '../api/Agent';
import { DashboardCodes } from '../models/dashboard/dashboardCodes';
import { TemplateShares } from '../models/dashboard/templateShares';
import { ActivePhotographersDto } from '../models/dashboard/activePhotographers.dto';
import { yyyymmdd } from '../common/util/date';
import { ProjectPhotos } from '../models/dashboard/projectPhotos';
import { ChartObject } from '../models/dashboard/chartObject';
import { BarChartData } from '../models/dashboard/barChartData';

export class DashboardStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading: boolean = false;
  loadingComponent: string[] = [];
  overallLoaded: boolean = false;

  qrCodes: DashboardCodes | null = null;
  templateShares: TemplateShares | null = null;
  activePhotographers: ActivePhotographersDto | null = null;
  projectPhotos: BarChartData[] = [];
  templatePhotos: BarChartData[] = [];

  setLoading = (value: boolean, component: string) => {
    this.loading = value;

    if (value) {
      this.loadingComponent.push(component);
    } else {
      const index = this.loadingComponent.findIndex((s) => s === component);
      if (index !== -1) {
        this.loadingComponent.splice(index, 1);
      }
    }
  };

  setOverallLoaded = (value: boolean) => {
    this.overallLoaded = value;
  };

  getOverall = async (subscriptionId: number, loaded: boolean) => {
    try {
      const today = yyyymmdd(new Date());

      this.getQrCodes(subscriptionId);
      this.getTemplateShares(subscriptionId, '20000101', today);
      this.getActivePhotographers(subscriptionId, '20000101', today);
      this.getActiveTemplates(subscriptionId, '20000101', today);
      this.getProjectPhotos(subscriptionId, '20000101', today);
    } catch (error) {
      throw error;
    } finally {
      this.setOverallLoaded(loaded);
    }
  };

  getQrCodes = async (id: number) => {
    try {
      this.setLoading(true, 'qr');
      this.setQrCodes(null);
      const result = await agent.Dashboard.qrSummary(id);
      this.setQrCodes(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false, 'qr');
    }
  };

  getTemplateShares = async (
    id: number,
    startDate: string,
    endDate: string
  ) => {
    try {
      this.setLoading(true, 'shares');

      this.setTemplateShares(null);
      const result = await agent.Dashboard.socialShares(id, startDate, endDate);
      this.setTemplateShares(result);
    } catch (error) {
      this.setTemplateShares(null);
      throw error;
    } finally {
      this.setLoading(false, 'shares');
    }
  };

  getActivePhotographers = async (
    id: number,
    startDate: string,
    endDate: string
  ) => {
    try {
      this.setLoading(true, 'photographers');
      this.setPhotographers(null);
      const result = await agent.Dashboard.activePhotographers(
        id,
        startDate,
        endDate
      );
      this.setPhotographers(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false, 'photographers');
    }
  };

  getProjectPhotos = async (id: number, startDate: string, endDate: string) => {
    try {
      this.setLoading(true, 'projects');
      this.setProjectPhotos([]);
      const activeProjects = await agent.Dashboard.activeProjects(
        id,
        startDate,
        endDate
      );
      this.setProjectPhotos(activeProjects);
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false, 'projects');
    }
  };

  getActiveTemplates = async (
    id: number,
    startDate: string,
    endDate: string
  ) => {
    try {
      this.setLoading(true, 'templates');
      this.templatePhotos = [];
      const result = await agent.Dashboard.activeTemplates(
        id,
        startDate,
        endDate
      );
      this.setActiveTemplates(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false, 'templates');
    }
  };

  get photographers() {
    if (!this.activePhotographers) return [];

    return this.activePhotographers.photographers;
  }

  setPhotographers = (value: ActivePhotographersDto | null) => {
    this.activePhotographers = value;
  };

  setQrCodes = (value: DashboardCodes | null) => {
    this.qrCodes = value;
  };

  setTemplateShares = (value: TemplateShares | null) => {
    this.templateShares = value;
  };

  setProjectPhotos = (value: ProjectPhotos[]) => {
    this.projectPhotos = value.map((p) => ({
      name: p.projectName,
      value: p.noOfPhotos,
      id: p.projectId,
    }));
  };

  setActiveTemplates = (value: ChartObject) => {
    this.templatePhotos = value.stats.data.map((value) => ({
      name: value.name,
      value: value.value,
      id: value.id,
    }));
  };

  get shareArray() {
    if (!this.templateShares) return [];
    return [
      this.templateShares.download,
      this.templateShares.whatsApp,
      this.templateShares.twitter,
      this.templateShares.facebook,
    ];
  }
}
