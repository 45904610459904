import CurrencySelectDropdown from 'src/features/account/components/CurrencySelectDropdown';

interface Props {
  prices: {
    currency: string;
    price: number;
  }[];

  selectedCurrency: {
    currency: string;
    price: number;
  };

  onChangeSelectedCurrency: (value: {
    currency: string;
    price: number;
  }) => void;
  total: number;
  request: number;
}

function NoCodesPremium(props: Props) {
  const { prices, total, request, selectedCurrency, onChangeSelectedCurrency } =
    props;

  return (
    <>
      <h3>Not Enough QR Codes</h3>

      <p>
        You do not have enough codes to generate this bundle.
        <br />
        You are missing <strong>{request - +total}</strong> codes to complete
        the request.
        <br />
        You can purchase the missing codes for{' '}
        <strong>
          {Number(selectedCurrency.price).toFixed(
            Math.max(
              selectedCurrency.price.toString().split('.')[1]?.length,
              2
            ) || 2
          )}{' '}
          {selectedCurrency.currency}
        </strong>{' '}
        by clicking the button below.
      </p>
      <CurrencySelectDropdown
        value={selectedCurrency.currency}
        onChange={(value) => {
          const currency = prices.find((p) => p.currency === value);
          if (currency !== undefined) onChangeSelectedCurrency(currency);
        }}
      />
    </>
  );
}

export default NoCodesPremium;
