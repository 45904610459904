import React from 'react';
import { Button, Form, Input, Layout } from 'antd';
import { useStore } from '../../../app/stores/store';
import { ProjectPutDTO } from '../../../app/models/projects/projectPutDTO';
import { observer } from 'mobx-react-lite';

function ProjectSettings() {
  const { projectStore } = useStore();

  const {
    selectedProject,
    submitting,

    updateProject,
    setSelectedProject,
  } = projectStore;
  const [form] = Form.useForm();
  async function save(e: any) {
    if (!selectedProject) return;

    const dto: ProjectPutDTO = {
      name: e.name,
      description: e.description,
      projectStatus: selectedProject.status,
    };
    updateProject(selectedProject.id, dto);
  }

  function formLeft(e: any) {
    if (!selectedProject) return;
    setSelectedProject({
      ...selectedProject,
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
    });
  }

  const { Header, Content } = Layout;
  const { TextArea } = Input;
  return (
    <>
      <Layout className="project-settings">
        <Header>
          <h3 className={'tab-header'}>Project Settings</h3>
          <Button type="primary" onClick={form.submit} loading={submitting}>
            Save
          </Button>
        </Header>
        <Content>
          <Form
            onBlur={formLeft}
            form={form}
            className="project-settings-form"
            labelAlign="left"
            labelCol={{ span: 3 }}
            onFinish={save}
            initialValues={{
              name: selectedProject?.name ?? '',
              description: selectedProject?.description ?? '',
            }}>
            <Form.Item
              label="Project Name:"
              name="name"
              rules={[
                { required: true, message: 'Please add a project name.' },
              ]}>
              <Input className="project-name" />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please have a project description.',
                },
              ]}>
              <TextArea className="project-description" rows={4} />
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </>
  );
}

export default observer(ProjectSettings);
