import { makeAutoObservable, runInAction } from 'mobx';
import { QrCodeSummaryDto } from '../models/qrcodes/qrCodeSummary.dto';
import agent, { IGetQuery } from '../api/Agent';
import { QrCodeHistoryItemDto } from '../models/qrcodes/qrCodeHistoryItem.dto';
import { AxiosError } from 'axios';

export class QrCodeStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingSummary: boolean = false;
  loadingHistory: boolean = false;
  downloadingCodes: boolean = false;

  qrCodeSummary: QrCodeSummaryDto | null = null;
  qrCodeHistory: QrCodeHistoryItemDto[] = [];

  setQrCodeSummary = (value: QrCodeSummaryDto | null) => {
    this.qrCodeSummary = value;
  };

  setLoadingSummary = (value: boolean) => {
    this.loadingSummary = value;
  };

  setDownloadingCodes = (value: boolean) => {
    this.downloadingCodes = value;
  };

  setLoadingHistory = (value: boolean) => {
    this.loadingHistory = value;
  };

  fetchQrCodeSummary = async (query: IGetQuery, showLoading = true) => {
    try {
      if (showLoading) this.setLoadingSummary(true);

      const result = await agent.QRCodes.summary(query);
      this.setQrCodeSummary(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingSummary(false);
    }
  };

  downloadQrCode = async (id: number) => {
    try {
      this.setDownloadingCodes(true);

      const result = await agent.QRCodes.download(id);

      runInAction(() => {
        if (!this.qrCodeSummary) return;

        const bundle = this.qrCodeSummary.albumCodesRequestInfo.find(
          (b) => b.albumCodeRequestId === id
        );
        if (!bundle) return;

        bundle.status = 'Downloaded';
      });

      return result;
    } catch (error) {
      throw error;
    } finally {
      this.setDownloadingCodes(false);
    }
  };

  fetchHistory = async (id: number, sysAdmin: boolean) => {
    try {
      this.setLoadingHistory(true);

      const result = sysAdmin
        ? await agent.QRCodes.history({})
        : await agent.QRCodes.history({ subscriptionId: id.toString() });
      this.setHistory(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingHistory(false);
    }
  };

  setHistory = (value: QrCodeHistoryItemDto[]) => {
    this.qrCodeHistory = value;
  };

  /**
   * Generate codes and handle sales calls
   */

  generatingBundle: boolean = false;

  setGeneratingBundle = (value: boolean) => {
    this.generatingBundle = value;
  };

  requestCodes = async (
    total: string,
    output: string,
    description: string,
    subscriptionId: string
  ) => {
    try {
      this.setGeneratingBundle(true);

      await agent.QRCodes.generate({
        total: total,
        subscriptionId: subscriptionId,
        description: description,
        outputType: output,
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      const { data } = axiosError.response!;
      if (data) {
        return data.value;
      }

      throw error;
    } finally {
      this.setGeneratingBundle(false);
    }
  };

  // private handleNoCodes = (value: MissingCodes) => {
  //   const split = value.split('|');
  //
  //   if (split[1] === 'starter') {
  //     return {
  //       value: '0',
  //       price: 'starter'
  //     }
  //   }
  //
  //   return {
  //     value: split.length === 3 ? split[2].split('-')[1] : '',
  //     price: split[1].split('-')[1]
  //   }
  // }
}
