import React, { useEffect } from 'react';
import { useStore } from 'src/app/stores/store';
import { toast } from 'react-toastify';
import TermsAndConditionsConfirmationModal from 'src/app/common/modals/TermsAndConditionsConfirmationModal';
import termsAndConditions from 'src/app/common/options/termsAndConditions';

type Props = {};

const TermsAndConditionsPopup = (props: Props) => {
  const { accountStore, userStore, modalStore } = useStore();
  const { user, getUser } = userStore;
  const { openModal, closeModal } = modalStore;

  async function confirmChange(version: string) {
    closeModal();
    await accountStore.acceptTermsAndConditions(version);
    window.location.reload();
  }

  useEffect(() => {
    if (!user) getUser();
  }, []);

  function showModal() {
    if (!user) return;
    if (
      new Date(termsAndConditions.versionDate) <=
      new Date(user.termsAcceptedDateTime?.split('T')[0])
    )
      return;

    openModal(
      true,
      <TermsAndConditionsConfirmationModal
        confirm={() => confirmChange(termsAndConditions.versionDate)}
        eulaLink={termsAndConditions.eulaLink}
        termsAndConditionsLink={termsAndConditions.termsAndConditionsLink}
        confirmText={termsAndConditions.acceptButtonText}
      />,
      termsAndConditions.title,
      true,
      'terms-and-conditions-confirmation',
      true
    );
  }

  return <div>{user && showModal()}</div>;
};

export default TermsAndConditionsPopup;
