import React from 'react';
import { Layout } from 'antd';
import { PACKAGES } from '../packages';

function RegisterPackages() {
  return (
    <>
      <Layout className="registration-packages">
        <div>
          <strong>Please note!</strong> <br />
          Your default subscription is the Pay As You Go package. In order to
          upgrade, login to Happy Snappy Lite, go to Account, select
          Subscription and follow the prompts.
        </div>

        <div className="packages-list">
          <div className="packages-list-chip" />

          <div className="packages-list-content-outer">
            <div className="pl-message">
              <h1>The pricing is simple...</h1>

              <h4>
                It's free to sign up, download and design your own templates.
              </h4>
            </div>

            <div className="packages-list">
              <div className="packages-list-content">
                {PACKAGES.map((p, i) => (
                  <div key={i} className="account-package-container">
                    <div
                      className={`account-list pl-package ${
                        i % 2 !== 0 ? 'orange' : ''
                      }`}>
                      <div className="pl-package-header">
                        <div className="pl-package-header-inner">
                          {p.subtitle}
                        </div>
                      </div>
                      <div className="pl-package-body">
                        <div className="pl-package-body-inner">
                          <h3 className="pl-package-body-tile">{p.title}</h3>
                          <p className="pl-package-body-description">
                            {p.description}
                          </p>
                        </div>
                        <ul className="pl-package-option-container">
                          {p.options.map((o, index) => (
                            <li
                              key={`${i}-${index}-option`}
                              className={`pl-package-option ${
                                index === 0 ? 'no-border' : ''
                              }`}>
                              {o}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default RegisterPackages;
