import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import EmptyList from '../../../app/layout/EmptyList';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { ProjectImage } from '../../../app/models/projects/projectImage';
import { TablePaginationConfig } from 'antd/es/table/Table';
import { yyyymmdd } from '../../../app/common/util/date';
import ProjectPhotosFilter, { FilterOptions } from './ProjectPhotosFilter';

function PhotosTaken() {
  const { projectStore, userStore } = useStore();
  const {
    selectedProject,
    projectPhotos,
    loadingPhotos,
    projectPhotosPagination,
    filteredPhotoCount,
    selectedProjectPhotos,
    setSelectedProjectPhotos,
    getPhotos,
  } = projectStore;
  const { user } = userStore;
  const [dataSource, setDataSource] = useState<ProjectImage[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
  });
  const [options, setOptions] = useState<FilterOptions>({
    qrCode: '',
    template: '',
    dateRange: {
      start: '20000101',
      end: yyyymmdd(),
    },
    unlinked: null,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    selectedProjectPhotos.map((x) => x.snaptImageId)
  );

  useEffect(() => {
    if (selectedProjectPhotos.length === 0 && selectedRowKeys.length > 0) {
      setSelectedRowKeys([]);
    }
  }, [selectedProjectPhotos]);

  useEffect(() => {
    if (!selectedProject || projectPhotos) return;

    getPhotos(selectedProject.id, '20000101', yyyymmdd());
  }, [selectedProject, getPhotos, projectPhotos]);

  useEffect(() => {
    if (!projectPhotos) return;

    setDataSource(projectPhotos.listofPhotos);
  }, [projectPhotos]);

  const columns = [
    {
      title: 'Image',
      dataIndex: 'imageUrl',
      key: 'imageTaken',
      render: (imageTaken: string, record: ProjectImage) => (
        <a href={record.imageUrl} target="_blank" rel="noreferrer">
          <img
            src={imageTaken}
            className="project-photo-img"
            alt={'snapt'}
            loading="lazy"
          />
        </a>
      ),
    },
    {
      title: 'Photographer',
      dataIndex: 'userName',
      render: (userName: string, record: ProjectImage) => (
        <div style={{ color: record.userDeleted ? '#6c757d' : 'black' }}>
          {userName} {record.userDeleted ? '(Deleted)' : ''}
        </div>
      ),
      sorter: (a: { userId: number }, b: { userId: number }) =>
        a.userId - b.userId,
    },
    {
      title: 'Date and Time',
      dataIndex: 'dateTime',
      render: (dateTime: string) => (
        <div>
          {new Date(dateTime + 'Z').toLocaleDateString('en-ZA', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}
        </div>
      ),
      sorter: (a: { dateTime: Date }, b: { dateTime: Date }) =>
        new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime(),
    },
    {
      title: 'Code',
      dataIndex: 'albumCode',
    },
  ];

  function tableChange(newPagination: TablePaginationConfig) {
    if (!newPagination) return;
    setPagination({
      ...pagination,
      current: newPagination.current,
    });
    if (
      newPagination.current !== projectPhotosPagination.currentPage ||
      newPagination.pageSize !== projectPhotosPagination.itemsPerPage
    ) {
      if (!selectedProject) return;
      getPhotos(
        selectedProject.id,
        options.dateRange.start,
        options.dateRange.end,
        newPagination.current,
        options.template,
        options.qrCode,
        options.unlinked,
        newPagination.pageSize ?? 10
      );
    }
  }

  function onFilter(e: FilterOptions) {
    setOptions(e);
    setPagination({
      ...pagination,
      current: 1,
    });
  }
  function onSelectChange(newSelectedRowKeys: React.Key[]) {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedProjectPhotos(
      newSelectedRowKeys.length > 0
        ? dataSource.filter((x) => newSelectedRowKeys.includes(x.snaptImageId))
        : []
    );
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: false,
  };

  return (
    <>
      <h3 className={'tab-header'}>Project Photos</h3>
      <ProjectPhotosFilter filterEvent={onFilter} />

      {dataSource.length < 1 && !loadingPhotos ? (
        <EmptyList
          message="No photos have been uploaded"
          hideButton={true}
          buttonAction={() => {}}
          buttonText=""
        />
      ) : (
        <Table
          pagination={{
            ...pagination,
            total: filteredPhotoCount,
            showSizeChanger: true,
            position: ['bottomRight', 'topRight'],
          }}
          rowSelection={rowSelection}
          onChange={tableChange}
          style={{ marginTop: '10px' }}
          loading={loadingPhotos}
          rowKey={'snaptImageId'}
          dataSource={dataSource}
          columns={columns}
        />
      )}
    </>
  );
}

export default observer(PhotosTaken);
