import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../app/stores/store';
import { AppUser } from '../../../app/models/appUser';
import { Button, Card, Table } from 'antd';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import EmptyList from '../../../app/layout/EmptyList';
import { history } from '../../..';
import { getActiveSubscriptionId } from '../../../app/common/util/common';

interface Props {
  addMembers: (e: boolean) => void;
  cancel: () => void;
}

function ProjectAddMembers(props: Props) {
  const { subscriptionsStore, userStore, projectStore } = useStore();
  const { user } = userStore;
  const { getUsers, subscriptionUsers, loading } = subscriptionsStore;
  const {
    selectedProject,
    addUsers,
    getUsers: getProjectUsers,
    submittingUsers,
    loadingUsers,
  } = projectStore;

  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [datasource, setDatasource] = useState<AppUser[]>([]);
  const activeSubscriptionId = getActiveSubscriptionId();

  useEffect(() => {
    if (!user || !activeSubscriptionId) return;

    getUsers(activeSubscriptionId);
  }, [getUsers, activeSubscriptionId]);

  useEffect(() => {
    setDatasource(
      subscriptionUsers
        .filter(
          (u) =>
            !selectedProject!.templateGroupAppUsers
              .map((tu) => tu.userId)
              .includes(u.id) && u.isPhotographer
        )
        .filter((u) => u.subscriptionId === selectedProject?.subscriptionId)
    );
  }, [subscriptionUsers]);

  async function addTeamMembers() {
    if (!selectedProject) return;

    await addUsers(selectedProject.id, selectedUsers);
    await getProjectUsers();
    props.addMembers(false);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'fullName',
      render: (name: string, record: AppUser) => (
        <div className="project-member-name">{name}</div>
      ),
    },
    {
      title: 'Roles',
      dataIndex: 'isAdministrator',
      key: 'userRoles',
      render: (isAdministrator: boolean, record: AppUser) => (
        <div>
          {isAdministrator ? 'Admin, ' : ''}{' '}
          {record.isManager ? 'Manager, ' : ''}{' '}
          {record.isPhotographer ? 'Photographer, ' : ''}
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tel Number',
      dataIndex: 'phone',
      key: 'phone',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedUsers(selectedRowKeys as number[]);
    },
  };

  function toTeam() {
    history.push('/team');
  }

  if (loading)
    return (
      <>
        <Card>
          <LoadingComponent content="Loading subscription users..." />
        </Card>
      </>
    );

  return (
    <>
      <Card>
        {datasource.length < 1 ? (
          <EmptyList
            message="You do not have any photograpers. Please create a photographer account or add the role to this account."
            buttonText="Create Member"
            buttonAction={toTeam}
            customImage="/assets/images/people-list.svg"
          />
        ) : (
          <>
            <Button
              type="text"
              style={{ float: 'right', marginLeft: '10px' }}
              onClick={props.cancel}>
              Cancel
            </Button>
            <Button
              disabled={selectedUsers.length < 1}
              loading={submittingUsers || loadingUsers}
              style={{ float: 'right', marginBottom: '20px' }}
              type="primary"
              onClick={addTeamMembers}>
              Add Members
            </Button>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={datasource}
              rowKey={'id'}
            />
          </>
        )}
      </Card>
    </>
  );
}

export default observer(ProjectAddMembers);
