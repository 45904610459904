import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Button, Card, Col, Input, Layout, Row, Space } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { useParams } from 'react-router-dom';
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { history } from '../../../index';
import { EntityStatus } from '../../../app/common/options/enums';
import { SaleTransactionsCurrencyTotalDto } from '../../../app/models/sales/saleTransactionsCurrencyTotalDto';

const { Content } = Layout;

function ViewSubscription() {
  const { subscriptionsStore } = useStore();
  const {
    subscriptionAnalytics,
    loadingAnalytics,
    selectedSubscription,
    loading,
    submitting,
    fetch,
    setSelectedSubscription,
    deactivate,
    fetchAnalytics,
  } = subscriptionsStore;

  const { subId: id } = useParams<{ subId: string }>();

  useEffect(() => {
    if (!id) {
      history.push('/not-found');
      return;
    }

    fetch(+id, {
      mode: 'info',
      includeUsers: 'false',
    });
    fetchAnalytics(+id);
  }, [fetch, id]);

  function back() {
    history.push('/account?tab=3');
  }

  function updateName(e: any) {
    if (!selectedSubscription) return;

    setSelectedSubscription({
      ...selectedSubscription,
      companyName: e.target.value,
    });
  }

  async function deactivateClick() {
    if (!selectedSubscription) return;

    await deactivate(
      selectedSubscription.id,
      selectedSubscription.status === 4
        ? EntityStatus.Deleted
        : EntityStatus.DeActivated
    );
    if (selectedSubscription.status === 4) {
      history.goBack();
      return;
    }
    await fetch(+id, {
      mode: 'info',
      includeUsers: 'false',
    });
    await fetchAnalytics(+id);
  }

  if (loading || loadingAnalytics)
    return <LoadingComponent content="Loading Subscription" />;
  if (!selectedSubscription) return <></>;

  return (
    <>
      <Layout className="view-subscription">
        <Header className="view-subscription-header">
          <div>
            <h2>
              {selectedSubscription
                ? selectedSubscription.companyName
                : 'Subscription Details'}
            </h2>

            <Button
              type="primary"
              onClick={() => {
                history.push(
                  `/subscription/${selectedSubscription.id}/dashboard`
                );
              }}>
              Open Client Dashboard
            </Button>
            <Button
              loading={submitting}
              danger
              type="primary"
              onClick={deactivateClick}>
              {selectedSubscription.status === 4 ? 'Delete' : 'Deactivate'}{' '}
              Account
            </Button>

            <Button
              loading={submitting}
              className="yellow-ant-btn"
              onClick={back}>
              Close
            </Button>
          </div>
        </Header>

        {loading ? (
          <LoadingComponent content="Loading Subscription Information..." />
        ) : (
          <>
            <Content className="view-subscription-content">
              <Card>
                <Row>
                  <Col span={10} className={'subscription-details'}>
                    <h3 className={'tab-header'}>Account Name</h3>

                    <Space direction="vertical" size={2}>
                      <label>Account Name:</label>
                      <Input
                        onChange={updateName}
                        value={
                          (selectedSubscription ?? { companyName: '' })
                            .companyName
                        }
                      />
                    </Space>
                  </Col>

                  <Col span={14}>
                    {subscriptionAnalytics && (
                      <>
                        <h3 className={'tab-header'}>Account Analytics</h3>

                        <div className="account-analytics">
                          <Row className="account-analytics-header">
                            <Col span={6}>No. of Projects</Col>
                            <Col span={6}>No. of Photos</Col>
                            <Col span={6}>No. of Shares</Col>
                            <Col span={6}>Revenue Generated</Col>
                          </Row>
                          <Row className="account-analytics-info">
                            <Col span={6}>
                              {subscriptionAnalytics.projectCount}
                            </Col>
                            <Col span={6}>
                              {subscriptionAnalytics.photoCount}
                            </Col>
                            <Col span={6}>
                              {subscriptionAnalytics.totalShares}
                            </Col>
                            <Col span={6}>
                              {subscriptionAnalytics.currencyTotals.length ===
                              0 ? (
                                'No sales'
                              ) : (
                                <>
                                  {subscriptionAnalytics.currencyTotals.map(
                                    (
                                      total: SaleTransactionsCurrencyTotalDto,
                                      index
                                    ) => (
                                      <p
                                        key={index}
                                        className="subscription-total-item">{`${total.currencySymbol}${total.total}  (${total.currencyCode})`}</p>
                                    )
                                  )}
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Card>
            </Content>
          </>
        )}
      </Layout>
    </>
  );
}

export default observer(ViewSubscription);
