import React from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { TemplateCreateDTO } from '../../../app/models/templates/templateCreateDTO';
import {
  getActiveSubscriptionId,
  navigate,
} from '../../../app/common/util/common';

interface Props {
  type: 'all' | 'green' | 'standard';
  from: string;
  project_id?: number;
}

function AddTemplateForm(props: Props) {
  const { type, from, project_id } = props;

  const { modalStore, templatesStore, userStore, projectStore } = useStore();
  const { user } = userStore;
  const { submitting, createTemplate } = templatesStore;
  const { addTemplates, setAddingTemplate } = projectStore;
  const activeSubscriptionId = getActiveSubscriptionId();

  async function onFinish(e: any) {
    if (!user || !activeSubscriptionId) {
      return;
    }

    const template: TemplateCreateDTO = {
      name: e.name,
      description: e.description,
      isGreenScreen: e.isGreenScreen,
      subscriptionId: activeSubscriptionId,
    };
    const template_id = await createTemplate(template);
    let url = `/templates/${template_id}`;
    if (from === 'projects' && project_id) {
      url += `?project=${project_id}`;
      const dto = {
        templateIds: [template_id],
      };
      await addTemplates(project_id.toString(), dto);
      setAddingTemplate(false);
    }

    modalStore.closeModal();
    navigate(url);
  }

  const [form] = Form.useForm();
  function setGreenScreen() {
    const curValue = form.getFieldValue('isGreenScreen');
    form.setFieldsValue({ isGreenScreen: !curValue });
  }

  return (
    <>
      <Form
        className="add-template-form"
        name="addTemplate"
        layout="vertical"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        initialValues={{ isGreenScreen: type === 'green' }}>
        <Form.Item
          label="Template name"
          name="name"
          rules={[{ required: true, message: 'Please add a template name' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: true, message: 'Please add a template description.' },
          ]}>
          <Input.TextArea />
        </Form.Item>

        {user!.subscriptionPackageId !== 1 && (
          <Form.Item>
            <Form.Item valuePropName="checked" noStyle name="isGreenScreen">
              <Checkbox />
            </Form.Item>
            <label
              onClick={setGreenScreen}
              style={{ marginLeft: '10px', cursor: 'pointer' }}>
              Remove Background
            </label>
          </Form.Item>
        )}

        <Form.Item className="add-template-form-buttons">
          <Button
            disabled={submitting}
            className="yellow-ant-btn"
            onClick={modalStore.closeModal}>
            Cancel
          </Button>

          <Button loading={submitting} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default observer(AddTemplateForm);
