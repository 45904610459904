import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { Template } from '../../../app/models/templates/template';
import { EntityStatus } from '../../../app/common/options/enums';
import { TemplateImageSetFull } from '../../../app/models/templates/templateImageSetFull';
import { Button, Card, Space, Table } from 'antd';
import EmptyList from '../../../app/layout/EmptyList';
import AddTemplateForm from '../../templates/components/AddTemplateForm';
import TableImagePreview from '../../../app/common/components/TableImagePreview';

interface Props {
  callBack: (keys: React.Key[]) => void;
  menuOption: (option: 'show_menu' | 'add_list') => void;
}

function ProjectTemplatesAdd(props: Props) {
  const { templatesStore, projectStore, modalStore } = useStore();
  const { loading, getTemplates } = templatesStore;
  const { openModal } = modalStore;
  const {
    selectedProject,

    setAddingTemplate,
  } = projectStore;

  const [addType, setAddType] = useState('modal');
  const [dataSource, setDataSource] = useState<Template[]>([]);

  useEffect(() => {
    setDataSource(
      getTemplates.filter(
        (t) =>
          !selectedProject!.templateGroupTemplates
            .map((tg) => tg.templateId)
            .includes(t.id)
      )
    );
  }, [setDataSource, getTemplates]);

  function addTemplate() {
    props.menuOption('show_menu');
    setAddType('modal');
    openModal(
      true,
      <AddTemplateForm
        from="projects"
        project_id={selectedProject!.id}
        type="all"
      />,
      'Create New Template',
      true
    );
  }

  async function showList() {
    props.menuOption('add_list');
    setAddType('list');
  }

  if (loading)
    return <LoadingComponent content="Loading available templates..." />;

  const columns = [
    {
      title: 'Preview',
      dataIndex: 'sampleImageUrl',
      key: 'sample_url',
      render: (sampleImageUrl: string) => (
        <>
          <TableImagePreview url={sampleImageUrl} />
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: Template) => (
        <div className="project-templates-table-name">{name}</div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'isGreenScreen',
      key: 'isGreenScreen',
      render: (isGreenScreen: boolean) => (
        <div>{isGreenScreen ? 'No Background' : 'Standard'}</div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => <div>{EntityStatus[status]}</div>,
    },
    {
      title: 'Variations',
      dataIndex: 'templateImageSets',
      key: 'templateImagesSets',
      render: (templateImageSets: TemplateImageSetFull[]) => (
        <div>{templateImageSets.length}</div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      props.callBack(selectedRowKeys);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (addType === 'list')
    return (
      <>
        {dataSource && dataSource.length > 0 ? (
          <Table
            rowKey={'id'}
            columns={columns}
            dataSource={getTemplates.filter(
              (t) =>
                !selectedProject!.templateGroupTemplates
                  .map((tg) => tg.templateId)
                  .includes(t.id)
            )}
            rowSelection={rowSelection}
          />
        ) : (
          <EmptyList
            message="You have no templates to add to the project. Please create a template and try again."
            buttonText="Create template"
            buttonAction={addTemplate}
          />
        )}
      </>
    );

  return (
    <>
      <Card className="project-no-templates">
        <div style={{ display: 'flex' }}>
          <h3>Select an option to add a template to the project:</h3>

          <Button
            danger
            style={{ marginLeft: 'auto' }}
            type="text"
            onClick={(e) => setAddingTemplate(false)}>
            Cancel
          </Button>
        </div>

        <div className="project-no-templates-buttons">
          <div className="no-template-option" onClick={showList}>
            <img
              src="/assets/images/template_library.svg"
              alt="template-library"
            />
            <Space direction="vertical">
              <p className="no-template-option-text">
                Select from template library
              </p>
              <p className="no-template-option-subtext">
                Select an existing template created in the templates library.
              </p>
            </Space>
          </div>

          <div className="no-template-option" onClick={addTemplate}>
            <img src="/assets/images/add_template.svg" alt="add_template" />
            <Space direction="vertical">
              <p className="no-template-option-text">Create a new template.</p>
              <p className="no-template-option-subtext">
                Create a new template and add it directly to the project.{' '}
              </p>
            </Space>
          </div>
        </div>
      </Card>
    </>
  );
}

export default observer(ProjectTemplatesAdd);
