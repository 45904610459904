import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import EmptyList from '../../../app/layout/EmptyList';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { useStore } from '../../../app/stores/store';
import TemplateWebsite from '../../templates/components/TemplateWebsite/TemplateWebsite';
import { ProjectTemplates } from '../../../app/models/projects/projectTemplates';

interface Props {
  changeTab: (e: string) => void;
}

function ProjectWebsite(props: Props) {
  const { projectStore, templatesStore, navigationStore } = useStore();
  const {
    projectTemplates,
    selectedProject,

    changeMainValue,
  } = projectStore;
  const {
    setTemplateWebsite,
    submittingWebsite,
    templateWebsite,

    updateTemplateWebsite,
  } = templatesStore;

  const [mainWebsite, setMainWebsite] = useState<number>(-1);

  useEffect(() => {
    if (!selectedProject) return;

    let id = -1;
    selectedProject.templateGroupTemplates.forEach((tg: ProjectTemplates) => {
      if (tg.isTemplateGroupMainWebsiteId) id = tg.templateId;
    });

    setMainWebsite(id);

    if (id > -1) {
      const website = projectTemplates.find(
        (t) => t.id === id
      )?.templateWebsite;
      setTemplateWebsite(website ?? null);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (templateWebsite === null && mainWebsite > -1) {
      setMainWebsite(-1);
    }
  }, [templateWebsite]);

  function setMain(id: number) {
    const template = projectTemplates.find((t) => t.id === id);
    if (!template) return;
    if (!selectedProject) return;
    setMainWebsite(id);

    const templateGroup = selectedProject.templateGroupTemplates.find(
      (tg: ProjectTemplates) => tg.templateId === template.id
    );

    updateTemplateWebsite(
      {
        ...template.templateWebsite,
        isMain: true,
        isTemplateGroupMainPopUpId: (
          templateGroup ?? { isTemplateGroupMainPopUpId: false }
        ).isTemplateGroupMainPopUpId,
      },
      selectedProject.id.toString()
    );
    changeMainValue(template.id, 'website', true);
    setTemplateWebsite({
      ...template.templateWebsite,
      isMain: true,
    });
  }

  return (
    <>
      {mainWebsite === -1 ? (
        <>
          <h3 className="tab-header">Project Website</h3>
          {projectTemplates.length > 0 ? (
            <>
              <Card>
                <div className="select-template">
                  <h3>Choose main website for this project.</h3>
                  <p>
                    Please select from the list below to set the main website
                    for the project:
                  </p>

                  {submittingWebsite ? (
                    <>
                      <LoadingComponent />
                    </>
                  ) : (
                    <>
                      <div className="select-template-list">
                        {projectTemplates.map((t) => (
                          <div
                            key={t.id}
                            className="select-template-list-item"
                            onClick={() => setMain(t.id)}>
                            <h3>{t.name}</h3>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </Card>
            </>
          ) : (
            <>
              <EmptyList
                message="You have no templates added to be able to select a main website. Please add a template before continuing."
                buttonText="Add template"
                buttonAction={() => props.changeTab('2')}
                customImage="/assets/images/no-website.svg"
              />
            </>
          )}
        </>
      ) : (
        <TemplateWebsite />
      )}
    </>
  );
}

export default observer(ProjectWebsite);
