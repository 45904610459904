import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Input, Layout } from 'antd';
import { useStore } from '../../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import ImageUploader from '../../../../app/common/fileUploader/ImageUploader';

function WebsiteFooter() {
  const { modalStore, templatesStore, projectStore } = useStore();
  const { openModal, closeModal } = modalStore;
  const {
    templateWebsite,

    uploadTemplateFile,
    setTemplateWebsite,
  } = templatesStore;
  const { readonlySettings } = projectStore;

  const [uploaded, setUploaded] = useState(false);
  const [fileLink, setFileLink] = useState('');
  const [showUploader, setShowUploader] = useState(true);
  const [footerUrl, setFooterUrl] = useState('');

  useEffect(() => {
    if (!templateWebsite) return;

    const footer = templateWebsite.imageBlocks?.find(
      (ib) => ib.placement === 1
    );
    setFileLink((footer ?? { imageUrl: '' }).imageUrl);
    setUploaded(true);
    setFooterUrl((footer ?? { linkUrl: '' }).linkUrl);
  }, [templateWebsite]);

  function deleteImage() {
    if (!templateWebsite) return;

    setTemplateWebsite({
      ...templateWebsite,
      imageBlocks: templateWebsite.imageBlocks!.map((im) => {
        if (im.placement === 1) {
          im.imageUrl = '';
        }
        return im;
      }),
    });

    setUploaded(false);
    setShowUploader(true);
    setFileLink('');
  }

  function openUploader() {
    if (readonlySettings) return;
    openModal(
      true,
      <ImageUploader onSubmit={submitImage} />,
      'Upload an image',
      true
    );
  }

  async function submitImage(file: any) {
    if (!templateWebsite) return;

    const url = await uploadTemplateFile(file);
    closeModal();
    const footer = templateWebsite.imageBlocks?.find(
      (ib) => ib.placement === 1
    );
    footer!.imageUrl = url;
    setUploaded(true);
    setFileLink(url);
  }

  function urlTyped(e: ChangeEvent<HTMLInputElement>) {
    if (!templateWebsite) return;
    const footer = templateWebsite.imageBlocks?.find(
      (ib) => ib.placement === 1
    );
    if (!footer) return;
    footer.linkUrl = e.target.value;
    setFooterUrl(e.target.value);
  }

  return (
    <>
      <Layout className="website-header">
        <div className="website-header-subheading">Footer Redirect</div>

        <Input
          disabled={readonlySettings}
          value={footerUrl}
          onChange={urlTyped}
          placeholder={'Enter click URL'}
          className="website-header-url"
        />

        <div className="website-header-subheading">Footer Image</div>

        <div onClick={openUploader} className="website-header-uploader">
          <img
            onLoad={() => setShowUploader(false)}
            style={{
              height: '90px',
              width: '100%',
              objectFit: 'contain',
              display: `${showUploader ? 'none' : 'block'}`,
            }}
            src={fileLink}
            alt={'uploaded file'}
          />
          {showUploader && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                style={{ height: '47px', width: '47px', margin: '0 auto' }}
                src="/assets/images/file-upload.svg"
                alt={'file upload'}
              />
              Click here to upload an image. <br />
              Recommended file: .jpg, .png format, less than 2MB. <br />
              Dimensions: 1080 x 900px
            </div>
          )}
        </div>

        {uploaded && !showUploader && (
          <Button
            disabled={readonlySettings}
            style={{ marginTop: '15px' }}
            danger
            type="primary"
            onClick={deleteImage}>
            Delete
          </Button>
        )}
      </Layout>
    </>
  );
}

export default observer(WebsiteFooter);
