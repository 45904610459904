const getScale = (size: number) => {
  let scale = 0.22;
  if (window.innerWidth < 1645) scale = 0.22;
  if (window.innerWidth < 1540) scale = 0.2;
  if (window.innerWidth < 1420) scale = 0.17;

  return scale;
};

export { getScale };
