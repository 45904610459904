import { useEffect } from 'react';
import { Button, Col, Form, notification, Row, Select } from 'antd';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { EditSubscriptionBucket } from 'src/app/models/subscriptions/editSubscriptionStorageBucket';
import AWSRegionSelectDropdown from 'src/features/account/components/AWSRegionSelectDropdown';
import DeleteConfirmationModal from 'src/app/common/modals/DeleteConfirmationModal';
import LoadingComponent from 'src/app/layout/LoadingComponent';
import { getActiveSubscriptionId } from 'src/app/common/util/common';

interface Props {}

function ImageStorage(props: Props) {
  const { modalStore, subscriptionsStore } = useStore();

  const {
    selectedSubscription,
    submitting,
    fetch,
    updateSubscriptionStorageBucket,
    loading,
  } = subscriptionsStore;
  const { openModal, closeModal } = modalStore;
  const activeSubscriptionId = getActiveSubscriptionId();

  const [form] = Form.useForm();

  const openErrorNotification = (type: any) => {
    notification.error({
      duration: 45,
      className: 'submit-notification-error',
      message: 'Error',
      description: 'There was an error updating the storage information',
    });
  };

  async function onChangeStorageLocation(values: any) {
    closeModal();
    try {
      if (!selectedSubscription || !activeSubscriptionId) return;

      const dto: EditSubscriptionBucket = {
        region: values.region,
      };

      const res = await updateSubscriptionStorageBucket(
        selectedSubscription.id,
        dto
      );
      if (res) {
        return toast.success('Saved storage details!');
      }
      openErrorNotification('error');
    } catch (error) {
    } finally {
      if (!activeSubscriptionId) return;
      await fetch(activeSubscriptionId, { mode: 'full' });
      if (selectedSubscription?.subscriptionStorageBucket?.region) {
        form.setFieldValue(
          'region',
          selectedSubscription?.subscriptionStorageBucket?.region
        );
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    openErrorNotification('error');
  };

  function showChangeWarning(values: any) {
    let deleteMessage = (
      <span>
        Please note that changing your storage location{' '}
        <strong>will only affect new media uploaded</strong> to your account.{' '}
        <strong>Existing media will remain in the original location</strong>.
      </span>
    );

    openModal(
      true,
      <DeleteConfirmationModal
        cancel={closeModal}
        confirm={() => onChangeStorageLocation(values)}
        message={<p>{deleteMessage}</p>}
        title="Before changing your storage location:"
        confirmText="Change Location"
      />,
      'Changing Storage Location',
      true,
      'delete-confirmation'
    );
  }

  useEffect(() => {
    if (!activeSubscriptionId) return;
    fetch(activeSubscriptionId, { mode: 'full' });
  }, []);

  useEffect(() => {
    if (!form || !selectedSubscription) return;
    form.setFieldsValue({
      region: selectedSubscription?.subscriptionStorageBucket?.region || '',
    });
  }, [loading, selectedSubscription]);

  if (loading)
    return <LoadingComponent content="Loading account storage details..." />;

  return (
    <>
      <Row className="storage-settings">
        <Col lg={12} sm={24}>
          <Form
            layout="vertical"
            form={form}
            requiredMark={false}
            name="basic"
            initialValues={{
              region:
                selectedSubscription?.subscriptionStorageBucket?.region || '',
            }}
            onFinish={showChangeWarning}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Form.Item
              label={<h2>Where would you like to store your media:</h2>}
              name="region"
              rules={[{ required: true, message: 'This field is required' }]}>
              <AWSRegionSelectDropdown
                value={
                  selectedSubscription?.subscriptionStorageBucket?.region || ''
                }
              />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 15 }}>
              <Button loading={submitting} type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Col>

        <Col lg={12} sm={24}>
          {/* <h3>To find your AWS S3 credentials:</h3>

          <ol>
            <li>Sign in to the AWS Management Console.</li>
            <li>
              Click on your <strong>username</strong> near the top right corner
              of the console.
            </li>
            <li>
              Select <strong>Security Credentials</strong> from the expanded
              drop-down list.
            </li>
            <li>
              On the <strong>My Security Credentials page,</strong> you’ll find
              all your security credentials.
            </li>
            <li>
              Expand the{' '}
              <strong>Access Keys (Access Key ID and Secret Access Key)</strong>{' '}
              option. You can create a new access key or manage existing ones on
              this page.
            </li>
          </ol> */}
        </Col>
      </Row>
    </>
  );
}

export default observer(ImageStorage);
