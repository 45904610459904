import { Select } from 'antd';
import {
  AWSS3Regions,
  StagingAWSS3Regions,
  IStandardOption,
} from 'src/app/common/options/selectOptions';

type Props = {
  onChange?: (value: string) => void;
  value?: string;
};
const { Option } = Select;

const AWSRegionSelectDropdown = ({ onChange, value }: Props) => {
  return (
    <Select
      showSearch
      defaultValue={'---'}
      value={value}
      filterOption={(inputValue, option) =>
        option!?.value
          ?.toString()
          .toUpperCase()
          .indexOf(inputValue.toUpperCase()) !== -1
      }
      onChange={onChange}
      className="aws-region-select-dropdown">
      {(process.env.REACT_APP_NODE_ENV === 'production'
        ? AWSS3Regions
        : StagingAWSS3Regions
      ).map((region: IStandardOption<string>) => (
        <Option
          key={region.value}
          value={region.value}
          className="aws-region-select-dropdown--option">
          <span className="region-name">{region.display}</span>{' '}
          <span className="region-value">{region.value}</span>
        </Option>
      ))}
    </Select>
  );
};

export default AWSRegionSelectDropdown;
