import React from 'react';
import { Button, Dropdown, Menu, Table } from 'antd';
import { Link } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { useStore } from '../../app/stores/store';
import AddTeamMember from './components/AddTeamMember';

function ActiveTeamMembers() {
  const { modalStore } = useStore();

  const { openModal } = modalStore;

  function openAddTeamMember() {
    openModal(true, <AddTeamMember />, 'Add New Team Member', true);
  }

  const dataSource = [
    {
      key: '1',
      memberName: 'Member One',
      role: 'Donec id elit non consectuer.',
      email: 'test@test.com',
      telNumber: '000000000',
      projects: 2,
      id: 1,
    },
    {
      key: '2',
      memberName: 'Member Two',
      role: 'Donec id elit non consectuer.',
      email: 'test@test.com',
      telNumber: '000000000',
      projects: 9,
      id: 2,
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="0" style={{ color: '#1779ba' }}>
        Duplicate
      </Menu.Item>
      <Menu.Item key="0">Delete</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'memberName',
      key: 'memberName',
      render: (name: string, record: any) => (
        <Link
          style={{ fontWeight: 'bold' }}
          to={`/team/active/member-${record.id}`}>
          {name}
        </Link>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'templates',
    },
    {
      title: 'Tel Number',
      dataIndex: 'telNumber',
      key: 'telNumber',
    },
    {
      title: 'Projects',
      dataIndex: 'projects',
      key: 'projects',
    },
    {
      title: '',
      key: 'more-actions',
      width: '50px',
      render: () => (
        <>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight"
            overlayStyle={{ width: '150px' }}>
            <MoreOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="active-team">
        <Button
          type={'primary'}
          className="active-team-add-member-button"
          onClick={openAddTeamMember}>
          Add Team Member
        </Button>
        <Table
          style={{ marginTop: '39px' }}
          dataSource={dataSource}
          columns={columns}
        />
      </div>
    </>
  );
}

export default ActiveTeamMembers;
