import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';
import DES from 'crypto-js/tripledes';
import Base64 from 'crypto-js/enc-base64';
import JSZip from 'jszip';

export const wait = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
/**
 *
 * @param desc A utility function to help with decrypting and encrypting keys
 * @param encrypt  encrypt or decrypt by default encrypt
 * @returns
 */
export const encryptDecryptKey = (
  item: string,
  key: string,
  encrypt: 'encrypt' | 'decrypt' = 'encrypt'
) => {
  const iv = Hex.parse('00000000000000000000000000000000');
  const cryptkey = Utf8.parse(key);
  if (encrypt === 'encrypt') {
    const result = DES.encrypt(item, cryptkey, {
      iv,
    });
    return result.toString();
  }

  const crypted = Base64.parse(item);
  // @ts-expect-error: Type 'WordArray' is valid here but the other params are not needed as far as the existing implementation is concerned (changing this at this point would require changing all the existing encrypted keys in the database incl. payment gateway keys)
  const dec = DES.decrypt({ ciphertext: crypted }, cryptkey, {
    iv,
  });

  const decrypted = dec.toString(Utf8);
  return decrypted.toString();
};

export const downloadAndZipImages = async (urls: string[]) => {
  const zip = new JSZip();

  // Add Images to the zip file

  await Promise.allSettled(
    urls.map(async (url) => {
      if (!url) {
        return;
      }

      const response = await fetch(url);

      const blob = await response.blob();
      const fileName =
        url.split('/').pop() ??
        `Happy-Snappy-Lite-Image-${urls.indexOf(url)}.jpg`;
      zip.file(fileName, blob);
    })
  );

  // Generate the zip file
  const zipData = await zip.generateAsync({
    type: 'blob',
    streamFiles: true,
  });

  // Create a download link for the zip file
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(zipData);
  link.download = 'Happy_Snappy_Lite_Images.zip';
  link.click();
};
