import React, { useState } from 'react';
import { Button, Upload } from 'antd';
import { useStore } from '../../stores/store';
import { toast } from 'react-toastify';

type UploadType = 'standard' | 'background';

interface Props {
  onSubmit: (file: any, size: any) => void;
  imageType?: UploadType;
}

function ImageUploader(props: Props) {
  const { onSubmit, imageType = 'standard' } = props;

  const [file, setFile] = useState<File | null>(null);
  const [imageAdded, setImageAdded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [previewLink, setPreviewLink] = useState('');
  const fileTypes = ['image/png', 'image/jpeg'];
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const { Dragger } = Upload;
  const { modalStore } = useStore();

  const draggerProps = {
    name: 'file',
    multiple: false,
    onChange(info: any) {},
    beforeUpload: (file: File) => {
      if (!fileTypes.includes(file.type)) {
        toast.error(
          'Invalid file type, please only upload a PNG or JPEG image.'
        );
        return false || Upload.LIST_IGNORE;
      }
      setFile(file);
      const url = URL.createObjectURL(file);
      const img = document.createElement('img');
      img.src = url;
      img.addEventListener('load', () => {
        const { width, height } = img;
        setSize({ width, height });

        setPreviewLink(url);
        setImageAdded(true);

        // Make sure the image is the right size
        if (height * width < 3566952 && imageType === 'background') {
          toast.error(
            'Picture is too small, please make sure it is equal to or larger than 2778 x 1284 for landscape and 1284 x 2778 for portrait.'
          );
          removeImage();
          return Upload.LIST_IGNORE;
        }

        return false;
      });
    },
  };

  function removeImage() {
    setFile(null);
    setPreviewLink('');
    setImageAdded(false);
  }
  return (
    <>
      <div className="image-uploader" style={{ margin: '0 20px' }}>
        <Dragger style={{ padding: '10px' }} {...draggerProps}>
          {imageAdded ? (
            <img
              style={{ maxWidth: '100px', maxHeight: '130px' }}
              src={previewLink}
              alt="selected"
            />
          ) : (
            <div>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-text">
                Please upload a PNG or a JPEG image.
              </p>
            </div>
          )}
        </Dragger>
        {imageAdded && (
          <>
            {file && (
              <div>
                <strong>Name:</strong> {file.name} | <strong>Type:</strong>{' '}
                {file.type}
              </div>
            )}
            <Button
              style={{ marginLeft: '-16px' }}
              danger
              type="text"
              onClick={removeImage}>
              Remove
            </Button>
          </>
        )}
      </div>
      <div className="image-uploader-buttons">
        <Button
          disabled={uploading}
          className="yellow-ant-btn"
          onClick={modalStore.closeModal}>
          Cancel
        </Button>

        <Button
          loading={uploading}
          type="primary"
          htmlType="submit"
          onClick={() => {
            setUploading(true);
            onSubmit(file, size);
          }}>
          Submit
        </Button>
      </div>
    </>
  );
}

export default ImageUploader;
