import React from 'react';
import { Checkbox, Form, Input } from 'antd';
import { useStore } from '../../../../app/stores/store';

function PopupContent() {
  const { templatesStore, projectStore } = useStore();
  const {
    selectedDataCollection,

    setDataCollection,
    setDisablePopupSave,
  } = templatesStore;

  const { readonlySettings } = projectStore;

  const [collectionForm] = Form.useForm();
  function selectField(field: string, control: string) {
    if (readonlySettings) return;

    if (control === 'label') {
      const value = collectionForm.getFieldValue(field);
      collectionForm.setFieldsValue({
        [field]: !value,
      });
    }

    setDataCollection({
      ...selectedDataCollection,
      collectName: collectionForm.getFieldValue('name') ?? false,
      collectEmail: collectionForm.getFieldValue('email_address') ?? false,
      collectPhone: collectionForm.getFieldValue('phone_num') ?? false,
    });
    setDisablePopupSave();
  }

  const [contentForm] = Form.useForm();
  function setContent() {
    setDataCollection({
      ...selectedDataCollection,
      headerText: contentForm.getFieldValue('header'),
      bodyText: contentForm.getFieldValue('message'),
      termsText: contentForm.getFieldValue('terms'),
    });
    setDisablePopupSave();
  }

  const { TextArea } = Input;
  return (
    <>
      <Form
        form={contentForm}
        initialValues={{
          header: selectedDataCollection?.headerText,
          message: selectedDataCollection?.bodyText,
          terms: selectedDataCollection?.termsText,
        }}
        name="popup-info"
        onBlur={setContent}
        onValuesChange={setContent}
        layout="vertical">
        <Form.Item
          label="Header:"
          rules={[{ required: true, message: 'Please add message header.' }]}
          name="header">
          <Input disabled={readonlySettings} />
        </Form.Item>

        <Form.Item
          label="Message Text:"
          rules={[{ required: true, message: 'Please add message text.' }]}
          name="message">
          <TextArea rows={2} disabled={readonlySettings} />
        </Form.Item>

        <Form.Item
          label="Terms:"
          rules={[
            { required: true, message: 'please enter the terms of the popup.' },
          ]}
          name="terms">
          <TextArea rows={4} disabled={readonlySettings} />
        </Form.Item>
      </Form>

      <div className="website-header-subheading">Data To Collect</div>

      <Form
        form={collectionForm}
        name="data-collection"
        initialValues={{
          name: selectedDataCollection?.collectName,
          email_address: selectedDataCollection?.collectEmail,
          phone_num: selectedDataCollection?.collectPhone,
        }}>
        <Form.Item style={{ marginBottom: '0' }}>
          <Form.Item valuePropName="checked" noStyle name="name">
            <Checkbox
              onChange={() => selectField('name', 'checkbox')}
              disabled={readonlySettings}
            />
          </Form.Item>
          <label
            onClick={() => selectField('name', 'label')}
            style={{ marginLeft: '10px', cursor: 'pointer' }}>
            Name
          </label>
        </Form.Item>

        <Form.Item style={{ marginBottom: '0' }}>
          <Form.Item valuePropName="checked" noStyle name="email_address">
            <Checkbox
              onChange={() => selectField('email_address', 'checkbox')}
              disabled={readonlySettings}
            />
          </Form.Item>
          <label
            onClick={() => selectField('email_address', 'label')}
            style={{ marginLeft: '10px', cursor: 'pointer' }}>
            Email Address
          </label>
        </Form.Item>

        <Form.Item style={{ marginBottom: '0' }}>
          <Form.Item valuePropName="checked" noStyle name="phone_num">
            <Checkbox
              onChange={() => selectField('email_address', 'checkbox')}
              disabled={readonlySettings}
            />
          </Form.Item>
          <label
            onClick={() => selectField('phone_num', 'label')}
            style={{ marginLeft: '10px', cursor: 'pointer' }}>
            Telephone Number
          </label>
        </Form.Item>
      </Form>
    </>
  );
}

export default PopupContent;
