import React from 'react';
import { Collapse } from 'antd';
import PopupContent from './PopupContent';
import PopupSettings from './PopupSettings';
import PopupStyle from './PopupStyle';

function PopupMenu() {
  const { Panel } = Collapse;
  return (
    <>
      <Collapse
        accordion
        expandIconPosition="right"
        className="project-website-collapse">
        <Panel header="Content" key="1">
          <PopupContent />
        </Panel>

        <Panel header="Settings" key="2">
          <PopupSettings />
        </Panel>

        <Panel header="Style" key="3">
          <PopupStyle />
        </Panel>
      </Collapse>
    </>
  );
}

export default PopupMenu;
