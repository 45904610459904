import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { CreateStripeCustomerDto } from '../../../app/models/stripe/createStripeCustomer.dto';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import StripePayment from './subscription/StripePayment';
import { Elements } from '@stripe/react-stripe-js';

interface Props {
  cancel: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '');

function ActivateSubscription(props: Props) {
  const { cancel } = props;

  const [step, setStep] = useState('email');
  const [sameAddress, setSameAddress] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [subId, setSubId] = useState('');

  const { accountStore, stripeStore } = useStore();
  const { selectedUserDetails } = accountStore;
  const {
    submittingCustomer,

    createCustomer,
  } = stripeStore;

  async function submit(e: any) {
    const dto: CreateStripeCustomerDto = {
      email: e.email,
      name: e.name,
      city: e.city,
      country: e.country,
      line1: e.line1,
      postalCode: e.postal,
      state: e.state,
      shippingCity: sameAddress ? e.city : e.shipping_city,
      shippingCountry: sameAddress ? e.country : e.shipping_country,
      shippingLine1: sameAddress ? e.line1 : e.shipping_line1,
      shippingPostalCode: sameAddress ? e.postal : e.shipping_postal,
      shippingState: sameAddress ? e.state : e.shipping_state,
    };

    const result = await createCustomer(dto);
    setClientSecret(result.clientSecret);
    setSubId(result.subscriptionId);
    setStep('pay');
  }

  return (
    <>
      <h4>
        {step === 'email'
          ? 'Enter details linked to subscription:'
          : 'Enter payment details:'}
      </h4>

      {step === 'email' && (
        <Form
          onFinish={submit}
          hideRequiredMark={true}
          layout="vertical"
          className="email-form"
          initialValues={{ email: selectedUserDetails!.email }}>
          <Row gutter={50}>
            <Col span={10}>
              <Form.Item
                name="email"
                label="Email:"
                rules={[
                  { required: true, message: 'Enter email address.' },
                  { type: 'email', message: 'Please enter a valid email.' },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="name"
                label="Name:"
                rules={[{ required: true, message: 'Enter name.' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={50}>
            <Col span={10}>
              <h5>Billing Address</h5>

              <Form.Item
                name="line1"
                label="Address Line 1:"
                rules={[{ required: true, message: 'Enter address line.' }]}>
                <Input />
              </Form.Item>

              <Form.Item name="line2" label="Address Line 2:">
                <Input />
              </Form.Item>

              <Form.Item
                name="city"
                label="City: "
                rules={[{ required: true, message: 'Enter city.' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="country"
                label="Country:"
                rules={[{ required: true, message: 'Enter country.' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="postal"
                label="Postal Code:"
                rules={[{ required: true, message: 'Enter postal code.' }]}>
                <Input />
              </Form.Item>

              <Checkbox onChange={(e) => setSameAddress(e.target.checked)}>
                Same shipping address as billing address
              </Checkbox>
            </Col>

            <Col span={10}>
              {!sameAddress && (
                <>
                  <h5>Shipping Address</h5>

                  <Form.Item
                    name="shipping_line1"
                    label="Shipping Address Line 1:"
                    rules={
                      sameAddress
                        ? []
                        : [{ required: true, message: 'Enter address line.' }]
                    }>
                    <Input />
                  </Form.Item>

                  <Form.Item name="shipping_line2" label="Address Line 2:">
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="shipping_city"
                    label="Shipping City: "
                    rules={
                      sameAddress
                        ? []
                        : [{ required: true, message: 'Enter city.' }]
                    }>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="shipping_country"
                    label="Shipping Country:"
                    rules={
                      sameAddress
                        ? []
                        : [{ required: true, message: 'Enter country.' }]
                    }>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="shipping_postal"
                    label="Shipping Postal Code:"
                    rules={
                      sameAddress
                        ? []
                        : [{ required: true, message: 'Enter postal code.' }]
                    }>
                    <Input />
                  </Form.Item>
                </>
              )}
            </Col>
          </Row>

          <div className="email-form-buttons">
            <Button
              type="primary"
              htmlType="submit"
              loading={submittingCustomer}>
              Next
            </Button>
            {step === 'email' && (
              <Button
                htmlType="button"
                onClick={cancel}
                className="yellow-ant-btn"
                loading={submittingCustomer}>
                Cancel
              </Button>
            )}
          </div>
        </Form>
      )}

      {step === 'pay' && (
        <>
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
              appearance: {
                theme: 'stripe',
              },
            }}>
            <StripePayment subId={subId} />
          </Elements>
        </>
      )}
    </>
  );
}

export default observer(ActivateSubscription);
