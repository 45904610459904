import {
  Button,
  Col,
  Radio,
  RadioChangeEvent,
  Row,
  Skeleton,
  Tooltip,
} from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../app/stores/store';
import TemplateReview from './template-preview/TemplatePreview';
import TemplateVariationsListItem from './TemplateVariationsListItem';
import { fabric } from 'fabric';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TemplateImageFull } from '../../../app/models/templates/templateImageFull';
import { getScale } from '../../../app/common/util/canvasScale';

function TemplateEditorContent() {
  const { templatesStore, fabricStore } = useStore();

  const {
    imageSets,
    selectedTemplate,
    templateSetImages,
    templateImages,
    submittingImageSet,
    imageSetOrientation,
    selectedImageSet,
    updatingTemplate,
    selectedImage,

    createImageSet,
    setImageSetOrientation,
    setSelectedImage,
    updateLayerPosition,
    updateLayerSize,
  } = templatesStore;

  const {
    canvas,
    setCanvasOrientation,
    clearCanvas,
    addShape,
    setObjectHandler,
    getObject,
    addBackground,
  } = fabricStore;

  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (!templateImages) return;
    if (updatingTemplate) return;

    clearCanvas();
    if (!firstLoad) {
      if (selectedTemplate!.isGreenScreen) {
        fabric.Image.fromURL(
          templateImages.backgroundUrl,
          (oImg: fabric.Image) =>
            addBackground(oImg, imageSetOrientation, false)
        );
      } else {
        fabric.Image.fromURL(
          `/assets/default/${
            imageSetOrientation === 'landscape' ? 'landscape' : 'portrait'
          }-photo.jpg`,
          (oImg) => {
            addShape(oImg);
            oImg.sendToBack();
          },
          {
            top: 0,
            left: 0,
            selectable: false,
            evented: false,
            name: 'people-placeholder',
            scaleX: 2480 / 3126,
            scaleY: 1748 / 2251,
          }
        );
      }
    } else {
      setFirstLoad(false);
    }

    templateImages.templateImageLayers.forEach((i) => {
      let top = 0,
        left = 0;
      switch (imageSetOrientation) {
        default:
        case 'landscape':
          top = i.top * 1748;
          left = i.left * 2480;
          break;

        case 'portrait':
          top = i.top * 2480;
          left = i.left * 1748;
          break;
      }
      fabric.Image.fromURL(i.imageUrl, function (oImg: fabric.Image) {
        oImg.set('top', top);
        oImg.set('left', left);
        oImg.set('name', i.id.toString());
        addShape(oImg);
        oImg.scaleToHeight(i.height * 0.8);
        oImg.scaleToWidth(i.width * 0.8);
        setObjectHandler('selected', i.id.toString(), setSelected);
        setObjectHandler('moved', i.id.toString(), setMoved);
        setObjectHandler('deselected', i.id.toString(), setDeselected);
        setObjectHandler('scaled', i.id.toString(), setChanged);
      });
    });
  }, [templateImages]);

  function setChanged(e: fabric.IEvent<Event>) {
    if (e.target === undefined) return;
    const object = getObject(e.target.name ?? '');
    if (!object) return;

    updateLayerSize(
      object.getScaledWidth() / 0.8,
      object.getScaledHeight() / 0.8
    );
    let top = +e.target.top!;
    let left = +e.target.left!;

    updateLayerPosition(top, left);
  }

  function setSelected(e: fabric.IEvent<Event>) {
    if (e.target === undefined) return;

    const layer = templateSetImages.find(
      (l) => l.id.toString() === e.target!.name
    );
    if (layer) {
      setSelectedImage(layer);
    }
  }

  function setDeselected(e: any) {
    setSelectedImage(null);
  }

  function setMoved(e: fabric.IEvent<Event>) {
    if (e.target === undefined) return;

    let top = +e.target.top!;
    let left = +e.target.left!;

    updateLayerPosition(top, left);
  }

  function newVariation() {
    if (!selectedTemplate) return;

    createImageSet(selectedTemplate.id);
  }

  function orientationChanged(e: RadioChangeEvent) {
    canvas?.clear();

    setImageSetOrientation(e.target.value);
    let scale = getScale(window.innerWidth);
    let link = undefined;
    if (selectedTemplate!.isGreenScreen) {
      link = (
        selectedImageSet!.templateImages.find(
          (im: TemplateImageFull) =>
            im.isPortrait === (e.target.value === 'portrait')
        ) ?? {
          backgroundUrl: undefined,
        }
      ).backgroundUrl;
    }

    setCanvasOrientation(
      e.target.value,
      selectedTemplate!.isGreenScreen!,
      scale,
      link
    );
  }

  return (
    <>
      <Row className="layer-content-header">
        <Col span={6} style={{ display: 'flex' }}>
          {selectedTemplate!.isGreenScreen && (
            <>
              <p>Backgrounds</p>
              <Tooltip title="Variations allow different background options for a single template. When an image is uploaded from the mobile app by the photographer, all background variations will be applied to to the single image.">
                <InfoCircleOutlined
                  style={{ margin: 'auto 10px', height: 'fit-content' }}
                />
              </Tooltip>
            </>
          )}
        </Col>

        <Col span={18}>
          <Radio.Group
            className="template-orientation-group"
            defaultValue="landscape"
            buttonStyle="solid"
            onChange={(e: RadioChangeEvent) => orientationChanged(e)}>
            <Radio.Button value="landscape">Landscape</Radio.Button>
            <Radio.Button value="portrait">Portrait</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Row className="layer-content">
        <Col span={6} className="layer-content-col1">
          {selectedTemplate!.isGreenScreen && (
            <>
              <div>
                {imageSets &&
                  imageSets.map((v: any, i) => (
                    <TemplateVariationsListItem key={v.id} variation={v} />
                  ))}
              </div>
              {submittingImageSet && (
                <div className="variation-loader">
                  <Skeleton.Button active />
                </div>
              )}

              <Button
                type="primary"
                className="add-variation"
                onClick={newVariation}>
                + Add Background
              </Button>
            </>
          )}
        </Col>

        <Col span={18}>
          <TemplateReview />
        </Col>
      </Row>
    </>
  );
}

export default observer(TemplateEditorContent);
