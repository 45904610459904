import { useCallback, useEffect, useMemo } from 'react';
import { useStore } from '../../stores/store';
import { PACKAGES } from '../../../features/user/packages';
import { CustomWindow } from './types';

const customWindow = window as any as CustomWindow;

const TutorialWalkthrough = () => {
  const { userStore, subscriptionsStore, accountStore } = useStore();
  const { selectedUserDetails } = accountStore;
  const { user } = userStore;

  const initializeTutorialData = async () => {
    if (!user || !customWindow?.Appcues) return;
    const planTier = PACKAGES.find(
      (item) => item.id === selectedUserDetails?.subscriptionPackage
    )?.title;

    const role = user.isSysAdmin
      ? 'system admin'
      : user.isAdministrator
      ? 'admin'
      : user.isManager
      ? 'manager'
      : 'photographer';

    customWindow?.Appcues?.identify(user.id?.toString() || '', {
      planTier: planTier || null,
      role,
      accountId: selectedUserDetails?.subscriptionPackage,
      firstName: user.displayName,
      companyName: selectedUserDetails?.companyName,
      email: user.email,
    });
  };

  useEffect(() => {
    const initialise = async () => {
      await userStore.getUser();
      initializeTutorialData();
    };

    if (!userStore.user) {
      initialise();
    } else {
      initializeTutorialData();
    }
  }, [userStore.user]);
  return null;
};
export default TutorialWalkthrough;
export { customWindow as window };
