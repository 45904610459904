import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../app/stores/store';
import { Button, DatePicker, Select, Space, Table } from 'antd';
import EmptyList from '../../../../app/layout/EmptyList';
import moment from 'moment';
import { yyyymmdd } from '../../../../app/common/util/date';
import SafariMessage from '../../../../app/common/modals/SafariMessage';
import { TimePeriods } from '../../../../app/common/options/selectOptions';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { DataCollectionDto } from '../../../../app/models/templates/dataCollection.dto';
import { toXlsx } from '../../../../app/common/util/excelExport';
import { ColumnsType } from 'antd/es/table/interface';

const { RangePicker } = DatePicker;
const { Option } = Select;

function PopupResults() {
  const { templatesStore, modalStore, commonStore } = useStore();
  const {
    dataCollectionResults,
    selectedTemplate,
    loadingDataCollection,

    fetchCollectionResults,
  } = templatesStore;
  const { downloadModal } = modalStore;
  const { isSafari } = commonStore;
  const [exportingData, setExporting] = useState<boolean>(false);

  const [filters, setFilters] = useState({
    start: '20000101',
    end: yyyymmdd(new Date()),
  });
  const [showRange, setShowRange] = useState<boolean>(false);

  const columns: ColumnsType<any> = [
    {
      key: 'collectionDate',
      dataIndex: 'collectedDateTime',
      title: 'Collection Date',
      sorter: (a: Date, b: Date) => (new Date(a) > new Date(b) ? 1 : -1),
      defaultSortOrder: 'ascend',
      render: (collectedDateTime: string) => (
        <div>
          {new Date(collectedDateTime).toLocaleDateString('en-ZA', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}
        </div>
      ),
    },
    {
      key: 'collectionDeviceType',
      dataIndex: 'userAgentDeviceType',
      title: 'Device',
    },
    {
      key: 'collectionName',
      dataIndex: 'collectedName',
      title: 'Name',
    },
    {
      key: 'collectionPhone',
      dataIndex: 'collectedPhone',
      title: 'Phone',
    },
    {
      key: 'collectionEmail',
      dataIndex: 'collectedEmail',
      title: 'Email',
    },
  ];

  async function datesChanged(e: any) {
    if (!selectedTemplate) return;
    const end = yyyymmdd(new Date(e[1]));
    const start = yyyymmdd(new Date(e[0]));

    if (end === filters.end) return;

    setFilters({
      start,
      end,
    });

    await fetchCollectionResults(selectedTemplate.id, start, end);
  }

  async function exportData() {
    try {
      setExporting(true);

      if (!selectedTemplate) return;

      if (isSafari) downloadModal(<SafariMessage />);

      const data = dataCollectionResults.map((value: DataCollectionDto) => ({
        'Collection Date': new Date(value.collectedDateTime).toLocaleDateString(
          'en-ZA',
          {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }
        ),
        Device: value.userAgentDeviceType,
        Name: value.collectedName,
        Phone: value.collectedPhone,
        Email: value.collectedEmail,
      }));
      const date = new Date().toLocaleDateString('en-Za').replace(/\//g, '');
      const fileName = `PopupResults-${date}`;

      toXlsx(data, fileName);
    } catch (error) {
      console.error(error);
    } finally {
      setExporting(false);
    }
  }

  async function timeSelected(e: string) {
    if (!selectedTemplate) return;

    if (e === 'custom') {
      setShowRange(true);
      return;
    }

    setShowRange(false);
    const lowerDate = new Date();
    switch (e) {
      case '7_days':
        lowerDate.setDate(lowerDate.getDate() - 7);
        break;

      case '14_days':
        lowerDate.setDate(lowerDate.getDate() - 14);
        break;

      case '30_days':
        lowerDate.setDate(lowerDate.getDate() - 30);
        break;
    }

    await fetchCollectionResults(
      selectedTemplate.id,
      e === 'all' ? '20000101' : yyyymmdd(lowerDate),
      yyyymmdd(new Date())
    );
  }

  return (
    <>
      <h3 className="tab-header">Popup Results</h3>

      <div className="collections-filter">
        <div>
          <Select
            defaultValue={'all'}
            onChange={timeSelected}
            placeholder="Please select"
            style={{ width: 130 }}>
            {TimePeriods.map((t) => (
              <Option value={t.value} key={t.value}>
                {t.label}
              </Option>
            ))}
            <Option value={'custom'}>Custom</Option>
          </Select>

          {showRange && (
            // @ts-ignore
            <RangePicker
              className="collections-filter-dates"
              onChange={datesChanged}
              defaultValue={[moment(), moment()]}
            />
          )}
        </div>

        <Button
          loading={exportingData}
          type="primary"
          className="collections-filter-export"
          onClick={exportData}>
          <Space>
            <span>Export</span>
            <img src={'/assets/images/excel.png'} alt={'excel icon'} />
          </Space>
        </Button>
      </div>

      {loadingDataCollection ? (
        <LoadingComponent content="Loading popup results..." />
      ) : (
        <>
          {dataCollectionResults.length > 0 ? (
            <Table
              loading={loadingDataCollection}
              columns={columns}
              rowKey={'id'}
              dataSource={dataCollectionResults}
            />
          ) : (
            <EmptyList
              message="No data has been collected."
              buttonAction={() => {}}
              buttonText=""
              hideButton={true}
              customImage="/assets/images/no-data-collection.svg"
            />
          )}
        </>
      )}
    </>
  );
}

export default observer(PopupResults);
