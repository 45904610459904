import { Row, Select, Input, DatePicker, Button, Tooltip, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import {
  TimePeriodsCustom,
  UnlinkedOrLinkedPhotosOptions,
} from '../../../app/common/options/selectOptions';
import { Template } from '../../../app/models/templates/template';
import { useStore } from '../../../app/stores/store';
import '../../../app/common/styles/projectPhotosFilter.scss';
import { yyyymmdd } from '../../../app/common/util/date';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import InputButtonCombo from 'src/features/projects/components/ProjectPhotosFilterInputButtonCombo';
import { downloadAndZipImages } from 'src/utils/helpers';

const { Option } = Select;
const { RangePicker } = DatePicker;

export type DateRange = {
  start: string;
  end: string;
};

export type FilterOptions = {
  qrCode: string;
  template: string;
  dateRange: DateRange;
  unlinked: boolean | null;
};

interface Props {
  filterEvent: (optiosn: FilterOptions) => void;
}

function ProjectPhotosFilter(props: Props) {
  const {
    projectPhotos,
    projectTemplates,
    getPhotos,
    selectedProject,
    loadingPhotos,
    selectedProjectPhotos,
    linkPhotosToNewQRCode,
    unwatermarkPhotos,
    setSelectedProjectPhotos,
  } = useStore().projectStore;

  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [options, setOptions] = useState<FilterOptions>({
    qrCode: '',
    template: '',
    dateRange: {
      start: '20000101',
      end: yyyymmdd(),
    },
    unlinked: null,
  });
  const [relinkPhotosCode, setRelinkPhotosCode] = useState<string>('');
  const [unwatermarkPhotosCode, setUnwatermarkPhotosCode] =
    useState<string>('');
  const { filterEvent } = props;

  async function filter(options: FilterOptions) {
    if (!selectedProject) return;

    await getPhotos(
      selectedProject.id,
      options.dateRange.start,
      options.dateRange.end,
      1,
      options.template,
      options.unlinked === true ? undefined : options.qrCode,
      options.unlinked
    );
    filterEvent(options);
  }

  const datesChange = useCallback(
    (e: any) => {
      const start = e[0].format('YYYYMMDD');
      const end = e[1].format('YYYYMMDD');

      const newOptions: FilterOptions = {
        ...options,
        dateRange: {
          start,
          end,
        },
      };
      setOptions(newOptions);
      filter(newOptions);
    },
    [options]
  );

  const templateChange = useCallback(
    (e: number) => {
      const newOptions = {
        ...options,
        template: e === -1 ? '' : e.toString(),
      };
      setOptions(newOptions);
      filter(newOptions);
    },
    [options]
  );

  const filterRequest = debounce(filter, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterDebounce = useCallback(filterRequest, []);

  const codeChange = useCallback(
    (e: any) => {
      const value = e.target.value;
      const newOptions = {
        ...options,
        qrCode: value,
        unlinked: null,
      };
      setOptions(newOptions);
      filterDebounce(newOptions);
    },
    [options]
  );

  const unlinkedOnlyChange = useCallback(
    (key: string) => {
      const option = UnlinkedOrLinkedPhotosOptions.find((o) => o.key === key);
      const newOptions = {
        ...options,
        unlinked: option?.value ?? null,
      };
      setOptions(newOptions);
      filterDebounce(newOptions);
    },
    [options]
  );

  const searchCode = useCallback(
    (e: any) => {
      filterDebounce(options);
    },
    [options]
  );

  const timeChange = useCallback(
    (e: string) => {
      setShowPicker(false);

      const lowerDate = new Date();
      let start = '20000101';
      switch (e) {
        case 'today':
          start = yyyymmdd();
          break;

        case '7_days':
          lowerDate.setDate(lowerDate.getDate() - 7);
          start = yyyymmdd(lowerDate);
          break;

        case '14_days':
          lowerDate.setDate(lowerDate.getDate() - 14);
          start = yyyymmdd(lowerDate);
          break;

        case '30_days':
          lowerDate.setDate(lowerDate.getDate() - 30);
          start = yyyymmdd(lowerDate);
          break;

        case 'custom':
          setShowPicker(true);
          return;

        default:
        case 'all':
          start = '20000101';
          break;
      }
      const newOptions = {
        ...options,
        dateRange: {
          start,
          end: yyyymmdd(),
        },
      };

      setOptions(newOptions);
      filter(newOptions);
    },
    [options]
  );

  const onLinkPhotosToNewQRCode = useCallback(async () => {
    try {
      if (!selectedProject) return;
      const isSuccess = await linkPhotosToNewQRCode(relinkPhotosCode);
      if (isSuccess !== true) throw new Error();

      setSelectedProjectPhotos([]);
      toast.success(
        `Photos linked to new QR code (${relinkPhotosCode}) successfully.`
      );
      getPhotos(selectedProject.id, '20000101', yyyymmdd());
      setRelinkPhotosCode('');
    } catch (error) {
      console.error(error);
      toast.error(
        'Error linking photos to your new QR code, please recheck your code and try again.'
      );
    }
  }, [selectedProject, relinkPhotosCode]);

  const onUnwatermarkImages = useCallback(async () => {
    try {
      if (!selectedProject) return;
      const isSuccess = await unwatermarkPhotos(unwatermarkPhotosCode);
      if (isSuccess !== true) throw new Error();

      setSelectedProjectPhotos([]);
      toast.success(`Images successfully unwatermarked.`);
      // getPhotos(selectedProject.id, '20000101', yyyymmdd());
      setUnwatermarkPhotosCode('');
    } catch (error) {
      console.error(error);
      toast.error(
        'Error unwatermarking images, please recheck your code or the photos selected and try again.'
      );
    }
  }, [selectedProject, selectedProjectPhotos]);

  const handleDownloadSelectedPhotos = useCallback(async () => {
    if (!selectedProject || selectedProjectPhotos.length <= 0) return;
    const selectedPhotoUrls = selectedProjectPhotos.map((p) => p.imageUrl);

    toast.promise(downloadAndZipImages(selectedPhotoUrls), {
      pending: 'Downloading Photos...',
      success: 'Photos downloaded successfully.',
      error: 'Error downloading photos.',
    });

    setSelectedProjectPhotos([]);
  }, [selectedProjectPhotos]);

  return (
    <>
      <Row className="pf-container">
        <div className="pf-filter-container">
          <div className="pf-option">
            <p className="pf-option__label">Filter by Template: </p>
            <Select
              loading={loadingPhotos}
              onChange={templateChange}
              defaultValue={-1}
              placeholder="Filter by Templates">
              <Option key={-1} value={-1}>
                All
              </Option>
              {projectTemplates.map((t: Template) => (
                <Option key={t.id} value={t.id}>
                  {t.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="pf-option">
            <p className="pf-option__label">Search QR Code </p>
            <Space direction="horizontal">
              <Tooltip
                title={
                  options.unlinked === true
                    ? 'To search using a QR code you need to set the photo options to show All or Linked photos'
                    : undefined
                }>
                <div>
                  <Input
                    placeholder="Enter..."
                    onChange={codeChange}
                    value={options.qrCode}
                    disabled={options.unlinked === true}
                  />
                </div>
              </Tooltip>
              <Select
                style={{ maxWidth: 150 }}
                autoClearSearchValue={false}
                loading={loadingPhotos}
                defaultValue={UnlinkedOrLinkedPhotosOptions[0].key}
                onChange={unlinkedOnlyChange}
                value={
                  UnlinkedOrLinkedPhotosOptions.find(
                    (o) => o.value === options.unlinked
                  )?.key
                }>
                {UnlinkedOrLinkedPhotosOptions.map((t, i) => (
                  <Option value={t.key} key={t.key}>
                    {t.label}
                  </Option>
                ))}
              </Select>
            </Space>
          </div>
          <div className="pf-option">
            <p className="pf-option__label">Click to View </p>
            <Button type="primary" onClick={searchCode}>
              Search Code
            </Button>
          </div>
          <div className="pf-option">
            <p className="pf-option__label">Showing Photos Taken: </p>
            <Select
              loading={loadingPhotos}
              defaultValue={'all'}
              onChange={timeChange}
              placeholder="Please select">
              {TimePeriodsCustom.map((t) => (
                <Option value={t.value} key={t.value}>
                  {t.label}
                </Option>
              ))}
            </Select>
            {showPicker && (
              <>
                {/*@ts-ignore*/}
                <RangePicker
                  disabled={loadingPhotos}
                  className="pf-rangepicker"
                  onChange={datesChange}
                />
              </>
            )}
          </div>
        </div>
        <div className="pf-filter-container">
          <div className="pf-option">
            <p className="pf-option__label">
              Total Photos:{' '}
              {(projectPhotos ?? { totalFilterCount: 0 }).totalFilterCount}
            </p>
          </div>

          <InputButtonCombo
            label={
              selectedProjectPhotos.length > 0
                ? `Unwatermark the ${
                    selectedProjectPhotos.length > 1
                      ? selectedProjectPhotos.length
                      : ''
                  } Selected Photo${
                    selectedProjectPhotos.length > 1 ? 's' : ''
                  }.`
                : 'Unwatermark Photos by Code...'
            }
            buttonText="Unwatermark"
            value={unwatermarkPhotosCode}
            onClick={onUnwatermarkImages}
            onChange={(e) =>
              setUnwatermarkPhotosCode(e?.target?.value?.trim() || '')
            }
            buttonDisabled={
              selectedProjectPhotos.length <= 0
                ? unwatermarkPhotosCode.length < 5 ||
                  !unwatermarkPhotosCode.startsWith('@')
                : false
            }
            buttonTooltip={
              selectedProjectPhotos.length > 0
                ? undefined
                : "A valid code must start with '@' and be at least 4 characters long."
            }
            inputDisabled={selectedProjectPhotos.length > 0}
            placeholder="Enter..."
          />
          {selectedProjectPhotos.length > 0 && (
            <InputButtonCombo
              label={`Link the ${
                selectedProjectPhotos.length > 1
                  ? selectedProjectPhotos.length
                  : ''
              } Selected Photo${
                selectedProjectPhotos.length > 1 ? 's' : ''
              } to a New Code.`}
              buttonText="Relink"
              value={relinkPhotosCode}
              onClick={onLinkPhotosToNewQRCode}
              onChange={(e) =>
                setRelinkPhotosCode(e?.target?.value?.trim() || '')
              }
              buttonDisabled={
                relinkPhotosCode.length < 5 || !relinkPhotosCode.startsWith('@')
              }
              buttonTooltip="A valid code must start with '@' and be at least 4 characters long."
              placeholder="Enter..."
            />
          )}
          {selectedProjectPhotos.length > 0 && (
            <div className="pf-option">
              <p className="pf-option__label">
                {`Download Selected Photo${
                  selectedProjectPhotos.length > 1 ? 's' : ''
                }`}
                .
              </p>
              <Button type="primary" onClick={handleDownloadSelectedPhotos}>
                Download
              </Button>
            </div>
          )}
        </div>
      </Row>
    </>
  );
}

export default observer(ProjectPhotosFilter);
