import React, { useEffect, useState } from 'react';
import { Layout, Tabs } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import SalesSummaryReports from './SalesSummaryReports';
import SalesPaymentGateway from './SalesPaymentGateway';
import SalesCurrencies from './components/SalesCurrencies';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import LoadingComponent from '../../app/layout/LoadingComponent';
import useQuery from '../../app/common/util/hooks';
import { getActiveSubscriptionId } from '../../app/common/util/common';
import { useParams } from 'react-router-dom';

function Sales() {
  const { userStore, salesStore, subscriptionsStore, templatesStore } =
    useStore();
  const { user } = userStore;
  const {
    selectedFilter,
    loadingGateways,
    loadingTransactions,
    firstLoad,

    fetchTransactions,
    setTransactions,
    fetchPaymentGateways,
  } = salesStore;
  const { fetchAllSubscriptions } = subscriptionsStore;
  const {
    getTemplates,

    getTemplatesBySubscription,
  } = templatesStore;

  const [activeTab, setActiveTab] = useState('1');
  const activeSubscriptionId = getActiveSubscriptionId();
  const query = useQuery();
  const { subId } = useParams<{ subId: string }>();

  useEffect(() => {
    if (!user || !activeSubscriptionId) return;

    fetchTransactions(user.isSysAdmin, {
      ...selectedFilter,
      subscriptionId: user.isSysAdmin
        ? activeSubscriptionId || -1
        : activeSubscriptionId,
    });

    if (!user.isSysAdmin) {
      fetchPaymentGateways(activeSubscriptionId);
    }

    if (!user.isSysAdmin && getTemplates.length === 0) {
      getTemplatesBySubscription(activeSubscriptionId.toString());
    }

    if (user.isSysAdmin) {
      fetchAllSubscriptions();
    }

    const tab = query.get('tab');
    if (tab) {
      if (['1', '2', '3'].includes(tab)) {
        setActiveTab(tab);
      }
    }
  }, [setTransactions, fetchPaymentGateways, activeSubscriptionId, subId]);

  if (loadingGateways || (loadingTransactions && firstLoad))
    return <LoadingComponent content="Loading sales data..." />;

  const { Content } = Layout;
  const { TabPane } = Tabs;
  return (
    <>
      <Layout className="sales">
        <Header className="view-project-header">
          <div>
            <h2>Sales</h2>
          </div>
        </Header>

        <Content>
          <Tabs
            defaultActiveKey="1"
            className="sales-tabs"
            onChange={(e) => setActiveTab(e)}
            activeKey={activeTab}>
            <TabPane key="1" tab="Reports">
              <SalesSummaryReports />
            </TabPane>

            {!(user ?? { isSysAdmin: false }).isSysAdmin && (
              <>
                <TabPane key="2" tab="Payment Gateway">
                  <SalesPaymentGateway />
                </TabPane>

                <TabPane key="3" tab="Currencies">
                  <SalesCurrencies />
                </TabPane>
              </>
            )}
          </Tabs>
        </Content>
      </Layout>
    </>
  );
}

export default observer(Sales);
