import React, { useState } from 'react';
import { Button, Card } from 'antd';
import useQuery from '../../app/common/util/hooks';
import { useStore } from '../../app/stores/store';
import { history } from '../../index';
import { observer } from 'mobx-react-lite';
import { VerifyEmailResponse } from '../../app/models/verifyEmailResponse';

const PAGE_MESSAGE = {
  verify:
    'Please click the button below to verify your email and finish creating your account:',
  verified:
    'Your account has successfully been verified. Please go to the Happy Snappy Lite mobile app to login with your account.',
};

function VerifyEmail() {
  const [error, setError] = useState(false);
  const query = useQuery();
  const { userStore } = useStore();
  const {
    submitting,

    verifyEmail,
  } = userStore;

  const [pageMessage, setPageMessage] = useState<string>(PAGE_MESSAGE.verify);

  // State used to show the verified message for a photographer
  const [verified, setVerified] = useState<boolean>(false);

  async function submit() {
    const token = query.get('token');
    const email = query.get('email');

    const result: VerifyEmailResponse = await verifyEmail(
      token ?? '',
      email ?? ''
    );
    if (result.message.toLowerCase().includes('email confirmed')) {
      if (result.isPhotographer) {
        setVerified(true);
        setPageMessage(PAGE_MESSAGE.verified);
        return;
      }

      history.push('/login');
    } else {
      setError(true);
    }
  }

  return (
    <>
      <div style={{ height: '100vh', display: 'flex' }}>
        <Card className={'verify-email'}>
          <h2>Thank you for creating an account with Happy Snappy Lite!</h2>

          <div className="verify-email-subtitle">{pageMessage}</div>

          {!verified && (
            <Button
              disabled={error}
              type="primary"
              onClick={submit}
              loading={submitting}>
              Verify
            </Button>
          )}

          {error && (
            <div className={'verify-email-error'}>
              There was a problem verifying your email. Either it was an invalid
              attempt or something went wrong.
            </div>
          )}
        </Card>
      </div>
    </>
  );
}

export default observer(VerifyEmail);
