import React from 'react';
import { Button, Form, Input } from 'antd';
import { useStore } from '../../../app/stores/store';
import { ProjectPostDTO } from '../../../app/models/projects/projectPostDTO';
import { observer } from 'mobx-react-lite';
import {
  getActiveSubscriptionId,
  navigate,
} from '../../../app/common/util/common';

function AddProjectForm() {
  const { modalStore, projectStore, userStore } = useStore();

  const { user } = userStore;

  const {
    submitting,

    createProject,
  } = projectStore;

  const activeSubscriptionId = getActiveSubscriptionId();

  async function onFinish(e: any) {
    if (!user || !activeSubscriptionId) return;

    const project: ProjectPostDTO = {
      subscriptionId: activeSubscriptionId,
      name: e.name,
      description: e.description,
    };
    const id = await createProject(project);
    modalStore.closeModal();
    navigate(`/projects/${id}`);
  }

  return (
    <>
      <Form
        className="add-project-form"
        name="addProject"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off">
        <Form.Item
          label="Project name"
          name="name"
          rules={[{ required: true, message: 'Please add a project name' }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: true, message: 'Please add a project description.' },
          ]}>
          <Input.TextArea />
        </Form.Item>

        <Form.Item className="add-project-form-buttons">
          <Button
            disabled={submitting}
            className="yellow-ant-btn"
            onClick={modalStore.closeModal}>
            Cancel
          </Button>

          <Button loading={submitting} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default observer(AddProjectForm);
