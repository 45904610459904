import {
  Card,
  Form,
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  Space,
  Input,
} from 'antd';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SaleStatus, SaleType } from '../../../app/common/options/enums';
import {
  Currencies,
  IStandardOption,
  PaymentProviders,
  SaleStatuses,
  SaleTypes,
  TimePeriodsCustom,
} from '../../../app/common/options/selectOptions';
import { yyyymmdd } from '../../../app/common/util/date';
import { Template } from '../../../app/models/templates/template';
import { useStore } from '../../../app/stores/store';
import { getActiveSubscriptionId } from '../../../app/common/util/common';

interface FormValues {
  dateRange: [moment.Moment, moment.Moment];
  template: number;
  status: SaleStatus;
  provider: string;
  currency: string;
  saleType: SaleType;
  subscription: number;
  albumCode?: string;
}

const { Option } = Select;
interface DateRange {
  startDate: string;
  endDate: string;
}

const DEFAULT_START_DATE = yyyymmdd(
  new Date(new Date().setMonth(new Date().getMonth() - 3))
);

function ReportFilters() {
  const { salesStore, userStore, subscriptionsStore, templatesStore } =
    useStore();
  const {
    selectedFilter,

    setSelectedFilter,
    fetchTransactions,
  } = salesStore;
  const { user } = userStore;
  const { allSubscriptions } = subscriptionsStore;
  const { getTemplates } = templatesStore;
  const activeSubscriptionId = getActiveSubscriptionId();

  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: DEFAULT_START_DATE,
    endDate: yyyymmdd(new Date()),
  });

  const [form] = Form.useForm();
  async function filter(e: FormValues) {
    if (!user || !activeSubscriptionId) return;

    const subscriptionId = !user.isSysAdmin
      ? user.subscriptionId
      : e.subscription ?? -1;

    const albumCode = e?.albumCode?.trim()
      ? e.albumCode.trim().startsWith('@')
        ? e.albumCode.trim()
        : `@${e.albumCode.trim()}`
      : null;

    setSelectedFilter({
      currency: e.currency,
      endDate: dateRange.endDate,
      guidSearch: null,
      paymentProvider: e.provider,
      saleType: e.saleType,
      startDate: dateRange.startDate,
      status: e.status,
      subscriptionId,
      templateId: e.template ?? -1,
      albumCode: albumCode,
    });

    await fetchTransactions(user.isSysAdmin);
  }

  function reset() {
    const { isSysAdmin, subscriptionId } = user ?? {
      isSysAdmin: false,
      subscriptionId: -1,
    };

    setSelectedFilter({
      currency: 'All',
      endDate: yyyymmdd(),
      guidSearch: '',
      paymentProvider: 'All',
      saleType: -1,
      startDate: '20190101',
      status: -1,
      subscriptionId: isSysAdmin
        ? getActiveSubscriptionId() ?? -1
        : subscriptionId,
      templateId: -1,
      albumCode: null,
    });
    fetchTransactions((user ?? { isSysAdmin: false }).isSysAdmin, {
      currency: 'All',
      endDate: yyyymmdd(),
      guidSearch: '',
      paymentProvider: 'All',
      saleType: -1,
      startDate: '20190101',
      status: -1,
      subscriptionId: isSysAdmin
        ? getActiveSubscriptionId() ?? -1
        : subscriptionId,
      templateId: -1,
      albumCode: null,
    });
    form.setFieldsValue({
      dateRange: [
        moment(selectedFilter.startDate),
        moment(selectedFilter.endDate),
      ],
      template: -1,
      status: -1,
      provider: 'All',
      currency: 'All',
      saleType: -1,
      subscription: getActiveSubscriptionId() ?? -1,
      albumCode: '',
    });
  }

  async function timeSelected(e: string) {
    if (showPicker) setShowPicker(false);
    const lowerDate = new Date();
    let startDate = DEFAULT_START_DATE;
    switch (e) {
      case 'today':
        startDate = yyyymmdd();
        break;

      case '7_days':
        lowerDate.setDate(lowerDate.getDate() - 7);
        startDate = yyyymmdd(lowerDate);
        break;

      case '14_days':
        lowerDate.setDate(lowerDate.getDate() - 14);
        startDate = yyyymmdd(lowerDate);
        break;

      case '30_days':
        lowerDate.setDate(lowerDate.getDate() - 30);
        startDate = yyyymmdd(lowerDate);
        break;

      case 'custom':
        setShowPicker(true);
        setDateRange({
          startDate: DEFAULT_START_DATE,
          endDate: yyyymmdd(new Date()),
        });
        return;

      default:
      case 'all':
        startDate = '20190101';
        break;
    }

    setDateRange({
      endDate: yyyymmdd(new Date()),
      startDate,
    });
  }

  async function selectedDates(e: any) {
    setDateRange({
      startDate: e[0].format('YYYYMMDD'),
      endDate: e[1].format('YYYYMMDD'),
    });
  }

  useEffect(() => {
    if (selectedFilter.subscriptionId !== getActiveSubscriptionId()) {
      reset();
    }
  }, []);

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  return (
    <>
      <Card title="Report Filters">
        <Form
          labelAlign="left"
          labelWrap={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            dateRange: [
              moment(selectedFilter.startDate),
              moment(selectedFilter.endDate),
            ],
            template: selectedFilter.templateId,
            status: selectedFilter.status,
            provider: selectedFilter.paymentProvider,
            currency: selectedFilter.currency,
            saleType: selectedFilter.saleType,
            subscription: selectedFilter.subscriptionId,
            timePeriod: 'all',
            albumCode: selectedFilter.albumCode,
          }}
          form={form}
          onFinish={(e) => filter(e)}>
          <Row gutter={24}>
            <Col span={12}>
              {(user ?? { isSysAdmin: false }).isSysAdmin ? (
                <Form.Item name="subscription" label="Select Account">
                  <Select>
                    <Option value={-1}>All</Option>
                    {allSubscriptions
                      .sort(({ accountName: aName }, { accountName: bName }) =>
                        aName.toLowerCase() > bName.toLowerCase() ? 1 : -1
                      )
                      .map((s) => (
                        <Option key={s.id} value={s.id}>
                          {s.accountName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item name="template" label="Template: ">
                  <Select disabled={(user ?? { isSysAdmin: false }).isSysAdmin}>
                    <Option value={-1}>All</Option>
                    {getTemplates.map((t: Template) => (
                      <Option key={t.id} value={t.id}>
                        {t.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>

            <Col span={12}>
              <Form.Item name="status" label="Status: ">
                <Select>
                  <Option value={-1}>All</Option>
                  {SaleStatuses.map((status: IStandardOption<SaleStatus>) => (
                    <Option key={status.value} value={status.value}>
                      {status.display}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="provider" label="Provider: ">
                <Select>
                  <Option value={'All'}>All</Option>
                  {PaymentProviders.map((provider: IStandardOption<string>) => (
                    <Option key={provider.value} value={provider.value}>
                      {provider.display}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="currency" label="Currency: ">
                <Select>
                  <Option value={'All'}>All</Option>
                  {Currencies.map((provider: IStandardOption<string>) => (
                    <Option key={provider.value} value={provider.value}>
                      {provider.display}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="saleType" label="Sale Type: ">
                <Select>
                  <Option value={-1}>All</Option>
                  {SaleTypes.map((value: IStandardOption<SaleType>) => (
                    <Option key={value.value} value={value.value}>
                      {value.display}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="albumCode" label="Album Code: ">
                <Input placeholder="Enter Album Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="timePeriod"
                label="Select time: "
                className="report-filter-dates">
                <Select placeholder="Please select" onChange={timeSelected}>
                  {TimePeriodsCustom.map((t) => (
                    <Option value={t.value} key={t.value}>
                      {t.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              {showPicker && (
                <Form.Item name="dateRange" className="report-filter-dates">
                  {/*@ts-ignore*/}
                  <RangePicker
                    onChange={selectedDates}
                    className="report-filter-dates"
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
        <Space>
          <Button onClick={form.submit} type="primary">
            Filter
          </Button>

          <Button type="text" onClick={reset}>
            Reset
          </Button>
        </Space>
      </Card>
    </>
  );
}

export default observer(ReportFilters);
