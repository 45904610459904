import { makeAutoObservable, runInAction } from 'mobx';
import { SubscriptionDto } from '../models/subscriptions/subscriptionDto';
import agent from '../api/Agent';
import { AppUser } from '../models/appUser';
import { SubscriptionFullDto } from '../models/subscriptions/subscriptionFullDto';
import { AllSubscriptionItemDto } from '../models/subscriptions/allSubscriptionItem.dto';
import { EntityStatus } from '../common/options/enums';
import { SubscriptionAnalyticsDto } from '../models/subscriptions/subscriptionAnalytics.dto';
import { EditSubscriptionBucket } from 'src/app/models/subscriptions/editSubscriptionStorageBucket';
import { encryptDecryptKey } from 'src/utils/helpers';

export class SubscriptionsStore {
  submitting: boolean = false;
  loading: boolean = false;

  subscriptionUsers: AppUser[] = [];
  subscriptionsRegistry = new Map<number, SubscriptionFullDto>();

  selectedSubscription: SubscriptionFullDto | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  create = async (dto: SubscriptionDto) => {
    try {
      this.setSubmitting(true);
      await agent.Subscriptions.create(dto);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  fetch = async (
    id: number,
    params?: { [key: string]: string | undefined }
  ) => {
    try {
      this.setLoading(true);

      const result = await agent.Subscriptions.fetch(id, params);
      this.setSelectedSubscription(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  setSelectedSubscription = (value: SubscriptionFullDto | null) => {
    this.selectedSubscription = value;
  };

  // Get all the users linked to a subscription
  getUsers = async (id: number) => {
    try {
      this.setLoading(true);

      const result = await agent.Subscriptions.getUsers(id);
      this.setSubscriptionUsers(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  setSubscriptionUsers = (value: AppUser[]) => {
    this.subscriptionUsers = value;
  };

  removeUser = (id: number) => {
    const index = this.subscriptionUsers.findIndex(
      (u) => u.id === id || u.userId === id
    );
    this.subscriptionUsers.splice(index, 1);
  };

  listSubscriptions = async () => {
    try {
      const result = await agent.Subscriptions.list();
      runInAction(() => {
        result.forEach((subscription: SubscriptionFullDto) => {
          this.subscriptionsRegistry.set(subscription.id, subscription);
        });
      });
    } catch (error) {
      throw error;
    }
  };

  get subscriptions() {
    return Array.from(this.subscriptionsRegistry.values());
  }

  pushUser = (user: AppUser) => {
    this.subscriptionUsers.push(user);
  };

  changeSubscription = async (id: number, stripeId?: string) => {
    try {
      const result = await agent.Subscriptions.changePackage(id, stripeId);
    } catch (error) {
      console.error(error);
    }
  };

  requestSubscriptionPackageChange = async (id: number) => {
    try {
      const result = await agent.Subscriptions.requestSubscriptionPackageChange(
        id
      );
    } catch (error) {
      console.error(error);
    }
  };
  updateSubscriptionStorageBucket = async (
    id: number,
    dto: EditSubscriptionBucket
  ) => {
    try {
      const result = await agent.Subscriptions.updateStorageBucket(id, dto);
      return result;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  /**
   * System Admin
   */

  loadingSubscriptions: boolean = false;
  setLoadingSubscriptions = (value: boolean) => {
    this.loadingSubscriptions = value;
  };

  allSubscriptionsRegistry = new Map<number, AllSubscriptionItemDto>();
  subscriptionAnalytics: SubscriptionAnalyticsDto | null = null;

  fetchAllSubscriptions = async () => {
    try {
      this.setLoadingSubscriptions(true);

      const result = await agent.Subscriptions.listAll();
      result.forEach((value: AllSubscriptionItemDto) => {
        this.setSubscription(value);
      });
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingSubscriptions(false);
    }
  };

  private setSubscription = (value: AllSubscriptionItemDto) => {
    this.allSubscriptionsRegistry.set(value.id, value);
  };

  private removeSubscription = (id: number) => {
    this.allSubscriptionsRegistry.delete(id);
  };

  get allSubscriptions() {
    return Array.from(this.allSubscriptionsRegistry.values());
  }

  deactivate = async (id: number, status: EntityStatus) => {
    try {
      this.setSubmitting(true);

      await agent.Subscriptions.deactivate(id, status);
      this.removeSubscription(id);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  loadingAnalytics: boolean = false;
  setLoadingAnalytics = (value: boolean) => {
    this.loadingAnalytics = value;
  };

  fetchAnalytics = async (id: number) => {
    try {
      this.setLoadingAnalytics(true);

      const result = await agent.Subscriptions.analytics(id);
      this.setSubscriptionAnalytics(result);
    } catch (error) {
      throw error;
    } finally {
      this.setLoadingAnalytics(false);
    }
  };

  setSubscriptionAnalytics = (value: SubscriptionAnalyticsDto | null) => {
    this.subscriptionAnalytics = value;
  };
}
