import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { ItemTypes } from '../../../app/common/options/itemTypes';
import { useStore } from '../../../app/stores/store';
import { TemplateImageLayerFull } from '../../../app/models/templates/templateImageLayerFull';
import { Button } from 'antd';

interface Props {
  layer: TemplateImageLayerFull;
  index: number;
  moveLayer: (dragIndex: number, hoverIndex: number, objectId: string) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

function TemplateLayer(props: Props) {
  const { layer, index, moveLayer } = props;

  const { templatesStore, fabricStore } = useStore();
  const {
    selectedImage,

    setSelectedImage,
    removeLayer,
  } = templatesStore;
  const { removeObject, selectObject } = fabricStore;
  const ref = useRef<HTMLDivElement>(null);

  const [classes, setClasses] = useState('image-layer');
  useEffect(() => {
    if (
      selectedImage &&
      ((selectedImage.id === 0 && selectedImage.newId === layer.newId) ||
        (selectedImage.id !== 0 && selectedImage.id === layer.id))
    ) {
      setClasses('image-layer image-layer-active');
    } else {
      setClasses('image-layer');
    }
  }, [layer.id, layer.newId, selectedImage]);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.IMAGE_LAYER,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveLayer(dragIndex, hoverIndex, item.id);
      item.index = hoverIndex;
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IMAGE_LAYER,
    item: () => {
      return { id: layer.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  function selectLayer() {
    setSelectedImage(layer);
    selectObject(layer.newId ? layer.newId : layer.id.toString());
  }

  function deleteLayer() {
    removeObject(layer.newId ? layer.newId : layer.id.toString());
    removeLayer(layer.newId ? layer.newId : layer.id.toString());
  }

  drag(drop(ref));
  return (
    <>
      <div
        ref={ref}
        data-handler-id={handlerId}
        className={classes}
        onClick={selectLayer}>
        <img
          className="image-layer-thumb"
          src={layer.thumbUrl}
          alt="layer_thumb"
        />
        <Button
          type="text"
          danger={true}
          onClick={(e) => {
            e.stopPropagation();
            deleteLayer();
          }}>
          Remove
        </Button>
        {/*<img onClick={e => { e.stopPropagation(); deleteLayer() }} className="image-layer-x" src='/assets/images/x_red.svg' alt='red_x' />*/}
        <img
          className="image-layer-burger"
          src="/assets/images/burger.svg"
          alt="burger"
        />
      </div>
    </>
  );
}

export default observer(TemplateLayer);
