import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Skeleton, Space } from 'antd';
import { useStore } from '../../../app/stores/store';
import TemplateCard from './TemplateCard';
import EmptyList from '../../../app/layout/EmptyList';
import { getActiveSubscriptionId } from '../../../app/common/util/common';

interface Props {
  search: string;
}

function TemplatesListArchived(props: Props) {
  const { templatesStore, userStore } = useStore();
  const { search } = props;

  const { fetchArchivedTemplates, loading, getArchivedTemplates } =
    templatesStore;

  const { user } = userStore;
  const activeSubscriptionId = getActiveSubscriptionId();

  useEffect(() => {
    if (!user || !activeSubscriptionId) return;

    fetchArchivedTemplates(activeSubscriptionId.toString());
  }, [fetchArchivedTemplates, activeSubscriptionId]);

  if (getArchivedTemplates.length < 1)
    return (
      <EmptyList
        message="You have not archived any templates"
        hideButton={true}
        buttonAction={() => {}}
        buttonText=""
      />
    );

  return (
    <>
      <Space size={19} wrap>
        {getArchivedTemplates.length < 1 && loading && (
          <>
            <div className="skel-loader">
              <Skeleton.Button active />
              <Skeleton
                active
                paragraph={{ rows: 2 }}
                style={{ marginLeft: '15px' }}
              />
            </div>

            <div className="skel-loader">
              <Skeleton.Button active />
              <Skeleton
                active
                paragraph={{ rows: 2 }}
                style={{ marginLeft: '15px' }}
              />
            </div>
          </>
        )}

        {getArchivedTemplates &&
          getArchivedTemplates
            .filter((t) => t.name?.toLowerCase().includes(search.toLowerCase()))
            .map((t) => (
              <TemplateCard template={t} key={t.id} archived={true} />
            ))}
      </Space>
    </>
  );
}

export default observer(TemplatesListArchived);
