import { makeAutoObservable } from 'mobx';
import Agent from '../api/Agent';
import { Currency } from '../models/currency';
import { TemplateCurrencyDTO } from '../models/templates/templateCurrencyDTO';
import agent from '../api/Agent';
import { toast } from 'react-toastify';

const Currencies = Agent.Currencies;

export class CurrencyStore {
  currencyRegistry = new Map<number, Currency>();
  allowedCurrencies = new Map<number, TemplateCurrencyDTO>();
  subscriptionCurrenciesRegistry = new Map<number, TemplateCurrencyDTO>();

  loading: boolean = false;
  submitting: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchAllowedCurrencies = async (subscription: number) => {
    try {
      this.setLoading(true);
      const result = await Currencies.allowedCurrencies(subscription);
      result.subscriptionCurrencyDtos.forEach((c) =>
        this.setAllowedCurrency(c)
      );
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  get getAllowedCurrencies() {
    return Array.from(this.allowedCurrencies.values());
  }

  get currencies() {
    return Array.from(this.currencyRegistry.values());
  }

  private setAllowedCurrency = (currency: TemplateCurrencyDTO) => {
    this.allowedCurrencies.set(currency.currencyId, currency);
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  fetchSubscriptionCurrencies = async (id: number) => {
    try {
      this.setLoading(true);

      const result = await agent.Currencies.subscriptionCurrencies(id);
      result.subscriptionCurrencyDtos.forEach((currency) => {
        this.addCurrency(currency);
      });
    } catch (error) {
      throw error;
    } finally {
      this.setLoading(false);
    }
  };

  addCurrency = (value: TemplateCurrencyDTO) => {
    this.subscriptionCurrenciesRegistry.set(value.currencyId, value);
  };

  get subscriptionCurrencies() {
    return Array.from(this.subscriptionCurrenciesRegistry.values());
  }

  changeDefaultCurrency = (currencyId: number) => {
    this.subscriptionCurrenciesRegistry.forEach((value, key) => {
      value.isDefault = value.currencyId === currencyId;
    });
  };

  updateCurrencies = async (dto: any, id: number) => {
    try {
      this.setSubmitting(true);

      await agent.Currencies.updateSubscriptionCurrencies(dto, id);
      toast.success('Saved subscription currencies!');
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };
}
