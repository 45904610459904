import { Select } from 'antd';
import {
  Currencies,
  IStandardOption,
} from 'src/app/common/options/selectOptions';

type Props = {
  onChange: (value: string) => void;
  value: string;
};
const { Option } = Select;

const CurrencySelectDropdown = ({ onChange, value }: Props) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      className="currency-select-dropdown">
      {Currencies.map((provider: IStandardOption<string>) => (
        <Option key={provider.value} value={provider.value}>
          {provider.display}
        </Option>
      ))}
    </Select>
  );
};

export default CurrencySelectDropdown;
