import { makeAutoObservable, runInAction } from 'mobx';
import { history } from '../..';
import agent from '../api/Agent';
import { User, UserFormValues } from '../models/user';
import { store } from './store';
import { LoginDTO } from '../models/LoginDTO';
import { AxiosError } from 'axios';

export default class UserStore {
  user: User | null = null;
  fbAccessToken: string | null | undefined = null;
  fbLoading = false;
  refreshTokenTimeout: any;

  loginLoading = false;
  submitting = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isLoggedIn() {
    return !!this.user;
  }

  setSubmitting = (value: boolean) => {
    this.submitting = value;
  };

  login = async (creds: LoginDTO) => {
    try {
      this.setLoginLoading(true);
      const user = await agent.Account.login(creds);
      store.commonStore.setToken(user.token);
      // this.startRefreshTokenTimer(user);
      runInAction(() => (this.user = user));
      history.push('/dashboard');
      store.modalStore.closeModal();
    } catch (error) {
      const ex = error as AxiosError;
      if (ex.response && typeof ex.response.data === 'string') {
        return ex.response.data;
      }
      throw error;
    } finally {
      this.setLoginLoading(false);
    }
  };

  setLoginLoading = (value: boolean) => {
    this.loginLoading = value;
  };

  logout = async () => {
    history.push('/login');
    await agent.Account.logout();
    store.commonStore.setToken(null);
    window.localStorage.removeItem('jwt');
    this.user = null;
    window.location.reload();
  };

  getUser = async () => {
    try {
      const user = await agent.Account.current();
      store.commonStore.setToken(user.token);
      runInAction(() => (this.user = user));
      // this.startRefreshTokenTimer(user);
      return user;
    } catch (error) {
      console.log(error);
    }
  };

  register = async (creds: UserFormValues) => {
    try {
      await agent.Account.register(creds);
      history.push(`/account/registerSuccess?email=${creds.email}`);
      store.modalStore.closeModal();
    } catch (error) {
      throw error;
    }
  };

  setImage = (image: string) => {
    if (this.user) this.user.image = image;
  };

  verifyEmail = async (token: string, email: string) => {
    try {
      this.setSubmitting(true);
      return await agent.Account.verifyEmail(token, email);
    } catch (error) {
      throw error;
    } finally {
      this.setSubmitting(false);
    }
  };

  setDisplayName = (name: string) => {
    if (this.user) this.user.displayName = name;
  };

  getFacebookLoginStatus = async () => {
    window.FB.getLoginStatus((response) => {
      if (response.status === 'connected') {
        this.fbAccessToken = response.authResponse.accessToken;
      }
    });
  };

  // facebookLogin = () => {
  //     this.fbLoading = true;
  //     const apiLogin = (accessToken: string) => {
  //         agent.Account.fbLogin(accessToken).then(user => {
  //             store.commonStore.setToken(user.token);
  //             this.startRefreshTokenTimer(user);
  //             runInAction(() => {
  //                 this.user = user;
  //                 this.fbLoading = false;
  //             })
  //             history.push('/activities');
  //         }).catch(error => {
  //             console.log(error);
  //             runInAction(() => this.fbLoading = false);
  //         })
  //     }
  //     if (this.fbAccessToken) {
  //         apiLogin(this.fbAccessToken);
  //     } else {
  //         window.FB.login(response => {
  //             apiLogin(response.authResponse.accessToken);
  //         }, { scope: 'public_profile,email' })
  //     }
  //
  // }

  refreshToken = async () => {
    // this.stopRefreshTokenTimer();
    try {
      const user = await agent.Account.refreshToken();
      runInAction(() => (this.user = user));
      store.commonStore.setToken(user.token);
      // this.startRefreshTokenTimer(user);
      return user.token;
    } catch (error) {
      console.log(error);
    }
  };
  //this will cause the token to be refreshed every 30 sec
  private startRefreshTokenTimer(user: User) {
    const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
