import { Button, Radio, Tooltip } from 'antd';
import React, { useState } from 'react';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { useStore } from '../../../../app/stores/store';
import { observer } from 'mobx-react-lite';

function TemplatePreviewToolbar() {
  const { fabricStore } = useStore();
  const { resetZoom, canvasZoomIn, canvasZoomOut, canvas, setDragging } =
    fabricStore;

  const [curZoom, setCurZoom] = useState(100);
  const [pointerStyle, setPointerStyle] = useState<'pointer' | 'navigation'>(
    'pointer'
  );

  function zoomOut() {
    canvasZoomOut();
    const zoom = canvas!.getZoom() * 100;
    setCurZoom(zoom);
  }

  function zoomIn() {
    canvasZoomIn();
    const zoom = canvas!.getZoom() * 100;
    setCurZoom(zoom);
  }

  function reset() {
    resetZoom();
    setCurZoom(100);
    setDragging(false);
    setPointerStyle('pointer');
  }

  return (
    <>
      <div className="template-preview-toolbar">
        <Radio.Group
          defaultValue="pointer"
          buttonStyle="solid"
          value={pointerStyle}
          onChange={(e) => setPointerStyle(e.target.value)}>
          <Tooltip title="Select">
            <Radio.Button value="pointer" onClick={() => setDragging(false)}>
              <img
                alt="cursor"
                style={{ height: '20px' }}
                src="/assets/images/cursor.svg"
              />
            </Radio.Button>
          </Tooltip>
          <Tooltip title="Move and pan">
            <Radio.Button
              disabled={curZoom === 100}
              value="navigation"
              onClick={() => setDragging(true)}>
              <img
                alt="hand"
                style={{ height: '15px', marginLeft: '2px' }}
                src="/assets/images/hand-grab.svg"
              />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>

        <Button.Group className="template-preview-toolbar-zoom">
          <Tooltip title="Zoom out">
            <Button onClick={zoomOut}>
              <ZoomOutOutlined />
            </Button>
          </Tooltip>
          <Button style={{ width: '70px' }}>
            {canvas && Math.trunc(canvas!.getZoom() * 100)}%
          </Button>
          <Tooltip title="Reset zoom and position">
            <Button onClick={reset} className="fullscreen-button">
              <img src="/assets/images/fullscreen.svg" alt="fullscreen icon" />
            </Button>
          </Tooltip>
          <Tooltip title="Zoom in">
            <Button onClick={zoomIn}>
              <ZoomInOutlined />
            </Button>
          </Tooltip>
        </Button.Group>
      </div>
    </>
  );
}

export default observer(TemplatePreviewToolbar);
