import { makeAutoObservable, reaction } from 'mobx';
import { ServerError } from '../models/serverError';

export default class CommonStore {
  error: ServerError | null = null;
  token: string | null = window.localStorage.getItem('jwt');
  appLoaded = false;
  browser: string = '';

  constructor() {
    makeAutoObservable(this);
    //reactions it only runs when there is a change to the value of token
    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem('jwt', token);
        } else {
          window.localStorage.removeItem('jwt');
        }
      }
    );
  }

  setServerError = (error: ServerError) => {
    this.error = error;
  };

  setToken = (token: string | null) => {
    this.token = token;
  };
  setAppLoaded = () => {
    this.appLoaded = true;
  };

  setBrowser = (value: string) => {
    this.browser = value;
  };

  get isSafari() {
    return this.browser === 'Safari' || this.browser === 'Mobile Safari';
  }
}
