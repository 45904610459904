import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../stores/store';
import { Modal } from 'antd';

// interface Props {
//     buttonContent: string,
//     headerContent:string,
//     body: JSX.Element | null;{ buttonContent,headerContent, body }: Props
// }

export default observer(function ModalContainer() {
  const { modalStore } = useStore();
  const {
    openState,
    bodyContent,
    modalTitle,
    removeFooter,
    className,
    disableClose,
  } = modalStore;
  const footerProp = removeFooter ? { footer: null } : {};
  return (
    <>
      <Modal
        centered
        onCancel={disableClose ? undefined : modalStore.closeModal}
        closable={!disableClose}
        className={className}
        title={modalTitle}
        visible={openState}
        onOk={modalStore.closeModal}
        {...footerProp}>
        {bodyContent}
      </Modal>
    </>
  );
});
