import React from 'react';
import { Button, Col, Layout, Modal, Row } from 'antd';
import PopupMenu from './PopupMenu';
import PopupPreview from './PopupPreview';
import { useStore } from '../../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';
import { EntityStatus } from '../../../../app/common/options/enums';
import ProjectValidationModal from '../../../projects/components/ProjectValidationModal';

function TemplatePopup() {
  const { templatesStore, projectStore, navigationStore } = useStore();
  const {
    selectedDataCollection,
    disablePopupSave,
    submittingDataCollection,

    updateDataCollection,
    startTemplateDataCollection,
    stopTemplateDataCollection,
    setDataCollection,
  } = templatesStore;
  const { selectedProject, changeMainValue } = projectStore;
  const location = useLocation();

  function save() {
    if (!selectedDataCollection) return;

    updateDataCollection(selectedDataCollection);
  }

  function changeStatus() {
    if (!selectedDataCollection) return;
    let isActive = selectedDataCollection.isActive;
    if (selectedDataCollection.isActive) {
      stopTemplateDataCollection(selectedDataCollection.templateId!);
      isActive = false;
    } else {
      startTemplateDataCollection(selectedDataCollection.templateId!);
      isActive = true;
    }
    setDataCollection({
      ...selectedDataCollection,
      isActive,
    });
  }

  function removeDataCollection() {
    if (!selectedDataCollection || !selectedProject) return;

    // if (selectedProject.status === EntityStatus.Active) {
    //   Modal.info({
    //     content: <ProjectValidationModal issues={[]} message='Please deactivate the project before trying to remove the popup.'/>,
    //     className: 'project-validation-modal'
    //   });
    //   return;
    // }

    updateDataCollection(
      {
        ...selectedDataCollection,
        isMain: false,
      },
      selectedProject.id.toString()
    );
    changeMainValue(selectedDataCollection.templateId!, 'popup', false);
    setDataCollection(null);
  }

  if (!selectedDataCollection) return <></>;

  const { Header, Content } = Layout;
  return (
    <>
      <Layout className="project-popup">
        <Header>
          <h3 className="tab-header">Incentive Pop-up</h3>

          <div style={{ display: 'flex', marginLeft: 'auto' }}>
            {location.pathname.includes('projects') && (
              <div style={{ display: 'flex', height: 'fit-content' }}>
                <div
                  style={{
                    height: 'fit-content',
                    margin: 'auto 10px',
                    lineHeight: '32px',
                  }}>
                  <Link
                    to={`${navigationStore.baseUrl}/templates/${selectedDataCollection.templateId}?tab=3`}>
                    Click here
                  </Link>
                  , to edit the popup.
                </div>
                <Button
                  loading={submittingDataCollection}
                  style={{ marginRight: '20px' }}
                  type="primary"
                  danger
                  onClick={removeDataCollection}>
                  Remove from project
                </Button>
              </div>
            )}
            <Button
              onClick={changeStatus}
              loading={submittingDataCollection}
              className="project-popup-collection-button"
              type="primary"
              danger={selectedDataCollection!.isActive}>
              {selectedDataCollection!.isActive
                ? 'Stop Data Collection'
                : 'Start Data Collection'}
            </Button>
            {!location.pathname.includes('projects') && (
              <Button
                disabled={disablePopupSave}
                loading={submittingDataCollection}
                onClick={save}
                type="primary">
                Save
              </Button>
            )}
          </div>
        </Header>
      </Layout>

      <Content>
        <Row>
          <Col span={16}>
            <PopupPreview />
          </Col>

          <Col span={8}>
            <PopupMenu />
          </Col>
        </Row>
      </Content>
    </>
  );
}

export default observer(TemplatePopup);
