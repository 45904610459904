import { Col, Row, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../app/stores/store';
import { fabric } from 'fabric';

function TemplateSelectedLayer() {
  const { templatesStore, fabricStore } = useStore();
  const {
    selectedImage,
    templateImages,
    imageSetOrientation,

    updateLayerPosition,
  } = templatesStore;
  const { updateObjectPosition, getObject } = fabricStore;

  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    if (!selectedImage) return;

    setPosition({
      top: selectedImage.top * 1748,
      left: selectedImage.left * 2480,
    });
  }, [setPosition, selectedImage]);

  function snapObject(location: string) {
    const fabricObj = getObject(
      selectedImage!.newId ?? selectedImage!.id.toString()
    ) as fabric.Image;

    let left =
      (imageSetOrientation === 'landscape' ? 2480 : 1748) -
      fabricObj.width! * fabricObj.scaleX!;
    let top =
      (imageSetOrientation === 'landscape' ? 1748 : 2480) -
      fabricObj.height! * fabricObj.scaleY!;

    switch (location) {
      case 'topLeft':
        updateObjectPosition(
          selectedImage!.newId ?? selectedImage!.id.toString(),
          0,
          0
        );
        updateLayerPosition(0, 0);
        break;

      case 'bottomRight':
        updateObjectPosition(
          selectedImage!.newId ?? selectedImage!.id.toString(),
          left,
          top
        );
        updateLayerPosition(top, left);
        break;

      case 'topRight':
        updateObjectPosition(
          selectedImage!.newId ?? selectedImage!.id.toString(),
          left,
          0
        );
        updateLayerPosition(0, left);
        break;

      case 'bottomLeft':
        updateObjectPosition(
          selectedImage!.newId ?? selectedImage!.id.toString(),
          0,
          top
        );
        updateLayerPosition(top, 0);
        break;
    }
  }

  return (
    <>
      <Row className="snap-box">
        <Row className="snap-box-row">
          <Tooltip title="Snap top left">
            <div
              className="snap-box-col"
              onClick={() => snapObject('topLeft')}
            />
          </Tooltip>
          <Tooltip title="Snap top right">
            <div
              className="snap-box-col"
              onClick={() => snapObject('topRight')}
            />
          </Tooltip>
        </Row>
        <Row className="snap-box-row">
          <Tooltip title="Snap bottom left">
            <div
              className="snap-box-col"
              onClick={() => snapObject('bottomLeft')}
            />
          </Tooltip>
          <Tooltip title="Snap bottom right">
            <div
              className="snap-box-col"
              onClick={() => snapObject('bottomRight')}
            />
          </Tooltip>
        </Row>
      </Row>

      <Row className="selected-layer">
        <Col span={8} className="selected-layer-image">
          <img
            style={{ height: '65px', width: '65px', objectFit: 'contain' }}
            src={selectedImage!.imageUrl}
            alt="thumbnail-preview"
          />
        </Col>

        <Col span={16} className="alignment-column">
          <Row>
            <p>Top</p>
            <div className="position-value">
              {Math.round(
                selectedImage!.top * (templateImages!.isPortrait ? 2480 : 1748)
              )}
            </div>
          </Row>
          <Row>
            <p>Left</p>
            <div className="position-value">
              {Math.round(
                selectedImage!.left * (templateImages!.isPortrait ? 1748 : 2480)
              )}
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default observer(TemplateSelectedLayer);
