import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import QRCodesSummary from './components/QrCodesSummary';
import QrCodesHistory from './components/QrCodesHistory';
import QrCodesNewBundles from './components/QrCodesNewBundles';
import { useStore } from '../../app/stores/store';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import useQuery from '../../app/common/util/hooks';
import { IGetQuery } from '../../app/api/Agent';
import { getActiveSubscriptionId } from '../../app/common/util/common';

function QRCodes() {
  const { templatesStore, qrCodeStore, userStore } = useStore();
  const {
    getTemplates,
    loading: loadingTemplates,

    getTemplatesBySubscription,
  } = templatesStore;
  const {
    loadingHistory,
    loadingSummary,

    fetchQrCodeSummary,
    fetchHistory,
  } = qrCodeStore;
  const { user } = userStore;

  const query = useQuery();
  const activeSubscriptionId = getActiveSubscriptionId();

  useEffect(() => {
    if (!user || !activeSubscriptionId) return;

    if (!user.isSysAdmin && getTemplates.length === 0) {
      getTemplatesBySubscription(activeSubscriptionId?.toString());
    }

    let params: IGetQuery = {
      subscriptionId: activeSubscriptionId?.toString(),
    };
    if (user.isSysAdmin) {
      params = {
        accountId: user?.subscriptionId.toString(),
        subscriptionId: activeSubscriptionId?.toString(),
      };
    }

    fetchQrCodeSummary(params);

    fetchHistory(activeSubscriptionId, user.isSysAdmin);

    const tab = query.get('tab');
    if (tab && (tab ?? '').trim() !== '') {
      if (['1', '2', '3'].includes(tab)) setActiveTab(tab);
    }
  }, [
    getTemplatesBySubscription,
    fetchQrCodeSummary,
    fetchHistory,
    activeSubscriptionId,
  ]);

  const [activeTab, setActiveTab] = useState('1');

  if (loadingTemplates || loadingHistory || loadingSummary)
    return <LoadingComponent content="Loading QR Code summary..." />;

  const { TabPane } = Tabs;
  return (
    <>
      <Layout className="qr-codes">
        <Header className="view-project-header">
          <div>
            <h2>QR Codes</h2>
          </div>
        </Header>

        <Content>
          <Tabs
            defaultActiveKey="1"
            onChange={(e) => setActiveTab(e)}
            activeKey={activeTab}
            className="qr-codes-tabs">
            <TabPane key="1" tab="Summary">
              <QRCodesSummary changeTab={(e: string) => setActiveTab(e)} />
            </TabPane>

            <TabPane key="2" tab="Bundles">
              <QrCodesNewBundles changeTab={(e: string) => setActiveTab(e)} />
            </TabPane>

            <TabPane key="3" tab="History">
              <QrCodesHistory changeTab={(e: string) => setActiveTab(e)} />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </>
  );
}

export default observer(QRCodes);
