import { observer } from 'mobx-react-lite';
import { Redirect, Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import ModalContainer from '../common/modals/ModalContainer';
import { useStore } from '../stores/store';
import Login from '../../features/user/Login';
import ForgotPassword from '../../features/user/ForgotPassword';
import DashboardRoutes from './navigation/DashboardRoutes';
import RegisterCompany from '../../features/user/RegisterCompany';
import VerifyEmail from '../../features/user/VerifyEmail';
import {
  Chart,
  ArcElement,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from 'chart.js';
import PaygateRedirect from '../common/paygate/PaygateRedirect';
import { UAParser } from 'ua-parser-js';
import AdminDashboardRoutes from './navigation/AdminDashboardRoutes';

function App() {
  const { commonStore } = useStore();
  const { token, setBrowser } = commonStore;

  Chart.register(
    ArcElement,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip
  );

  const parser = new UAParser();
  setBrowser(parser.getBrowser().name ?? '');

  return (
    <>
      <ModalContainer />
      <ToastContainer position="bottom-right" />
      <Switch>
        <Route path={'/login'} component={Login} />
        <Route path={'/register'} component={RegisterCompany} />
        <Route path={'/account/verifyEmail'} component={VerifyEmail} />
        <Route path={'/forgot-password'} component={ForgotPassword} />
        <Route path={'/paygate/redirect'} component={PaygateRedirect} />
        <Redirect exact from={'/'} to={!token ? '/login' : '/dashboard'} />
        <Route path={'/subscription/:subId'} component={AdminDashboardRoutes} />
        <Route path={'/'} component={DashboardRoutes} />
      </Switch>
    </>
  );
}

export default observer(App);
