import { useStore } from '../../stores/store';
import { PACKAGES } from '../../../features/user/packages';
import LogRocket from 'logrocket';
import { useEffect } from 'react';

import React from 'react';

type Props = {};

const Logger = (props: Props) => {
  const { userStore, accountStore } = useStore();
  const logRocketUrl = process.env.REACT_APP_LOGROCKET_URL;

  const user = userStore.user;
  const { selectedUserDetails } = accountStore;

  useEffect(() => {
    if (
      !user ||
      !logRocketUrl ||
      process.env.REACT_APP_NODE_ENV === 'development'
    ) {
      return;
    }
    LogRocket.init(logRocketUrl);

    const planTier =
      PACKAGES.find((item) => item.id === user?.subscriptionPackageId)?.title ??
      'Unknown';

    const role = user.isSysAdmin
      ? 'system admin'
      : user.isAdministrator
      ? 'admin'
      : user.isManager
      ? 'manager'
      : 'photographer';

    LogRocket.identify(user?.id?.toString() ?? '0', {
      name: user.displayName,
      email: user.email,

      // Add your own custom user variables here, ie:
      subscriptionPlan: planTier,
      role,
      companyName: selectedUserDetails?.companyName ?? 'Unknown',
    });
  }, [user]);

  return null;
};

export default Logger;
